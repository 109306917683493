import { SkillCategory } from 'interfaces/skill-category'
import { routes, updateSkillCategory } from 'lib/api/admin/skill-categories/skill-categories'
import SelectBox from 'lib/components/dropdown/select-box'
import TextInput from 'lib/components/text-input/text-input'
import Button from 'components/core/button'
import ToastProvider, { useToastContext } from 'providers/toast-provider'
import { useState } from 'react'
import { snakeCaseKeys } from 'lib/object/utils'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  formWrapper: 'tw-max-w-xs',
  option: 'tw-mx-1 tw-flex-grow tw-text-center',
  select: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-min-h-screen',
}

interface SkillCategoriesEditPageProps {
  skillCategory: SkillCategory
  subscriptionTypes: string[][]
}

function AdminSkillCategoriesEdit({ skillCategory, subscriptionTypes }: SkillCategoriesEditPageProps) {
  const { alert, notice } = useToastContext()
  const [name, setName] = useState<string>(skillCategory.name || '')
  const [subscriptionType, setSubscriptionType] = useState<string>(skillCategory.subscriptionType || '')
  const subscriptionTypesOptions = subscriptionTypes.map((subscriptionType) => ({
    value: subscriptionType[1],
    displayElement: <SubscriptionTypeOption subscriptionType={subscriptionType[0]} />,
  }))

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const { status } = await updateSkillCategory(skillCategory.id, snakeCaseKeys({ name, subscriptionType }))
      if (status === 200) {
        notice('Skill Category updated successfully')
      }
    } catch (error) {
      console.error(error)
      alert('Failed to update Skill Category')
    } finally {
      window.open(routes.index, '_self')
    }
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <a href={routes.index} className="tw-text-gherkin-500 tw-text-sm tw-mb-4 tw-block">
          Back to Skill Categories
        </a>
        <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">{`Edit ${skillCategory.name}`}</h1>
        <div className={classNames.formWrapper}>
          <form data-testid="edit-form" onSubmit={handleSubmitForm}>
            <TextInput
              data-testid="name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              value={name || ''}
              name="name"
              className="tw-w-full"
            />
            <div className={classNames.select}>
              <SelectBox
                data-testid="subcription-type"
                label="Subscription Type"
                handleChange={setSubscriptionType}
                selectedValue={subscriptionType}
                options={subscriptionTypesOptions}
                className="tw-w-full tw-mb-4"
              />
            </div>
            <Button data-testid="submit-button" color="green" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

function SubscriptionTypeOption({ subscriptionType }: { subscriptionType: string }) {
  return <div className={classNames.option}>{subscriptionType}</div>
}

export default function AdminSkillCategoriesEditPage(props: SkillCategoriesEditPageProps) {
  return (
    <ToastProvider>
      <AdminSkillCategoriesEdit {...props} />
    </ToastProvider>
  )
}
