import {
  ChangeEvent,
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import axios from 'axios'
import { useToastContext } from 'providers/toast-provider'
import { EmptyTicketFilters, TicketFilters } from 'components/pages/requests/ticket-list-filters'
import { Brand } from 'interfaces/brand'
import { Ticket } from 'interfaces/ticket'
import { User } from 'interfaces/user'
import { UrlState, getSingleQTicketsAndRequests, getStateFromQueryString } from 'lib/api/tickets/tickets'
import { removeElement, reorder } from 'lib/array/utils'
import { hasOnlyFalsyValues } from 'lib/object/utils'
import { isStatusCompleted, isStatusOnDeck } from 'lib/ticket/utils'

const DEFAULT_TAB = 'active'

const SORTABLE_COLUMNS_FOR_USER = ['position', 'friendly_status_name']

export const DEFAULT_SORT_COLUMN: Record<string, SortTuple> = {
  active: ['friendly_status_name', 'ASC'],
  draft: ['id', 'ASC'],
  archived: ['last_updated_for_user', 'DESC'],
}

export type ActiveTab = 'active' | 'draft' | 'archived'

export type SortDirection = 'ASC' | 'DESC'

export type SortTuple = [string, SortDirection]

interface SingleQueueProviderProps {
  children: ReactNode
  userId: number
}

type ModalData = {
  title: string
  message: string | ReactNode
  confirmBtnText: string
  confirmAction: (event) => void | Promise<void>
}

interface SingleQueueContextValue {
  activeTab: ActiveTab
  brands: Brand[]
  bulkActionIds: bigint[]
  bulkSelect: (event: ChangeEvent, id: bigint, friendlyStatusName: string) => void
  bulkStateSelected: string | null
  categories: string[]
  changeTab: (string: string) => void
  currentPage: number
  dataLoaded: boolean
  dragDisable: boolean
  fetchTickets: (params?: FetchTicketsParams) => Promise<void>
  filters: TicketFilters
  handleFilterChange: (filters: TicketFilters) => void
  handlePageChange: (selected: { selected: number }) => void
  isModalVisible: boolean
  loading: boolean
  menuButtonAction: (route: string, data: Record<string, unknown>, method: string) => void
  modalData: ModalData
  onDragEnd: (result) => void
  oops: (err) => void
  pageCount: number
  setActiveTab: Dispatch<SetStateAction<ActiveTab>>
  setBulkActionIds: (ids: Array<number | bigint>) => void
  setBulkStateSelected: (string) => void
  setCurrentPage: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<TicketFilters>>
  setModalVisible: Dispatch<SetStateAction<boolean>>
  setUserCanSort: Dispatch<SetStateAction<boolean>>
  sortBy: (column?, direction?) => void
  sortColumn: string | null
  sortDirection: 'ASC' | 'DESC'
  statuses: string[]
  tabs: string[]
  tickets: Ticket[]
  urlState: UrlState
  userCanSort: boolean | null
  users: User[]
}

export interface FetchTicketsParams {
  sort_column?: string
  sort_direction?: SortDirection
}

const SingleQueueContext = createContext({})

function goToPage(page: number): void {
  const params = new URLSearchParams(window.location.search)
  params.set('page', page.toString())
  updateParams(params)
}

function goToTab(tab: ActiveTab): void {
  const params = new URLSearchParams(window.location.search)
  params.set('tab', tab)
  params.delete('page')
  updateParams(params)
}

function setParam(params, key, value) {
  if (value !== null && value !== undefined && value !== '') {
    params.set(key, value.toString())
  }
}

export function useSingleQueueContext(): SingleQueueContextValue {
  return useContext(SingleQueueContext) as SingleQueueContextValue
}

export default function SingleQueueProvider({ children, userId }: SingleQueueProviderProps): ReactElement {
  const [defaultColumn, defaultDirection] = DEFAULT_SORT_COLUMN[DEFAULT_TAB]

  const { alert, notice } = useToastContext()

  const [activeTab, setActiveTab] = useState<ActiveTab>(DEFAULT_TAB)
  const [brands, setBrands] = useState<Brand[]>([])
  const [bulkActionIds, setBulkActionIds] = useState<number[]>([])
  const [bulkStateSelected, setBulkStateSelected] = useState<string | null>(null)
  const [categories, setCategories] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [filters, setFilters] = useState<TicketFilters>(EmptyTicketFilters)
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [modalData, setModalData] = useState<ModalData>({} as ModalData)
  const [pageCount, setPageCount] = useState<number>(1)
  const [sortColumn, setSortColumn] = useState<string>(defaultColumn)
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>(defaultDirection)
  const [statuses, setStatuses] = useState<string[]>([])
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [userCanSort, setUserCanSort] = useState<boolean | null>(null)
  const [users, setUsers] = useState<User[]>([])

  const urlState = getStateFromQueryString()

  const oops = useCallback(
    (err) => {
      const message = err?.response?.data?.message || 'Oops! Something went wrong. Please try again.'
      alert(message)
      console.error(err)
    },
    [alert]
  )

  function menuButtonAction(route, data, method, btnModalData = null) {
    if (btnModalData != null) {
      setModalData(btnModalData)
      setModalVisible(true)
    } else {
      // move this to api file
      axios({
        method,
        url: route,
        data,
      })
        .then((response) => {
          if (response.data.status === 'ok') {
            try {
              notice(response.data.message as string)
            } catch (error) {
              console.error('Error showing notice:', error)
            }
            fetchTickets().catch(() => null)
          } else {
            alert(response.data.message as string)
          }
          setBulkStateSelected(null)
          setBulkActionIds([])
        })
        .catch(oops)
      setModalVisible(false)
    }
  }

  function sortResults(sort: SortTuple): void {
    const params = new URLSearchParams(window.location.search)
    setParam(params, 'sort', sort.join('|'))
    setParam(params, 'page', '1')
    updateParams(params)
  }

  function sortBy(column = 'position', direction: SortDirection = 'ASC') {
    setCurrentPage(1)

    sortResults([column, direction])
    fetchTickets().catch(() => null)
  }

  const dragDisable = useMemo(() => {
    return (
      activeTab !== 'active' ||
      !hasOnlyFalsyValues(filters) ||
      !SORTABLE_COLUMNS_FOR_USER.includes(sortColumn) ||
      sortDirection === 'DESC'
    )
  }, [activeTab, filters, sortColumn, sortDirection])

  const tabs = ['active', 'draft', 'archived']

  function bulkSelect(event, id, status) {
    event.stopPropagation()
    if (bulkActionIds.includes(id)) {
      setBulkActionIds(removeElement(bulkActionIds, id))
      if (bulkActionIds.length === 1) {
        setBulkStateSelected(null)
      }
    } else {
      setBulkActionIds((prevBulkActionIds) => [...prevBulkActionIds, id])
      if (bulkStateSelected === null) {
        setBulkStateSelected(status)
      }
    }
  }

  async function sortTickets(reorderedTickets, params = {}) {
    if (SORTABLE_COLUMNS_FOR_USER.includes(sortColumn)) {
      try {
        await axios.post(window.Routes.apiInternalTicketsUrl(), {
          tickets: reorderedTickets.map((ticket) => ticket.id),
          ...params,
        })
        notice('Tickets Sorted')
      } catch {
        alert(
          'Due to your currently assigned role, you are unable to change request priority. Please contact your company administrator to change roles.'
        )
      }
    }
  }

  async function fetchTickets(params: FetchTicketsParams = {}) {
    setLoading(true)

    const { data, column, direction } = await getSingleQTicketsAndRequests({ params, userId, dataLoaded })

    setTickets(data.tickets)
    setBulkActionIds([])
    setBulkStateSelected(null)
    setPageCount(Math.ceil(data.count / data.per_page))
    setSortColumn(column)

    setSortDirection(direction)
    if (!dataLoaded) {
      setStatuses(data.statuses)
      setCategories(data.categories)
      setUsers(data.users)
      setBrands(data.brands)
      setDataLoaded(true)
    }

    setLoading(false)
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const offsetPosition = tickets[0].position || 1

    const reorderedTickets = reorder(tickets, result.source.index, result.destination.index)

    function isInImmovableState(friendlyStatusName) {
      return !isStatusOnDeck(friendlyStatusName)
    }

    const ticketsAllowedToBeReordered = reorderedTickets
      .filter(({ friendlyStatusName }) => isStatusOnDeck(friendlyStatusName))
      .map((ticket: Ticket, index) => {
        ticket.position = index + offsetPosition
        return ticket
      })

    const immovableTicketsAboveOnDeck = reorderedTickets.filter(
      ({ friendlyStatusName }) => isInImmovableState(friendlyStatusName) && !isStatusCompleted(friendlyStatusName)
    )
    const immovableTicketsBelowOnDeck = reorderedTickets.filter(
      ({ friendlyStatusName }) => isInImmovableState(friendlyStatusName) && isStatusCompleted(friendlyStatusName)
    )

    const newPositionTickets = [
      ...immovableTicketsAboveOnDeck,
      ...ticketsAllowedToBeReordered,
      ...immovableTicketsBelowOnDeck,
    ]

    sortTickets(newPositionTickets).catch(() => null)
    setTickets(newPositionTickets as Ticket[])
  }

  function changeTab(selectedTab) {
    goToTab(selectedTab)
    setActiveTab(selectedTab)
    setCurrentPage(1)
    fetchTickets().catch(() => null)
  }

  function handlePageChange({ selected }) {
    const page = selected + 1
    setCurrentPage(selected + 1)
    goToPage(page)
    fetchTickets().catch(() => null)
    window.scrollTo(0, 0)
  }

  function setFiltersInQueryString(activeTab: ActiveTab, filters: TicketFilters, currentPage: number): void {
    const params = new URLSearchParams()
    setParam(params, 'brand', filters.brandIdEq)
    setParam(params, 'page', currentPage)
    setParam(params, 'skill', filters.skillSkillCategorySubscriptionTypeEq)
    setParam(params, 'sort', DEFAULT_SORT_COLUMN[activeTab].join('|'))
    setParam(params, 'status', filters.friendlyStatus)
    setParam(params, 'tab', activeTab)
    setParam(params, 'title', filters.titleQuery)
    setParam(params, 'user', filters.userIdEq)

    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`)
  }

  function handleFilterChange(filters: TicketFilters) {
    setFilters(filters)
    setFiltersInQueryString(activeTab, filters, 1)
  }

  const context: SingleQueueContextValue = {
    activeTab,
    brands,
    bulkActionIds,
    bulkSelect,
    bulkStateSelected,
    categories,
    changeTab,
    currentPage,
    dataLoaded,
    dragDisable,
    fetchTickets,
    filters,
    handleFilterChange,
    handlePageChange,
    isModalVisible,
    loading,
    menuButtonAction,
    modalData,
    onDragEnd,
    oops,
    pageCount,
    setActiveTab,
    setBulkActionIds,
    setBulkStateSelected,
    setCurrentPage,
    setFilters,
    setModalVisible,
    setUserCanSort,
    sortBy,
    sortColumn,
    sortDirection,
    statuses,
    tabs,
    tickets,
    urlState,
    userCanSort,
    users,
  }

  return <SingleQueueContext.Provider value={context}>{children}</SingleQueueContext.Provider>
}

function updateParams(params: URLSearchParams) {
  window.history.pushState(null, '', `${window.location.pathname}?${params.toString()}`)
}
