import Modal from 'lib/components/modals/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'

export type ModalTypes = 'gen-ai' | 'general' | 'pending-annotations'
interface ConfirmSendToMyDesignerModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  confirm: () => void
  type?: ModalTypes
  confirmText?: string
  pendingAnnotationsLength?: number
}

function Content({ type, pendingAnnotationsLength }: { type: string; pendingAnnotationsLength?: number }) {
  if (type === 'gen-ai') {
    return (
      <div>
        <h3>You have pending results</h3>
        <p>You have Request Assist results that are either in progress or waiting for your review.</p>
        <p>Submitting the request now will result in the loss of your Request Assist progress and/or results.</p>
      </div>
    )
  }
  if (type === 'pending-annotations')
    return (
      <div>
        <h3>Send updates with pending annotations for review?</h3>
        <p>
          You have {pendingAnnotationsLength} annotation(s) pending review that will not be sent to your designer. Are
          you sure you want to send updates to designer?
        </p>
      </div>
    )

  return (
    <div>
      <h3>You have pending updates</h3>
      <p>You have pending updates that will not be sent to your designer</p>
      <p>Submitting the request now will result in the loss of your directions, files, or any other updates.</p>
    </div>
  )
}

export default function ConfirmSendToMyDesignerModal({
  open,
  setOpen,
  confirm,
  type = 'gen-ai',
  confirmText = 'Send request anyway',
  pendingAnnotationsLength = 0,
}: ConfirmSendToMyDesignerModalProps) {
  return (
    <Modal open={open} setOpen={setOpen} size={type === 'pending-annotations' ? 'md' : 'xs'}>
      <Modal.Body setOpen={setOpen} closeButton={false}>
        <>
          <div className="tw-flex tw-items-start tw-justify-start">
            <div className="tw-mr-4">
              <div className="tw-flex tw-justify-center tw-items-center tw-text-cornflower-400 tw-bg-cornflower-100 tw-rounded-full tw-w-10 tw-h-10">
                <FontAwesomeIcon icon={['far', 'question-circle']} size="lg" />
              </div>
            </div>
            <Content type={type} pendingAnnotationsLength={pendingAnnotationsLength} />
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-justify-end tw-gap-4">
          <Button onClick={() => setOpen(false)} color="lightGray" type="button">
            Back to request
          </Button>
          <Button onClick={confirm} color="purple" type="button">
            {confirmText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
