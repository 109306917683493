import { useRef, useState } from 'react'
import { Drawer } from 'lib/components/drawer/drawer'
import { Search } from 'lucide-react'
import SearchResult from './search-result'

export default function UniversalSearchButton(): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [navSearch, setNavSearch] = useState<string>('')
  const navSearchInputRef = useRef(null)

  const searchResults = sampleData

  const classNames = {
    checkbox: 'tw-form-checkbox tw-h-4 tw-w-4 tw-text-cornflower-500',
    checkboxLabel: 'tw-ml-2 tw-text-sm tw-text-gray-600 tw-m-0 tw-font-normal',
    checkboxWrapper: 'tw-flex tw-items-center',
    filterTitle: 'tw-text-lg tw-font-semibold tw-pb-2 tw-text-gray-900',
    searchInput: {
      container: `
      tw-flex tw-justify-center tw-relative tw-mt-4
    `,
      input: `
      tw-block
      tw-appearance-none
      tw-rounded-md
      tw-border
      tw-border-solid
      tw-p-2
      tw-pl-10
      tw-shadow-none
      tw-outline-none
      tw-text-lg
      tw-w-80
      placeholder:tw-text-neutral-500
      focus:tw-ring-2
      focus:tw-ring-cornflower-500
    `,
      icon: 'tw-text-neutral-500 tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-left-2 tw-w-4 tw-h-4',
    },
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          setNavSearch(navSearchInputRef.current.value)
          setIsOpen(true)
        }}
        className="tw-relative tw-ml-2 tw-flex tw-flex-1"
      >
        <Search className="lu-md tw-absolute tw-left-3 tw-top-2.5 tw-text-peppercorn-700" />
        <input
          ref={navSearchInputRef}
          id="universal_search"
          placeholder="Search"
          className="tw-bg-peppercorn-50 tw-text-peppercorn-800 tw-border-none tw-pl-10 tw-h-10 tw-rounded tw-flex tw-items-center tw-flex-1"
        />
      </form>
      <Drawer isOpen={isOpen} setOpen={setIsOpen} size="xl">
        <Drawer.Header setOpen={setIsOpen}>
          <Drawer.Title className="tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-full">
            <div>What are you searching for?</div>
            <div className={classNames.searchInput.container}>
              <Search className={classNames.searchInput.icon} />
              <input
                name="search-request-name"
                placeholder="Search"
                className={classNames.searchInput.input}
                defaultValue={navSearch}
              />
            </div>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {isOpen && (
            <div className="tw-flex tw-flex-row tw-gap-8">
              <div className="tw-flex-1">
                <div className="tw-flex tw-flex-col tw-gap-8">
                  <h3>Requests ({searchResults.length})</h3>
                  {searchResults.map((result) => (
                    <SearchResult key={result.id} result={result} />
                  ))}
                </div>
              </div>
              <div className="tw-w-px tw-bg-peppercorn-100"></div>
              <div className="tw-w-56">
                <div className="tw-mb-6">
                  <div className={classNames.filterTitle}>Time</div>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className={classNames.checkboxWrapper}>
                      <input type="radio" name="time_filter" id="time_any_time" />
                      <label htmlFor="time_any_time" className={classNames.checkboxLabel}>
                        Any time
                      </label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="radio" name="time_filter" id="time_today" />
                      <label htmlFor="time_today" className={classNames.checkboxLabel}>
                        Today
                      </label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="radio" name="time_filter" id="time_yesterday" />
                      <label htmlFor="time_yesterday" className={classNames.checkboxLabel}>
                        Yesterday
                      </label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="radio" name="time_filter" id="time_past_7" />
                      <label htmlFor="time_past_7" className={classNames.checkboxLabel}>
                        Past 7 days
                      </label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="radio" name="time_filter" id="time_past_30" />
                      <label htmlFor="time_past_30" className={classNames.checkboxLabel}>
                        Past 30 days
                      </label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="radio" name="time_filter" id="time_past_year" />
                      <label htmlFor="time_past_year" className={classNames.checkboxLabel}>
                        Last year
                      </label>
                    </div>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <div className={classNames.filterTitle}>States</div>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className={classNames.checkboxWrapper}>
                      <input type="checkbox" className={classNames.checkbox} />
                      <label className={classNames.checkboxLabel}>Draft</label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="checkbox" className={classNames.checkbox} />
                      <label className={classNames.checkboxLabel}>On deck</label>
                    </div>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <div className={classNames.filterTitle}>Request Types</div>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className={classNames.checkboxWrapper}>
                      <input type="checkbox" className={classNames.checkbox} />
                      <label className={classNames.checkboxLabel}>Custom illustration</label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="checkbox" className={classNames.checkbox} />
                      <label className={classNames.checkboxLabel}>Graphic design</label>
                    </div>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <div className={classNames.filterTitle}>Submitted by</div>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className={classNames.checkboxWrapper}>
                      <input type="checkbox" className={classNames.checkbox} />
                      <label className={classNames.checkboxLabel}>User 1</label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="checkbox" className={classNames.checkbox} />
                      <label className={classNames.checkboxLabel}>User 2</label>
                    </div>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <div className={classNames.filterTitle}>Brand Profile</div>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className={classNames.checkboxWrapper}>
                      <input type="checkbox" className={classNames.checkbox} />
                      <label className={classNames.checkboxLabel}>Brand A</label>
                    </div>
                    <div className={classNames.checkboxWrapper}>
                      <input type="checkbox" className={classNames.checkbox} />
                      <label className={classNames.checkboxLabel}>Brand B</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  )
}

export type designRequestSearchResult = {
  id: number
  title: string
  excerpt?: string
  status: string
  requestType: string
  submittedBy: string
  submittedDate: string
  designer: string
  files?: string[]
  thumbnails?: string[]
}

const sampleData = [
  {
    id: 1,
    title: 'Playful Cat Mascot Design',
    excerpt: '...animals, cats have retained much of their wild instincts, allowing them to roam fr...',
    status: 'On Deck',
    requestType: 'Graphic design',
    submittedBy: 'Suraj Sodha',
    submittedDate: '07/20/2022 07:18pm MST',
    designer: 'Brenda Rolenc',
    files: [
      'cutecatpost.png',
      'playfulcatdesign_v2.png',
      'mascot_sketch_01.jpg',
      'mascot_colors.pdf',
      'mascot_inspiration.zip',
      'client_feedback.docx',
      'design_iterations.psd',
    ],
    thumbnails: [
      'https://picsum.photos/200/200?random=1',
      'https://picsum.photos/200/200?random=2',
      'https://picsum.photos/200/200?random=3',
    ],
  },
  {
    id: 2,
    title: 'Minimalist Cat Logo',
    status: 'In Progress',
    requestType: 'Graphic design',
    submittedBy: 'Leah Kim',
    submittedDate: '07/18/2022 02:34pm MST',
    designer: 'Ethan Nguyen',
    files: [
      'cat_logo_brief.pdf',
      'minimalist_inspiration.jpg',
      'logo_concepts.ai',
      'color_palette.png',
      'typography_options.ttf',
    ],
    thumbnails: ['https://picsum.photos/200/200?random=4', 'https://picsum.photos/200/200?random=5'],
  },
  {
    id: 3,
    title: 'Whimsical Cat Characters',
    excerpt: '...from the majestic lions to the domesticated cats, felines have captured the imaginat...',
    status: 'For Review',
    requestType: 'Custom Illustration',
    submittedBy: 'Olivia Patel',
    submittedDate: '07/15/2022 09:47am MST',
    designer: 'Sophia Lee',
    thumbnails: ['https://picsum.photos/200/200?random=7'],
  },
  {
    id: 4,
    title: 'Realistic Cat Sketches',
    excerpt: '...while often thought of as solitary creatures, cats can form strong social bonds with...',
    status: 'Preview',
    requestType: 'Custom Illustration',
    submittedBy: 'Liam Gupta',
    submittedDate: '07/22/2022 11:09am MST',
    designer: 'Mia Nakamura',
    files: [
      'cat_anatomy_reference.jpg',
      'sketch_01.png',
      'sketch_02.png',
      'sketch_03.png',
      'shading_tests.psd',
      'whiskers_detail.tiff',
      'final_sketch.jpg',
    ],
  },
  {
    id: 5,
    title: 'Abstract Cat Art',
    excerpt: '...the fascinating history of cats is a testament to their enduring popularity and mys...',
    status: 'Completed',
    requestType: 'Graphic design',
    submittedBy: 'Emma Hernandez',
    submittedDate: '07/12/2022 05:56pm MST',
    designer: 'Alexander Wright',
    thumbnails: [
      'https://picsum.photos/200/200?random=13',
      'https://picsum.photos/200/200?random=14',
      'https://picsum.photos/200/200?random=15',
    ],
  },
]
