import { Switch } from '@headlessui/react'
import Button from 'components/core/button'
import TextInput from 'components/core/text-input/text-input'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Format } from 'interfaces/format'
import { routes, updateFormat } from 'lib/api/admin/formats/formats'
import SelectBox from 'lib/components/dropdown/select-box'
import ToastProvider, { useToastContext } from 'providers/toast-provider'
import { useState } from 'react'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  option: 'tw-mx-1 tw-flex-grow tw-text-right',
  select: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  switch: {
    disabled:
      'tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-flex-shrink-0 tw-border-1 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-bg-neutral-200',
    enabled:
      'tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-flex-shrink-0 tw-border-1 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-bg-gherkin-500',
  },
  switchGroup: 'tw-flex tw-items-center',
  switchLabel: 'tw-mr-1 tw-mb-0 tw-text-black tw-uppercase',
  switchToggle: {
    disabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw--translate-x-1',
    enabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw-translate-x-4',
  },
  switchWrapper: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md tw-grid tw-grid-cols-3 tw-gap-4',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

interface AdminFormatsEditProps {
  format: Format
}

function AdminFormatsEdit({ format }: AdminFormatsEditProps) {
  const [name, setName] = useState<string>(format.name)
  const [source, setSource] = useState<string>(format.source.toString())
  const [delivery, setDelivery] = useState<string>(format.delivery)
  const [extensions, setExtensions] = useState<string[]>(format.extensions)
  const [updating, setUpdating] = useState<boolean>(false)

  const { alert, notice } = useToastContext()

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    setUpdating(true)
    try {
      const { status } = await updateFormat(format.id, { name, source, delivery, extensions })

      if (status === 200) {
        notice('Format updated successfully')
        window.open(routes.index, '_self')
      }
    } catch (error) {
      console.error(error)
      alert('Failed to update format')
    } finally {
      setUpdating(false)
    }
  }

  const deliveryOptions = () =>
    ['file', 'link'].map((option) => ({
      value: option,
      displayElement: <div className={classNames.option}>{option}</div>,
    }))

  if (updating) {
    return <LoadingScreen />
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <a href={routes.index} className="tw-text-gherkin-500 tw-text-sm tw-mb-4 tw-block">
          Back to Formats
        </a>
        <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">Edit {format.name}</h1>
        <form data-testid="edit-form" onSubmit={handleSubmitForm}>
          <TextInput data-testid="name" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
          <SourceSwitch source={source} setSource={setSource} />
          <div className={classNames.select}>
            <SelectBox
              data-testid="delivery"
              label="Delivery"
              handleChange={(value) => setDelivery(value)}
              selectedValue={delivery}
              options={deliveryOptions()}
            />
          </div>
          <div className="tw-mt-4">
            <TextInput
              data-testid="extensions"
              label="Extensions"
              value={extensions.join(', ')}
              onChange={(e) => setExtensions(e.target.value.split(',').map((ext) => ext.trim()))}
            />
          </div>
          <div className="tw-mt-4">
            <Button data-testid="submit-button" color="green" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

interface SourceSwitchProps {
  source: string
  setSource: (value: string) => void
}

function SourceSwitch({ source, setSource }: SourceSwitchProps) {
  return (
    <div className={classNames.switchWrapper}>
      <Switch.Group>
        <div className={classNames.switchGroup}>
          <Switch.Label className={classNames.switchLabel}>Source</Switch.Label>
          <Switch
            checked={source === 'true'}
            onChange={(value) => setSource(value.toString())}
            className={source === 'true' ? classNames.switch.enabled : classNames.switch.disabled}
            style={{ width: '38px', height: '18px' }}
          >
            <span className="sr-only">{source}</span>
            <span
              className={source === 'true' ? classNames.switchToggle.enabled : classNames.switchToggle.disabled}
              aria-hidden="true"
              style={{
                width: '14px',
                height: '14px',
                transform: `translateX(${source === 'true' ? '14px' : '-4px'})`,
              }}
            ></span>
          </Switch>
        </div>
      </Switch.Group>
    </div>
  )
}

interface AdminFormatsEditPageProps {
  format: Format
}

export default function AdminFormatsEditPage(props: AdminFormatsEditPageProps) {
  return (
    <ToastProvider>
      <AdminFormatsEdit format={props.format} />
    </ToastProvider>
  )
}
