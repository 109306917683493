import { ReactElement, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'
import { AdminTicketListItem, getTicketsForCurrentUser } from 'lib/api/admin/tickets/admin-tickets'

interface AdminTicketListContextValue {
  cumulativeDesignTime: number
  ticketsInQueue: AdminTicketListItem[]
  ticketsInQueueLength: number
  ticketsInReview: AdminTicketListItem[]
  ticketsInReviewLength: number
}

interface AdminTicketListProviderProps {
  children: ReactNode
}

interface TicketPartition {
  queue: AdminTicketListItem[]
  review: AdminTicketListItem[]
}

const AdminTicketListContext = createContext({})

export default function AdminTicketListProvider({ children }: AdminTicketListProviderProps): ReactElement {
  const [ticketsInQueue, setTicketsInQueue] = useState<AdminTicketListItem[]>([])
  const [ticketsInReview, setTicketsInReview] = useState<AdminTicketListItem[]>([])

  const ticketsInQueueLength = useMemo(() => ticketsInQueue.length, [ticketsInQueue.length])
  const ticketsInReviewLength = useMemo(() => ticketsInReview.length, [ticketsInReview.length])

  const cumulativeDesignTime = useMemo(() => {
    return ticketsInQueue.reduce((acc: number, ticket) => {
      return acc + (ticket.designTime || 0)
    }, 0)
  }, [ticketsInQueue])

  useEffect(() => {
    getTicketsForCurrentUser().then((response) => {
      const partitionedTickets = response.reduce(partitionAccumulator, { queue: [], review: [] })
      setTicketsInQueue(partitionedTickets.queue)
      setTicketsInReview(partitionedTickets.review)
    })
  }, [])

  const context: AdminTicketListContextValue = {
    ticketsInQueue,
    ticketsInQueueLength,
    ticketsInReview,
    ticketsInReviewLength,
    cumulativeDesignTime,
  }

  return <AdminTicketListContext.Provider value={context}>{children}</AdminTicketListContext.Provider>
}

function partitionAccumulator(acc: TicketPartition, ticket: AdminTicketListItem): TicketPartition {
  if (ticket.state === AdminTicketState.qualityReview) {
    acc.review.push(ticket)
  } else {
    acc.queue.push(ticket)
  }
  return acc
}

export function useAdminTicketListContext(): AdminTicketListContextValue {
  return useContext(AdminTicketListContext) as AdminTicketListContextValue
}
