import { ReactElement } from 'react'
import TimelineListProvider from '../providers/timeline-list-provider'
import TimelineList from './timeline-list'
import TimelineFilters from './timeline-filters'
import BannersList from '../banners/banners-list'

interface TimelineProps {
  onLoad: (behaviorIsSmooth?: boolean) => void
}

export default function Timeline({ onLoad }: TimelineProps): ReactElement {
  return (
    <TimelineListProvider onLoad={onLoad}>
      <BannersList />
      <TimelineFilters />
      <TimelineList />
    </TimelineListProvider>
  )
}
