import { ReactElement, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from 'lib/components/tooltip/tooltip'
import { generateRangeTo } from 'lib/util/range'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import { useRequestContext } from '../providers/request-provider'
import BannersList from '../banners/banners-list'
import DirectionInput from './directions-input'
import DirectionsList from './directions-list'
import DirectionsFlyoutMenu from './directions-flyout-menu'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'

const classNames = {
  directionsContainer: `tw-relative`,
  footer: {
    default: `tw-sticky tw-bottom-0 tw-px-5 tw-pb-5 tw-bg-white`,
    withDirections: `tw-pt-4`,
    withoutDirections: `tw-pt-0`,
  },
  h2: `tw-text-base tw-m-0`,
  header: `tw-pb-0 tw-bg-white`,
}

function DirectionsFooter(): ReactElement {
  const { directions } = useDirectionsContext()

  return (
    <footer
      data-testid="directions-footer"
      className={`${classNames.footer.default} ${
        directions?.length ? classNames.footer.withDirections : classNames.footer.withoutDirections
      }`}
    >
      <div className="tw-flex tw-gap-2 tw-items-start tw-w-full">
        <div className="tw-mt-3">
          <DirectionsFlyoutMenu />
        </div>
        <div className="tw-w-full">
          <DirectionInput placeholder="Start typing general direction" />
        </div>
      </div>
      <p className="tw--mt-8 tw-pt-2.5">Press enter for another line</p>
    </footer>
  )
}

function RequestDirectionsHeader() {
  const { areDirectionsValid } = useDirectionsContext()
  const { showValidationErrors } = useRequestContext()

  const showError = showValidationErrors && !areDirectionsValid

  return (
    <>
      <h2 className={`${classNames.h2} ${showError ? 'tw-text-flushpink-500' : ''}`}>
        Directions*
        <Tooltip
          content="Each direction and annotation will create a checklist for your creative to follow as they create your request."
          width={375}
        >
          <FontAwesomeIcon icon={['far', 'info-circle']} className="tw-px-2 tw-cursor-help" />
        </Tooltip>
      </h2>
      <div>
        Type general directions for your designer below. You can also click the &ldquo;+&rdquo; to add exact copy and
        leverage our built-in AI tools to assist with your creative brief.
      </div>
    </>
  )
}

function RevisionsDirectionsHeader() {
  const { areDirectionsValid } = useDirectionsContext()
  const { showValidationErrors } = useRequestContext()

  const showError = showValidationErrors && !areDirectionsValid

  return (
    <>
      <h2 className={`${classNames.h2} ${showError ? 'tw-text-flushpink-500' : ''}`}>Directions</h2>
      <div>Once you&lsquo;re done adding revisions, click “Send updates to designer” to submit.</div>
    </>
  )
}

export default function Directions(): ReactElement {
  const { showRevisions, ticket } = useRequestContext()
  const { canCreateDirections, directions, setFilters } = useDirectionsContext()
  const [selectedVersion, setSelectedVersion] = useState<number>(ticket?.lastDeliveredVersion || 1)

  const versions = useMemo(() => generateRangeTo(ticket?.lastDeliveredVersion), [ticket?.lastDeliveredVersion])

  const handleVersionChange = (version: number) => {
    setSelectedVersion(version)
    setFilters((previous) => ({ ...previous, version: Number(version) }))
  }

  return (
    <div className="tw-pt-6">
      <header className={classNames.header}>
        {showRevisions && <RevisionsDirectionsHeader />}
        {!showRevisions && <RequestDirectionsHeader />}
      </header>
      <div className={classNames.directionsContainer}>
        {showRevisions && directions?.length > 0 && versions && (
          <TicketVersionSelectBox
            versions={versions}
            selectedVersion={selectedVersion}
            setSelectedVersion={handleVersionChange}
          />
        )}
        <BannersList />
        <DirectionsList />
        {canCreateDirections && <DirectionsFooter />}
      </div>
    </div>
  )
}
