import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import { faCircleXmark, IconName } from '@fortawesome/free-regular-svg-icons'

type BannerType = 'success' | 'inProgress' | 'warning' | 'error'
interface BannerProps {
  type: bannerType
  border?: 'dashed' | 'none'
  children?: ReactNode
  heading?: string
  headingIcon?: IconName
  subheading?: string
}

const classNames = {
  container: {
    base: `
      tw-flex
      tw-items-center
      tw-my-2
    `,
    error: `
      tw-bg-flushpink-50
      tw-border-flushpink-200
      tw-text-flushpink-800
    `,
    success: `
      tw-bg-picklegreen-50
      tw-border-picklegreen-200
      tw-text-picklegreen-800
    `,
    warning: `
      tw-bg-sunnyyellow-50
      tw-border-sunnyyellow-200
      tw-text-sunnyyellow-800
    `,
    inProgress: `
      tw-bg-neutral-50
      tw-border-neutral-200
      tw-text-neutral-800
    `,
    border: {
      dashed: 'tw-border-2 tw-rounded-lg tw-border-dashed',
      none: '',
    },
  },
  heading: {
    container: 'tw-flex-shrink-0 tw-flex',
    icon: 'tw-pl-3 tw-pr-1 tw-text-xl',
  },
  controls: 'tw-pr-4 tw-font-semibold tw-flex tw-gap-4',
  icon: {
    base: 'tw-px-3 tw-text-xl',
    success: 'tw-text-picklegreen-500',
    warning: 'tw-text-sunnyyellow-800',
    error: 'tw-text-flushpink-800',
    inProgress: 'tw-text-picklegreen-500 fa-spin',
  },
  subheading: 'tw-flex-grow tw-pl-2 tw-italic tw-truncate',
}

function BannerIcon({ type }: { type: BannerType }) {
  switch (type) {
    case 'success':
      return <FontAwesomeIcon icon={['far', 'check-circle']} className={classNames.icon.success} />
    case 'warning':
      return <FontAwesomeIcon icon={['far', 'exclamation-triangle']} className={classNames.icon.warning} />
    case 'error':
      // Font Awesome could not find ['far', 'xmark-circle'] so I had to import it instead
      return <FontAwesomeIcon icon={faCircleXmark} className={classNames.icon.error} />
    case 'inProgress':
      return <FontAwesomeIcon icon={['far', 'circle-notch']} className={classNames.icon.inProgress} />
    default:
      return null
  }
}

export default function Banner({ type, children, heading, headingIcon, subheading, border }: BannerProps) {
  return (
    <div className={bannerContainerClassName(type, border)} style={{ minHeight: '64px' }}>
      <div className={classNames.icon.base}>
        <BannerIcon type={type} />
      </div>
      {(heading || headingIcon) && (
        <div className={classNames.heading.container}>
          {headingIcon && <FontAwesomeIcon icon={['far', headingIcon]} className={classNames.heading.icon} />}
          {heading && <span className="tw-font-semibold">{heading}</span>}
        </div>
      )}
      {subheading && <span className={classNames.subheading}>{subheading}</span>}
      <div className={classNames.controls}>{children}</div>
    </div>
  )
}

function bannerContainerClassName(type, border) {
  return `${classNames.container.base} ${classNames.container[type]} ${classNames.container.border[border || 'none']}`
}
