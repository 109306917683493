import { ReactElement } from 'react'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { ArrowDownUp, Link, Merge, Pencil, Split } from 'lucide-react'

const classNames = {
  flyoutContainer: 'tw-flex tw-items-center tw-gap-2',
}

export default function AdminHeaderOverflowMenu(): ReactElement {
  return (
    <div className="tw-z-30">
      <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']} adjustedLeft>
        <IconFlyoutMenu.Link url="" target="_blank">
          <div className={classNames.flyoutContainer}>
            <Link className="lu-light lu-md" />
            <div>Copy link to request</div>
          </div>
        </IconFlyoutMenu.Link>
        <IconFlyoutMenu.Button onClick={() => null}>
          <div className={classNames.flyoutContainer}>
            <img src="/images/icons/intercom.svg" className="tw-w-5" alt="intercom icon" />
            <div>Send to intercom</div>
          </div>
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => null}>
          <div className={classNames.flyoutContainer}>
            <Pencil className="lu-light lu-md" />
            <div>Edit ticket</div>
          </div>
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => null}>
          <div className={classNames.flyoutContainer}>
            <Split className="lu-light lu-md" />
            <div>Batch/Split</div>
          </div>
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => null}>
          <div className={classNames.flyoutContainer}>
            <Merge className="lu-light lu-md" />
            <div>Merge and close</div>
          </div>
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => null}>
          <div className={classNames.flyoutContainer}>
            <ArrowDownUp className="lu-light lu-md" />
            <div>Transfer</div>
          </div>
        </IconFlyoutMenu.Button>
      </IconFlyoutMenu>
    </div>
  )
}
