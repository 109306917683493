import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactChild, ReactElement } from 'react'

export type Sizes = 'xs' | 'sm' | 'lg' | 'xl' | '2xl'
export type Colors = 'primary' | 'secondary' | 'transparent' | 'success' | 'danger'

interface IconButtonProps {
  [otherProps: string]: unknown
  children?: ReactChild
  className?: string
  color: Colors
  dataTestid?: string
  disabled?: boolean
  icon?: IconProp | string
  invert?: boolean
  onClick: (e: unknown) => void
  selected?: boolean
  size?: Sizes
}

const classNames = {
  base: `
    tw-rounded-full
    tw-shadow-sm
    tw-border-none
    tw-transition
    tw-duration-300
    tw-cursor-pointer
    tw-flex
    tw-items-center
    tw-justify-center
    focus:tw-ring
    focus:tw-ring-1
    focus:tw-ring-offset-1
    disabled:tw-opacity-60`,
  size: {
    xs: 'tw-w-8 tw-h-8',
    sm: 'tw-w-10 tw-h-10',
    lg: 'tw-w-12 tw-h-12',
    xl: 'tw-w-14 tw-h-14',
    '2xl': 'tw-w-16 tw-h-16',
  },
  colors: {
    primary: {
      inverted: {
        base: `
          tw-text-cornflower-500
          tw-bg-neutral-50
          disabled:tw-bg-neutral-50
          hover:tw-bg-cornflower-50
          focus:tw-ring-cornflower-200
          focus:tw-bg-cornflower-100`,
        selected: `tw-bg-cornflower-100 tw-ring-cornflower-100 tw-ring tw-ring-1 tw-ring-offset-1`,
      },
      default: {
        base: `
          tw-bg-cornflower-500
          disabled:tw-bg-cornflower-500
          tw-text-white
          hover:tw-bg-cornflower-600
          focus:tw-ring-cornflower-600`,
        selected: ``,
      },
    },
    secondary: {
      inverted: {
        base: `
          tw-shadow-none
          tw-text-neutral-800
          tw-bg-transparent
          disabled:tw-bg-neutral-50
          hover:tw-bg-neutral-100
          focus:tw-ring-neutral-100
          focus:tw-bg-neutral-100`,
        selected: ``,
      },
      default: {
        base: `
          tw-text-neutral-800
          tw-bg-neutral-100
          disabled:tw-bg-neutral-50
          hover:tw-bg-neutral-200
          focus:tw-ring-neutral-100`,
        selected: ``,
      },
    },
    success: {
      inverted: {
        base: `
          tw-bg-neutral-100
          disabled:tw-bg-neutral-50
          tw-text-picklegreen-500
          hover:tw-bg-picklegreen-500
          focus:tw-ring-picklegreen-100
          focus:tw-bg-picklegreen-100`,
        selected: ``,
      },
      default: {
        base: `
          tw-bg-picklegreen-500
          disabled:tw-bg-picklegreen-500
          tw-text-white
          hover:tw-bg-picklegreen-600
          focus:tw-ring-picklegreen-600`,
        selected: ``,
      },
    },
    danger: {
      inverted: {
        base: `
          tw-bg-neutral-100
          disabled:tw-bg-neutral-50
          tw-text-flushpink-500
          hover:tw-bg-flushpink-50
          focus:tw-ring-flushpink-100
          focus:tw-bg-flushpink-100`,
        selected: `
          tw-bg-cornflower-100
          tw-ring-cornflower-100
          hover:tw-bg-cornflower-50
          tw-ring
          tw-ring-1
          tw-ring-offset-1
          focus:tw-ring-cornflower-200
          focus:tw-bg-cornflower-100`,
      },
      default: {
        base: `
          tw-bg-flushpink-500
          disabled:tw-bg-flushpink-500
          tw-text-white
          hover:tw-bg-flushpink-600
          focus:tw-ring-flushpink-600`,
        selected: ``,
      },
    },
  },
}

interface GetClassNamesParams {
  className?: string
  color: Colors
  disabled?: boolean
  forceFocus?: boolean
  invert?: boolean
  selected?: boolean
  size: Sizes
}

export function getClassNames({
  size,
  color,
  className = '',
  selected = false,
  invert = false,
  disabled = false,
}: GetClassNamesParams) {
  const classes = [classNames.base]

  if (color === 'transparent') {
    color = 'secondary'
    invert = true
  }

  if (classNames.size[size]) {
    classes.push(classNames.size[size])
  }

  classes.push(classNames.colors[color][invert ? 'inverted' : 'default']['base'])

  if (selected) {
    classes.push(classNames.colors[color][invert ? 'inverted' : 'default']['selected'])
  }

  if (!disabled) {
    classes.push('hover:tw-shadow-md')
  }

  if (className) {
    classes.push(className)
  }

  return classes.join(' ')
}

export default function IconButton({
  className,
  color,
  onClick,
  icon,
  invert,
  disabled,
  dataTestid,
  size = 'sm',
  selected,
  children,
  ...otherProps
}: IconButtonProps): ReactElement {
  return (
    <button
      type="button"
      className={getClassNames({ size, color, invert, selected, disabled, className })}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestid || `${icon ? icon[1] + '-button' : 'icon-button'}`}
      {...otherProps}
    >
      {icon ? <FontAwesomeIcon icon={icon as IconProp} size={size} /> : children}
    </button>
  )
}
