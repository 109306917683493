import { client } from 'filestack-react'

import {
  addTicketFile,
  AddTicketFileParams,
  AdminTicketFile,
  deleteTicketFile,
  getTicketFile,
  getTicketFilesForTicket,
} from 'lib/api/admin/ticket-files/admin-ticket-files'
import { AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'
import FilestackPicker, { defaultFilestackOptions } from 'lib/components/modals/filestack-picker'
import { FilestackFile, FilestackUploadDoneParams } from 'lib/util/filestack'
import { generateRangeTo } from 'lib/util/range'

import { AnimatedLoadingScreen } from 'components/pages/requests/empty-screens'
import { useAdminUserContext } from 'providers/admin-user-provider'
import { useToastContext } from 'providers/toast-provider'
import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAdminTicketContext } from './admin-ticket-provider'

const pickerOptions = { ...defaultFilestackOptions, fromSources: ['local_file_system'] }

interface AdminMediaContextProps {
  children: ReactNode
}

interface AdminMediaContextValue {
  addFile: (params: AddTicketFileParams) => Promise<AdminTicketFile>
  currentVersion: number
  deleteFile: (file: AdminTicketFile) => void
  extractedPreviewIndex: number
  files: AdminTicketFile[]
  filters: AdminMediaFilters
  getLatestFiles: (uploadedByCreative: boolean) => Promise<AdminTicketFile[]>
  selectedFile: AdminTicketFile
  selectedVersion: number
  setExtractedPreviewIndex: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<AdminMediaFilters>>
  setSelectedFile: Dispatch<SetStateAction<AdminTicketFile>>
  versions: number[]
  visibleFiles: AdminTicketFile[]
  setIsFileUploaderOpen: Dispatch<SetStateAction<boolean>>
}

interface AdminMediaFilters {
  isUploadedByCreative: boolean
  version: number
}

const AdminMediaContext = createContext({})

export default function AdminMediaProvider({ children }: AdminMediaContextProps): ReactElement {
  const [isFileUploaderOpen, setIsFileUploaderOpen] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [files, setFiles] = useState<AdminTicketFile[]>([])
  const [selectedFile, setSelectedFile] = useState<AdminTicketFile>(null)
  const [extractedPreviewIndex, setExtractedPreviewIndex] = useState<number>(-1)

  const { ticket } = useAdminTicketContext()
  const { user, settings } = useAdminUserContext()
  const { alert } = useToastContext()
  const [filters, setFilters] = useState<AdminMediaFilters>({
    isUploadedByCreative: ticket.state === AdminTicketState.incompleteReviewFailed || user.isDPManager,
    version: ticket.currentVersion,
  })

  const versions = useMemo(() => {
    return generateRangeTo(ticket.currentVersion)
  }, [ticket.currentVersion])

  const visibleFiles = useMemo(() => {
    if (filters.version) {
      return files.filter(
        (file) => file.ticketVersion === filters.version && file.uploadedByCreative === filters.isUploadedByCreative
      )
    }
    return files.filter((file) => file.uploadedByCreative === filters.isUploadedByCreative)
  }, [files, filters])

  const addFile = useCallback(
    async (params: AddTicketFileParams): Promise<AdminTicketFile> => {
      const newFile = await addTicketFile(ticket.id, params)
      setFiles((previous) => [...previous, newFile])

      return newFile
    },
    [ticket.id]
  )

  const addFileFromResponse = useCallback(
    (response: PromiseSettledResult<AdminTicketFile>) => {
      if (response.status === 'fulfilled') {
        setFiles((current) => [...current, response.value])
      } else {
        alert('Oops! Something went wrong while uploading. Please try again')
      }
    },
    [alert]
  )

  const saveFiles = useCallback(
    async (files: FilestackFile[]) => {
      const promises = files.map(async (file) => {
        return await addTicketFile(ticket.id, {
          filename: file.filename,
          handle: file.handle,
          mimetype: file.mimetype,
        })
      })

      const results = (await Promise.allSettled(promises)) as PromiseFulfilledResult<AdminTicketFile>[]
      results.forEach(addFileFromResponse)
    },
    [addFileFromResponse, ticket.id]
  )

  async function onUpload(result: client.PickerFileMetadata) {
    const { filesFailed, filesUploaded } = result as FilestackUploadDoneParams

    setIsFileUploaderOpen(false)

    if (filesFailed.length) {
      alert('Oops! Something went wrong while uploading. Please try again')
    }

    await saveFiles(filesUploaded)
  }

  async function deleteFile(file: AdminTicketFile) {
    await deleteTicketFile(ticket.id, file.id)
    if (file === selectedFile) {
      setSelectedFile(null)
    }
    setFiles((previous) => previous.filter((f) => f.id !== file.id))
  }

  async function getLatestFiles(isUploadedByCreative?: boolean) {
    return await getFiles(ticket.id, { isUploadedByCreative: isUploadedByCreative, version: ticket.currentVersion })
  }

  useEffect(() => {
    let isAbandoned = false
    getFiles(ticket.id, filters).then((response) => {
      if (!isAbandoned) {
        setFiles(response)
        setIsLoaded(true)
        if (
          (ticket.state === AdminTicketState.incompleteReviewFailed || user.isDPManager) &&
          filters.isUploadedByCreative &&
          filters.version === ticket.currentVersion
        ) {
          setSelectedFile(response[0])
        }
      }
    })
    return () => {
      isAbandoned = true
    }
  }, [
    ticket.currentVersion,
    ticket.id,
    filters.isUploadedByCreative,
    filters.version,
    user.isDPManager,
    filters,
    ticket.state,
  ])

  useEffect(() => {
    let isAbandoned: boolean, timeoutUntilNextPoll: number

    function recursivelyPollUntilPagesExtracted(delay = 500) {
      getTicketFile(ticket.id, selectedFile.id).then((latestFile) => {
        if (!isAbandoned && latestFile.isExtractable) {
          if (latestFile.extractedPages.length > 0) {
            setFiles((currentFiles) => {
              return currentFiles.map((file) => {
                if (file.id === latestFile.id) {
                  return updateExtractableFile(file, latestFile)
                }
                return file
              })
            })

            setExtractedPreviewIndex(0)
            setSelectedFile((current) => {
              return updateExtractableFile(current, latestFile)
            })
          } else {
            timeoutUntilNextPoll = window.setTimeout(() => recursivelyPollUntilPagesExtracted(delay * 2), delay)
          }
        }
      })
    }

    if (selectedFile && selectedFile.isExtractable && selectedFile.extractedPages.length === 0) {
      recursivelyPollUntilPagesExtracted()
    }

    return () => {
      isAbandoned = true
      window.clearTimeout(timeoutUntilNextPoll)
    }
  }, [selectedFile, ticket?.id])

  useEffect(() => {
    setSelectedFile(null)
  }, [filters.isUploadedByCreative, filters.version])

  const context: AdminMediaContextValue = {
    addFile,
    currentVersion: ticket.currentVersion,
    deleteFile,
    extractedPreviewIndex,
    files,
    filters,
    getLatestFiles,
    selectedFile,
    selectedVersion: filters.version,
    setExtractedPreviewIndex,
    setFilters,
    setIsFileUploaderOpen,
    setSelectedFile,
    versions,
    visibleFiles,
  }

  if (!isLoaded) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <AnimatedLoadingScreen />
      </div>
    )
  }

  return (
    <AdminMediaContext.Provider value={context}>
      {children}
      {isFileUploaderOpen && (
        <div className="tw-absolute tw-w-full tw-inset-x-1/2">
          <FilestackPicker
            apiKey={settings.filestackApiKey}
            onClose={() => setIsFileUploaderOpen(false)}
            pickerOptions={pickerOptions}
            onUploadDone={onUpload}
            fileSizeLimitInBytes={null}
          />
        </div>
      )}
    </AdminMediaContext.Provider>
  )
}

export function useAdminMediaContext(): AdminMediaContextValue {
  return useContext(AdminMediaContext) as AdminMediaContextValue
}

async function getFiles(ticketId: number, filters: AdminMediaFilters): Promise<AdminTicketFile[]> {
  const response = await getTicketFilesForTicket(ticketId, filters.version)
  return response.filter((file) => file.uploadedByCreative === filters.isUploadedByCreative)
}

function updateExtractableFile(oldFile: AdminTicketFile, newFile: AdminTicketFile): AdminTicketFile {
  const { extractedPages, previewUrl } = newFile
  return {
    ...oldFile,
    previewUrl,
    extractedPages,
  }
}
