import React, { Dispatch, SetStateAction } from 'react'

import SingleSelect from 'components/core/single-select'

import { Designer } from 'interfaces/designer'
import { Dpu } from 'interfaces/dpu'
import { findById } from 'lib/array/utils'
import Checkbox from 'components/core/checkbox'

interface GraphicsPremiumOrPowerDesignerSelectProps {
  designers: Designer[]
  dpu: Dpu
  onDesignerChange: (dpuId: number, changes: Record<string, unknown>) => void
  setAssigned: Dispatch<SetStateAction<boolean>>
  userCanAssign: boolean
}

export function GraphicsPremiumOrPowerDesignerSelect(props: GraphicsPremiumOrPowerDesignerSelectProps): JSX.Element {
  const selectedMgDesigner = findById(props.dpu.primaryMgDesignerId, props.designers)
  const selectedBackupMgDesigner = findById(props.dpu.backupMgDesignerId, props.designers)
  const selectedVeDesigner = findById(props.dpu.primaryVeDesignerId, props.designers)
  const selectedBackupVeDesigner = findById(props.dpu.backupVeDesignerId, props.designers)

  return (
    <>
      <DesignerSelect
        checkbox
        designers={props.designers}
        disabled={!props.userCanAssign}
        dpu={props.dpu}
        name="primaryMgDesignerId"
        onDesignerChange={props.onDesignerChange}
        placeholder="Select MG Designer"
        selectedDesigner={selectedMgDesigner}
        setAssigned={props.setAssigned}
      />
      <DesignerSelect
        designers={props.designers}
        disabled={!selectedMgDesigner || !props.userCanAssign}
        dpu={props.dpu}
        name="backupMgDesignerId"
        onDesignerChange={props.onDesignerChange}
        placeholder="Select Backup MG Designer"
        selectedDesigner={selectedBackupMgDesigner}
        setAssigned={props.setAssigned}
      />
      <DesignerSelect
        checkbox
        designers={props.designers}
        disabled={!props.userCanAssign}
        dpu={props.dpu}
        name="primaryVeDesignerId"
        onDesignerChange={props.onDesignerChange}
        placeholder="Select VE Designer"
        selectedDesigner={selectedVeDesigner}
        setAssigned={props.setAssigned}
      />
      <DesignerSelect
        designers={props.designers}
        disabled={!selectedVeDesigner || !props.userCanAssign}
        dpu={props.dpu}
        name="backupVeDesignerId"
        onDesignerChange={props.onDesignerChange}
        placeholder="Select Backup VE Designer"
        selectedDesigner={selectedBackupVeDesigner}
        setAssigned={props.setAssigned}
      />
    </>
  )
}

interface GraphicsProDesignerSelectProps {
  designers: Designer[]
  dpu: Dpu
  userCanAssign: boolean
  onDesignerChange: (dpuId: number, changes: Record<string, unknown>) => void
  setAssigned: Dispatch<SetStateAction<boolean>>
}

export function GraphicsProDesignerSelect(props: GraphicsProDesignerSelectProps): JSX.Element {
  const selectedCiDesigner = findById(props.dpu.primaryCiDesignerId, props.designers)
  const selectedBackupCiDesigner = findById(props.dpu.backupCiDesignerId, props.designers)
  const selectedPdDesigner = findById(props.dpu.primaryPdDesignerId, props.designers)
  const selectedBackupPdDesigner = findById(props.dpu.backupPdDesignerId, props.designers)

  return (
    <>
      <DesignerSelect
        checkbox
        designers={props.designers}
        disabled={!props.userCanAssign}
        dpu={props.dpu}
        name="primaryCiDesignerId"
        onDesignerChange={props.onDesignerChange}
        placeholder="Select CI Designer"
        selectedDesigner={selectedCiDesigner}
        setAssigned={props.setAssigned}
      />
      <DesignerSelect
        designers={props.designers}
        disabled={!selectedCiDesigner || !props.userCanAssign}
        dpu={props.dpu}
        name="backupCiDesignerId"
        onDesignerChange={props.onDesignerChange}
        placeholder="Select Backup CI Designer"
        selectedDesigner={selectedBackupCiDesigner}
        setAssigned={props.setAssigned}
      />
      <DesignerSelect
        checkbox
        designers={props.designers}
        disabled={!props.userCanAssign}
        dpu={props.dpu}
        name="primaryPdDesignerId"
        onDesignerChange={props.onDesignerChange}
        placeholder="Select PD Designer"
        selectedDesigner={selectedPdDesigner}
        setAssigned={props.setAssigned}
      />
      <DesignerSelect
        designers={props.designers}
        disabled={!selectedPdDesigner || !props.userCanAssign}
        dpu={props.dpu}
        name="backupPdDesignerId"
        onDesignerChange={props.onDesignerChange}
        placeholder="Select Backup PD Designer"
        selectedDesigner={selectedBackupPdDesigner}
        setAssigned={props.setAssigned}
      />
    </>
  )
}

interface DesignerSelectProps {
  checkbox?: boolean
  designers: Designer[]
  disabled: boolean
  dpu: Dpu
  onDesignerChange: (dpuId: number, changes: Record<string, unknown>) => void
  placeholder: string
  name: string
  selectedDesigner: Designer
  setAssigned: Dispatch<SetStateAction<boolean>>
}

export function DesignerSelect({
  checkbox,
  designers,
  disabled,
  dpu,
  name,
  onDesignerChange,
  placeholder,
  selectedDesigner,
  setAssigned,
}: DesignerSelectProps): JSX.Element {
  const handleDesignerChange = ({ value }) => {
    if (checkbox) {
      onDesignerChange(dpu.id, { primaryDesignerId: null, [name]: value })
    } else {
      onDesignerChange(dpu.id, { [name]: value })
    }
    setAssigned(false)
  }

  const handlePrimaryDesignerCheck = (e) => {
    const value = e.target.checked ? selectedDesigner.id : null
    onDesignerChange(dpu.id, { primaryDesignerId: value })
    setAssigned(false)
  }

  const designerOptions = designers.map((designer: Designer) => ({
    label: designer.name,
    value: designer.id,
  }))

  return (
    <div className="tw-relative">
      {checkbox && (
        <Checkbox
          checked={selectedDesigner ? dpu.primaryDesignerId === selectedDesigner.id : false}
          disabled={!selectedDesigner}
          onChange={handlePrimaryDesignerCheck}
          label=""
          className="tw-absolute tw--left-8 tw-top-2"
          testId={`${name}-checkbox`}
        />
      )}
      <label htmlFor={`${name}-${dpu.id}`} className="tw-hidden">
        {`${name}-${dpu.id}`}
      </label>
      <SingleSelect
        inputId={`${name}-${dpu.id}`}
        isDisabled={disabled}
        isSearchable
        name="designers-select"
        onChange={(e) => handleDesignerChange(e)}
        options={designerOptions}
        placeholder={placeholder}
        value={
          selectedDesigner
            ? {
                label: selectedDesigner.name,
                value: selectedDesigner.id,
              }
            : null
        }
      />
    </div>
  )
}

DesignerSelect.defaultProps = {
  disabled: false,
}
