import { Format } from 'interfaces/format'
import { baseRequest, BaseResponse, getApi, Metadata, putApi } from 'lib/api/api'

export const routes = {
  index: '/admin/formats',
  edit: (id: number) => `/admin/formats/${id}/edit`,
}

const apiRoutes = {
  index: '/api/internal/admin/formats',
  create: '/api/internal/admin/formats',
  edit: (id: number) => `/api/internal/admin/formats/${id}/edit`,
  update: (id: number) => `/api/internal/admin/formats/${id}`,
  destroy: (id: number) => `/api/internal/admin/formats/${id}`,
}

export interface FormatsResponse extends BaseResponse {
  formats: Format[]
  meta: Metadata
}

export interface FormatResponse extends BaseResponse {
  format: Format
}

export async function getformats(params): Promise<FormatsResponse> {
  const response = await baseRequest(apiRoutes.index, getApi, params)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    formats: data as unknown as Format[],
    meta: meta as Metadata,
  }
}

export async function updateFormat(id, params) {
  return await baseRequest(apiRoutes.update(id), putApi, params)
}
