import { ReactElement, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import ToastProvider, { useToastContext } from 'providers/toast-provider'
import { Transfer } from 'interfaces/transfer'
import TransfersList from 'components/pages/admin/reports/transfers/transfers-list'
import TransfersItem from 'components/pages/admin/reports/transfers/transfers-item'
import Pagination from 'components/elements/pagination'
import TransfersFilters, {
  TransferFilters,
  emptyTransferFilters,
  parsedFilterValues,
} from 'components/pages/admin/reports/transfers/transfers-filters'
import { getInitialFilters } from 'components/elements/admin-filters'
import { hasOnlyFalsyValues } from 'lib/object/utils'
import { EmptyTableScreen, LoadingScreen } from 'components/pages/requests/empty-screens'
import PageHeader from 'components/elements/page-header'

const columnNames = {
  Client: 'company_name',
  Status: 'status',
  'Confidence Level': 'status_prediction',
  'Supporting Documentation': 'supporting_documentation',
  'Status Log': 'status_log',
  'Age of Client': 'company_created_at',
  'Transfer Requested': 'transfer_requested',
  'Transfer Type': 'transfer_type',
  'Reason for Transfer': 'transfer_reason',
  Designers: null,
  'Date of Last Request': 'company_last_ticket_created_at',
  'Transfer Date': 'transfer_date',
  '# of Requests': 'number_of_requests',
}

export interface fetchTransfersProps {
  pageParams?: { page: number }
  sortParams?: { sort_column: string; sort_direction: string }
  formatParams?: { format: string }
  filterParams?: TransferFilters | Record<string, never>
}

const defaultSort = {
  sort_column: 'created_at',
  sort_direction: 'ASC',
}

const downloadCSV = (data: string) => {
  const blob = new Blob([data], { type: 'csv' })

  const a = document.createElement('a')
  a.download = 'transfers.csv'
  a.href = URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

function AdminTransfersReportPage() {
  const initialFilters = getInitialFilters(emptyTransferFilters) as TransferFilters

  const [transfers, setTransfers] = useState([])
  const [perPage, setPerPage] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [transferCount, setTransferCount] = useState(0)
  const [transferReasonsOptions, setTransferReasonsOptions] = useState<string[]>(null)
  const [transferTypesOptions, setTransferTypesOptions] = useState<string[]>(null)
  const [filters, setFilters] = useState<TransferFilters>(parsedFilterValues(initialFilters))
  const [sortColumn, setSortColumn] = useState<string>(null)
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const { alert } = useToastContext()

  const oops = useCallback(() => alert('Oops, something went wrong.'), [alert])

  const setLabel = 'Preventable Transfer'
  const textFilterPlaceholder = 'Search by name or email'

  useEffect(() => {
    if (sortColumn) {
      fetchTransfers().catch(oops)
    }
    // TODO: add memoization to have more reliable dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn, sortDirection])

  useEffect(() => {
    fetchTransfers().catch(oops)

    fetchTransferFieldsOptions().catch(oops)
    // TODO: add memoization to have more reliable dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchTransferFieldsOptions = async () => {
    const { data } = await axios.get('/api/internal/report/designer_transfers/fields')
    const { transfer_reasons, transfer_types } = data

    setTransferReasonsOptions(transfer_reasons)
    setTransferTypesOptions(transfer_types)
  }

  const getSortParams = () => {
    const mappedSortColumn = columnNames[sortColumn]
    if (!mappedSortColumn) return defaultSort

    return {
      sort_column: mappedSortColumn,
      sort_direction: sortDirection,
    }
  }

  const fetchTransfers = async ({
    pageParams,
    sortParams = getSortParams(),
    formatParams = { format: 'json' },
    filterParams = hasOnlyFalsyValues(filters) ? {} : filters,
  }: fetchTransfersProps = {}) => {
    if (formatParams.format === 'json') setIsLoading(true)
    else setIsDownloading(true)

    const { data } = await axios.get(window.Routes.apiInternalReportDesignerTransfersUrl(), {
      params: {
        ...pageParams,
        ...sortParams,
        ...formatParams,
        ...filterParams,
      },
    })

    if (formatParams.format === 'csv') {
      downloadCSV(data)
      setIsDownloading(false)
      return
    }

    const { transfers: transfer_list, per_page, count } = data

    setIsLoading(false)
    setTransfers(transfer_list)
    setPerPage(per_page)
    setTransferCount(count)
  }

  const onPageClick = (data) => {
    setCurrentPage(data.selected)
    fetchTransfers({ pageParams: { page: data.selected + 1 } }).catch(oops)
    window.scrollTo(0, 0)
  }

  const Header = () => (
    <PageHeader title=" Admin Transfers Report " hideDropdown={true}>
      {`Showing ${Math.min(perPage, transfers.length)} out of ${transferCount}`}
    </PageHeader>
  )

  const sortBy = (column = 'created_at', direction: 'ASC' | 'DESC' = 'ASC') => {
    if (sortColumn !== column) setSortColumn(column)
    if (sortDirection !== direction) setSortDirection(direction)
  }

  const TransfersTable = () => {
    if (isLoading) {
      return <LoadingScreen />
    }

    if (transfers.length === 0) return <EmptyTableScreen className="tw-w-10/12" />

    return (
      <div className="tw-w-10/12">
        <TransfersList
          headers={Object.keys(columnNames)}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          sortBy={sortBy}
        >
          {transfers.map((transfer: Transfer) => (
            <TransfersItem transfer={transfer} key={transfer.id} onTransferChange={fetchTransfers} />
          ))}
        </TransfersList>
        <Pagination pageCount={Math.ceil(transferCount / perPage)} onPageChange={onPageClick} forcePage={currentPage} />
      </div>
    )
  }

  return (
    <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row">
      <Header />
      <TransfersFilters
        setFilters={setFilters}
        filters={filters}
        setLabel={setLabel}
        textFilterPlaceholder={textFilterPlaceholder}
        transferReasonsOptions={transferReasonsOptions}
        transferTypesOptions={transferTypesOptions}
        fetchTransfers={fetchTransfers}
        isDownloading={isDownloading}
      />
      <TransfersTable />
    </div>
  )
}

const TransfersPage = (): ReactElement => (
  <ToastProvider>
    <AdminTransfersReportPage />
  </ToastProvider>
)

export default TransfersPage
