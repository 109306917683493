import { ReactElement, useState } from 'react'
import { PauseCircle, Play, StopCircle } from 'lucide-react'

import Button from 'components/core/button'
import AdminStopModal from '../stop-modal/admin-stop-modal'
import CustomerClarificationModal from '../customer-clarification-modal/customer-clarification-modal'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { useToastContext } from 'providers/toast-provider'
import AdminTimer from './admin-timer'

const classNames = {
  button: 'tw-flex tw-items-center tw-gap-2',
}

export default function AdminHeaderCreativeButtons(): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showStopModal, setShowStopModal] = useState<boolean>(false)
  const [showCustomerClarificationModal, setShowCustomerClarificationModal] = useState<boolean>(false)
  const { pause, resume, start, ticket } = useAdminTicketContext()
  const { alert } = useToastContext()

  const ticketPermissions = ticket.meta.permissions

  const canStop = ticketPermissions.stop
  const canStart = ticketPermissions.start
  const canPause = ticketPermissions.pause
  const canResume = ticketPermissions.resume
  const showTimer = canPause || canStart || canStop || canResume

  async function handlePause() {
    setIsLoading(true)

    try {
      await pause()
      setIsLoading(false)
    } catch (error) {
      alert('There was an error pausing this ticket, try again')
    }
  }

  async function handleResume() {
    setIsLoading(true)

    try {
      await resume()
      setIsLoading(false)
    } catch (error) {
      alert('There was an error resuming this ticket, try again')
    }
  }

  async function handleStart() {
    setIsLoading(true)

    try {
      await start()
      setIsLoading(false)
    } catch (error) {
      alert('There was an error start this ticket, try again')
    }
  }

  function handleRequestClarification() {
    setShowCustomerClarificationModal(true)
  }

  function handleStop() {
    setShowStopModal(true)
  }

  return (
    <>
      <div className="tw-flex tw-items-center tw-gap-4">
        {showTimer && <AdminTimer ticket={ticket} />}
        {canPause && (
          <Button className={classNames.button} type="button" onClick={handlePause} color="yellow" disabled={isLoading}>
            <PauseCircle className="lu-light lu-md" /> Pause
          </Button>
        )}
        {canResume && (
          <Button
            className={classNames.button}
            type="button"
            onClick={handleResume}
            color="purple"
            disabled={isLoading}
          >
            <Play className="lu-light lu-md" /> Resume
          </Button>
        )}

        {canStop && (
          <>
            <Button className={classNames.button} type="button" onClick={handleStop} color="red">
              <StopCircle className="lu-light lu-md" /> Stop
            </Button>
            <AdminStopModal open={showStopModal} setOpen={setShowStopModal} />
          </>
        )}

        {ticketPermissions.customerClarification && (
          <>
            <Button type="button" onClick={handleRequestClarification} color="lightGray">
              Request Customer Clarification
            </Button>
            <CustomerClarificationModal
              open={showCustomerClarificationModal}
              setOpen={setShowCustomerClarificationModal}
            />
          </>
        )}
        {canStart && (
          <Button
            className={classNames.button}
            type="button"
            onClick={handleStart}
            color="purple"
            disabled={ticket.inProgressTickets?.length > 1 || isLoading}
          >
            <Play className="lu-light lu-md" /> Start
          </Button>
        )}
      </div>
    </>
  )
}
