import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { ReactNode, useEffect, useRef, useState } from 'react'

export interface DropdownClassNames {
  chevron: string
  chevronContainer: string
  chevronHoverColor: string
  popover: string
  popoverButton: string
  popoverItems: string
  popoverPanel: string
  titleContainer: string
  title: string
  label: string
}

interface DropdownIconProps {
  classNames?: DropdownClassNames
  hovering: boolean
  icon?: IconProp
  isUp: boolean
}

interface CustomSize {
  width?: string
  height?: string
  maxWidth?: string
  maxHeight?: string
  minWidth?: string
  minHeight?: string
}

interface DropdownProps {
  children?: JSX.Element | string
  classNames?: DropdownClassNames
  customSize?: CustomSize
  disabled?: boolean
  icon?: IconProp
  label?: string | JSX.Element
  openByDefault?: boolean
  render?: (close: () => void) => JSX.Element
  size?: 'xs' | 'sm' | 'md' | 'lg'
  title: string | JSX.Element
  Tooltip?: ({ children }: { children: ReactNode }) => JSX.Element
}

const defaultClassNames = {
  chevron: `
    tw-w-5
    tw-h-4
  `,
  chevronContainer: `
    tw-relative
    tw--top-2
    tw-mr-1
    tw-ml-2
    tw-p-2
    tw-rounded-full
    tw-self-end
  `,
  chevronHoverColor: `
    tw-bg-neutral-100
  `,
  popover: `
    tw-relative
    tw-inline-block
    tw-text-left
  `,
  popoverButton: `
    tw-flex
    tw-flex-col
    tw-bg-white
    tw-text-gray-700
    tw-border-none
    tw-px-4
    tw-py-2
  `,
  popoverItems: `
    tw-absolute
    tw-origin-top-left
    tw-left-0
    tw-mt-2
    tw-w-30
    tw-rounded-md
    tw-shadow-lg
    tw-ring-1
    tw-ring-black
    tw-ring-opacity-5
    tw-bg-white
    focus:tw-outline-none
  `,
  popoverPanel: `
    tw-absolute
    tw-z-20
    tw-block
    tw-overflow-y-auto
    tw-bg-white
    tw-p-8
    tw-rounded-md
  `,
  titleContainer: `
    tw-text-neutral-800
    tw-flex
    tw-align-center
  `,
  title: `
    tw-font-semibold
  `,
  label: `
    tw-text-neutral-600 tw-font-sm tw-pb-1
  `,
}

function DropdownIcon({ icon, isUp = true, hovering = false, classNames = defaultClassNames }: DropdownIconProps) {
  function Container({ children }: { children: JSX.Element }): JSX.Element {
    return (
      <div className={`${classNames.chevronContainer} ${hovering ? classNames.chevronHoverColor : ''}`}>{children}</div>
    )
  }

  if (icon) {
    return <FontAwesomeIcon icon={['fal', 'pencil']} size={'md' as SizeProp} className="tw-mt-2 tw-ml-1" />
  }

  if (isUp) {
    return (
      <Container>
        <ChevronUpIcon className={classNames.chevron} aria-hidden="true" />
      </Container>
    )
  }

  return (
    <Container>
      <ChevronDownIcon className={classNames.chevron} aria-hidden="true" />
    </Container>
  )
}

export default function Dropdown({
  classNames = defaultClassNames,
  title,
  label,
  render,
  size = 'md',
  customSize,
  children,
  Tooltip,
  openByDefault = false,
  disabled = false,
  icon,
}: DropdownProps): JSX.Element {
  const [hovering, setHovering] = useState(false)
  const popoverButtonRef = useRef<HTMLButtonElement>()

  const sizes = {
    xs: {
      maxHeight: '600px',
      width: '260px',
    },
    sm: {
      maxHeight: '600px',
      width: '412px',
    },
    md: {
      maxHeight: '600px',
      width: '760px',
    },
    lg: {
      maxHeight: '670px',
      width: '760px',
    },
  }

  const inlineSize = customSize ?? sizes[size]

  useEffect(() => {
    if (openByDefault) {
      popoverButtonRef.current.click()
    }
  }, [openByDefault, popoverButtonRef])

  const disabledButtonStyles = { cursor: 'default', opacity: 0.6 }

  function ButtonWrapper({ children, ...otherProps }) {
    if (disabled) {
      return (
        <button {...otherProps} style={disabledButtonStyles}>
          {children}
        </button>
      )
    }

    return (
      <Popover.Button {...otherProps} ref={popoverButtonRef}>
        {children}
      </Popover.Button>
    )
  }

  function popoverButton(open) {
    return (
      <ButtonWrapper
        className={classNames.popoverButton}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {label && <div className={classNames.label}>{label}</div>}
        <div className={classNames.titleContainer}>
          <div className={classNames.title}>{title}</div>
          <DropdownIcon isUp={open} hovering={hovering && !disabled} classNames={classNames} icon={icon} />
        </div>
      </ButtonWrapper>
    )
  }

  return (
    <Popover as="div" className={classNames.popover}>
      {({ close, open }) => (
        <>
          {Tooltip ? <Tooltip>{popoverButton(open)}</Tooltip> : <>{popoverButton(open)}</>}
          <Popover.Panel
            className={classNames.popoverPanel}
            style={{ ...inlineSize, top: '62px', boxShadow: '0 10px 50px -12px' }}
            data-testid="popover-panel"
          >
            {render && render(close)}
            {children && children}
          </Popover.Panel>
          <Popover.Overlay />
        </>
      )}
    </Popover>
  )
}
