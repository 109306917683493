import { Fragment, HTMLAttributes, ReactElement } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDown, ChevronUp } from 'lucide-react'

export interface SelectBoxOption {
  value: string
  displayElement: ReactElement
}

interface SelectBoxProps {
  options: SelectBoxOption[]
  handleChange: (selectedValue: unknown) => void
  selectedValue: unknown
  label?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
}

const classNames = {
  button: `
    tw-h-10
    tw-w-full
    tw-py-2
    tw-pl-3
    tw-rounded-lg
    tw-border-solid
    tw-border-neutral-200
    tw-bg-white
    tw-flex
    tw-justify-between
    tw-items-center
    tw-text-black
    tw-border
    tw-border-neutral-200
    tw-text-sm
    focus:tw-ring
    focus:tw-ring-cornflower-500
  `,
  options: `
    tw-absolute
    tw-mt-2
    tw-max-h-60
    tw-w-full
    tw-overflow-auto
    tw-rounded-md
    tw-bg-white
    tw-py-1
    tw-shadow-lg
    tw-ring-1
    tw-ring-black
    tw-ring-opacity-5
    tw-z-30
    tw-px-1
    focus:outline-none
  `,
  option: `tw-relative tw-cursor-pointer tw-p-2 tw-list-none`,
}

export default function SelectBox({ options, handleChange, selectedValue, label, className = '' }: SelectBoxProps) {
  const selectedOption = options.find((option) => option.value === selectedValue)

  return (
    <Listbox value={selectedValue} onChange={handleChange}>
      {({ open }) => (
        <div className={`tw-relative tw-mt-1 ${className}`}>
          {label && <Listbox.Label className="tw-text-neutral-500">{label}</Listbox.Label>}
          <Listbox.Button
            className={`${classNames.button} ${open ? 'tw-ring tw-ring-cornflower-500' : ''} ${label ? 'tw-mt-2' : ''}`}
          >
            {!!selectedOption && selectedOption.displayElement}
            {open ? <ChevronDown /> : <ChevronUp />}
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className={classNames.options}>
              {options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `${classNames.option} ${active ? 'tw-bg-cornflower-100 tw-bg tw-text-black' : 'tw-text-gray-900'}`
                  }
                  value={option.value}
                >
                  {option.displayElement}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
