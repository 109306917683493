import { BlobWriter, BlobReader, ZipWriter } from '@zip.js/zip.js'
import { getBlob } from '../../api/api'

export function displayFilesize(filesize: number): string {
  const units = ['B', 'KB', 'MB', 'GB']

  let unitIndex = 0
  let size = filesize

  while (size > 1024) {
    size /= 1024
    unitIndex += 1
  }

  return `${size.toFixed(0)} ${units[unitIndex]}`
}

export interface File {
  name: string
  url: string
}

export async function generateAndDownloadZip(files: File[], zipName: string) {
  const zipWriter = new ZipWriter(new BlobWriter('application/zip'))

  await Promise.all(
    files.map(async (file) => {
      const fileAsBlob = await getBlob(file.url).then((response) => response.data)
      await zipWriter.add(file.name, new BlobReader(new Blob([fileAsBlob])))
    })
  )
  const zipFile = await zipWriter.close()

  downloadZipFile(zipFile, zipName)
}

function downloadZipFile(blob: Blob, name: string) {
  Object.assign(document.createElement('a'), {
    download: `${name}.zip`,
    href: URL.createObjectURL(blob),
  }).click()
}
