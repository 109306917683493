import { ReactElement } from 'react'
import { useRequestContext } from '../providers/request-provider'
import HeaderActions from './header-actions'
import RequestName from './request-name'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import HeaderDropdowns from './header-dropdowns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const classNames = {
  container: 'tw-flex tw-items-start tw-justify-between tw-px-3 tw-pb-3 tw-pt-4',
  rightColumn: 'tw-text-right',
}

function RequestDetails(): ReactElement {
  const { ticket, invalidFields, showValidationErrors, showRevisions } = useRequestContext()

  return (
    <div>
      <div className="tw-flex tw-items-center tw-mb-2">
        <RequestName showValidationError={invalidFields?.includes('subject') && showValidationErrors} />
        <TicketStatusBadge status={ticket?.status} />
      </div>
      {!showRevisions && <HeaderDropdowns />}
    </div>
  )
}

export default function RequestHeader(): JSX.Element {
  const { showRevisions } = useRequestContext()

  return (
    <header>
      {showRevisions && (
        <div className="tw-ml-6 tw-mt-3">
          <a href="/requests" className="tw-text-neutral-400 hover:tw-text-cornflower-500">
            <FontAwesomeIcon icon={['far', 'chevron-left']} className="tw-mr-3" />
            Back to all requests
          </a>
        </div>
      )}
      <div className={classNames.container}>
        <div>
          <RequestDetails />
        </div>
        <div className={classNames.rightColumn}>
          <HeaderActions />
        </div>
      </div>
    </header>
  )
}
