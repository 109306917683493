import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useState } from 'react'
import Modal from 'lib/components/modals/modal'
import QueueTypePlaceholder from './queue-type-placeholder'
import Button from './button'
import { ThumbsUp, ThumbsDown } from 'lucide-react'
import { saveTicketFeedback, updateTicketFeedback } from 'lib/api/ticket-feedbacks/ticket-feedbacks'
import { snakeCaseKeys } from 'lib/object/utils'
import Textarea from 'lib/components/textarea/textarea'
import { Thumbnail, Ticket } from 'interfaces/ticket'
import { RatingsSteps, SetStepType, TicketFeedbackValues } from 'interfaces/ticket_feedback'
import { useToastContext } from 'providers/toast-provider'
import { useTicketFeedbackContext } from 'providers/ticket-feedback-provider'

const classNames = {
  thumbsDownButton: 'tw-mt-4 tw-ml-4',
  thumbsIcon: 'tw-mr-2',
  thumbsUpButton: 'tw-mt-4',
  ticketImage: 'tw-max-h-56 tw-max-w-96',
  ticketTitle: 'tw-mt-4',
}

function Image({ thumbnail }: { thumbnail: Thumbnail }) {
  if (thumbnail.placeholder) return <QueueTypePlaceholder fileName={thumbnail.url} color={'#ADA6AD'} />

  return <img className={classNames.ticketImage} style={{ maxWidth: '24rem' }} src={thumbnail.url} alt="thumbnail" />
}

function Rate({
  setFeedbackId,
  setStep,
  ticket,
}: {
  setFeedbackId: Dispatch<SetStateAction<number>>
  setStep: SetStepType
  ticket: Partial<Ticket>
}) {
  const { id, thumbnail, subject } = ticket

  async function handleSave(thumbRating: TicketFeedbackValues) {
    const params = { ticketId: id, thumbRating }

    try {
      const response = await saveTicketFeedback(snakeCaseKeys(params))
      setFeedbackId(response.id)
    } catch (error) {
      console.error(error)
    } finally {
      if (thumbRating === TicketFeedbackValues.ThumbsUp) {
        setStep(RatingsSteps.ThankYou)
      } else {
        setStep(RatingsSteps.Feedback)
      }
    }
  }

  return (
    <>
      <div>
        <Image thumbnail={thumbnail} />
      </div>
      <div className={classNames.ticketTitle}>{subject}</div>
      <div>
        <Button
          data-testid="thumbs-up-button"
          className={classNames.thumbsUpButton}
          color="lightGray"
          onClick={() => handleSave(TicketFeedbackValues.ThumbsUp)}
        >
          <span>
            <ThumbsUp size={24} className={classNames.thumbsIcon} color="#009672" />
          </span>
          Yes
        </Button>
        <Button
          data-testid="thumbs-down-button"
          className={classNames.thumbsDownButton}
          color="lightGray"
          onClick={() => handleSave(TicketFeedbackValues.ThumbsDown)}
        >
          <span>
            <ThumbsDown size={24} className={classNames.thumbsIcon} color="#E50576" />
          </span>
          No
        </Button>
      </div>
    </>
  )
}

function Feedback() {
  const { feedbackId, ratingModalTicket: ticket, setStep, shuffledReasons } = useTicketFeedbackContext()
  const reasons = ticket.feedbackReasons
  const [feedback, setFeedback] = useState('')
  const [selectedReasons, setSelectedReasons] = useState<number[]>([])

  const classNames = {
    button: 'tw-mt-2',
    feedback: 'tw-mt-2 tw-w-3/4',
    header: 'tw-text-center tw-font-medium tw-text-peppercorn-800 tw-mb-4 tw-text-lg',
    reason:
      'tw-bg-peppercorn-50 tw-p-2 tw-rounded-sm tw-text-peppercorn-900 tw-mr-2 tw-cursor-pointer hover:tw-shadow-lg tw-my-1',
    reasons: 'tw-relative tw-flex tw-items-center tw-justify-center tw-mt-4 tw-w-80 tw-flex-wrap',
    selectedReason:
      'tw-bg-cornflower-100 tw-p-2 tw-rounded-sm tw-text-peppercorn-900 tw-mr-2 tw-cursor-pointer hover:tw-shadow-lg',
    wrapper: 'tw-flex tw-justify-center tw-items-center tw-flex-col',
  }

  async function handleSubmit() {
    const params = {
      id: feedbackId,
      reason: selectedReasons.toString(),
      privateFeedback: feedback,
    }

    updateTicketFeedback(snakeCaseKeys(params))
      .catch(console.error)
      .finally(() => setStep(RatingsSteps.ThankYou))
  }

  function handleSelectReason(reason) {
    if (selectedReasons.includes(reason)) {
      setSelectedReasons(selectedReasons.filter((item) => item !== reason))
    } else {
      setSelectedReasons([...selectedReasons, reason])
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLTextAreaElement>) {
    event.preventDefault()
    setFeedback(event.target.value)
  }

  return (
    <>
      <div className={classNames.wrapper}>
        <div className={classNames.header} style={{ fontSize: '1.1rem', maxWidth: '95%' }}>
          Select one or more areas that didn&apos;t meet your expectations
        </div>
        <div className={classNames.reasons}>
          {shuffledReasons.map((index) => (
            <div
              key={`reason-${reasons[index]}`}
              onClick={() => handleSelectReason(index)}
              className={selectedReasons.includes(index) ? classNames.selectedReason : classNames.reason}
            >
              {reasons[index]}
            </div>
          ))}
        </div>
        <div className={classNames.feedback}>
          <Textarea
            placeholder="Enter additional feedback here"
            value={feedback}
            onChange={handleInputChange}
            rows={3}
          />
        </div>
        <div className={classNames.button}>
          <Button color="purple" onClick={() => handleSubmit()} disabled={!selectedReasons.length}>
            Submit
          </Button>
        </div>
      </div>
    </>
  )
}

export default function ThumbRateRequestModal({
  onModalClose,
}: {
  onModalClose?: (value?: boolean) => void
}): ReactElement {
  const { notice } = useToastContext()
  const {
    ratingModalTicket: ticket,
    setFeedbackId,
    setStep,
    showTicketRatingModal,
    step,
    toggleFeedbackModalVisibility,
  } = useTicketFeedbackContext()

  const classNames = {
    modalHeader: 'tw-text-center tw-font-semibold tw-pr-12',
    modalBody: 'tw-flex tw-justify-center tw-items-center tw-flex-col tw-mt-4 tw-h-96',
  }

  function handleModalClose() {
    toggleFeedbackModalVisibility(false)
    if (onModalClose) {
      onModalClose()
    }
  }

  if (step === RatingsSteps.ThankYou) {
    handleModalClose()
    notice('Thank you for your feedback!')
  }

  function Step() {
    if (step === RatingsSteps.Rate) {
      return <Rate setFeedbackId={setFeedbackId} setStep={setStep} ticket={ticket} />
    }

    return <Feedback />
  }

  function setOpen(open: boolean) {
    if (!open) {
      handleModalClose()
    }
  }

  return (
    <Modal open={showTicketRatingModal} setOpen={setOpen} size="md">
      <Modal.Header>
        <div className={classNames.modalHeader} style={{ fontSize: '1.37rem', lineHeight: '0.15' }}>
          Did this request meet your expectations?
        </div>
      </Modal.Header>
      <Modal.Body setOpen={handleModalClose} closeButton>
        <div className={classNames.modalBody}>
          <Step />
        </div>
      </Modal.Body>
    </Modal>
  )
}
