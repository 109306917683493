import { ReactElement } from 'react'
import { AppSettings, CurrentUser } from 'interfaces/app'
import ToastProvider from 'providers/toast-provider'
import UserProvider from 'providers/user-provider'
import { generateTailwindPalette, setColorVars } from 'lib/util/color'

interface AppProps {
  allowCollaborators?: boolean
  children: ReactElement
  settings: AppSettings
  user: CurrentUser
}

export default function App({ allowCollaborators = false, children, settings, user }: AppProps): ReactElement {
  // `customBranding` is only passed when it's a collaborator page
  if (settings.customBranding?.primaryColor) {
    const customPalette = generateTailwindPalette(settings.customBranding.primaryColor)
    setColorVars('cornflower', customPalette)
  }

  return (
    <ToastProvider>
      <UserProvider settings={settings} user={user} allowCollaborators={allowCollaborators}>
        {children}
      </UserProvider>
    </ToastProvider>
  )
}
