import { ReactElement } from 'react'
import { DroppableProvided } from 'react-beautiful-dnd'
import SortableTableHeader from 'components/core/sortable-table-header'
import TableHead from 'components/core/table-head'
import { DroppableTableRow } from 'components/core/table-row'
import BulkActionsBar from 'components/elements/bulk-actions-bar'
import BulkActionsButtons from 'components/pages/requests/bulk-actions-buttons'
import { useSingleQueueContext } from 'providers/single-queue-provider'

interface HeaderProps {
  column: string
  isSortable?: boolean
  name: ReactElement
}

interface TableHeaderProps {
  droppableProvided: DroppableProvided
}

function TableHeader({ droppableProvided }: TableHeaderProps): ReactElement {
  const { activeTab, sortBy, sortColumn, sortDirection } = useSingleQueueContext()

  const lastUpdatedColumn = activeTab === 'draft' ? 'updated_at' : 'last_updated_for_user'

  const DynamicTableHeader = ({ column, name, isSortable = true }: HeaderProps): ReactElement => (
    <SortableTableHeader
      column={column}
      className="hover:tw-bg-peppercorn-50 hover:tw-text-peppercorn-900 tw-pl-4 tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider tw-whitespace-nowrap"
      sortedClassName="tw-bg-peppercorn-50  tw-text-peppercorn-900"
      onClick={sortBy}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      isSortable={isSortable}
    >
      {name}
    </SortableTableHeader>
  )

  const NonSortableColumn = () => (
    <th
      scope="col"
      className="tw-pl-4 tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-500 tw-uppercase tw-tracking-wider tw-pr-0.5"
    />
  )

  return (
    <TableHead>
      <DroppableTableRow innerRef={droppableProvided.innerRef} droppableProvided={droppableProvided}>
        {activeTab !== 'archived' && (
          <>
            <NonSortableColumn />
            <NonSortableColumn />
          </>
        )}

        {activeTab === 'active' && <DynamicTableHeader column="position" name="#" />}
        <DynamicTableHeader column="subject" name="Title" />
        <DynamicTableHeader column="friendly_status_name" name="Status" />
        <DynamicTableHeader column="queue_name" name="Request Type" />
        {activeTab === 'active' && <DynamicTableHeader column="designer_id" name="Designer" />}
        {activeTab === 'archived' && <DynamicTableHeader column="overall_rating" name="Rating" />}
        <DynamicTableHeader column="user_id" name="Submitted by" />
        {activeTab !== 'draft' && <DynamicTableHeader column={lastUpdatedColumn} name="Last Updated" />}

        <th scope="col" className="tw-relative tw-pl-4 tw-px-6 tw-py-3 tw-w-12 tw-pr-0.5"></th>
      </DroppableTableRow>
    </TableHead>
  )
}

function TableHeaderActions({ droppableProvided }: TableHeaderProps) {
  const { bulkActionIds, menuButtonAction, bulkStateSelected, setBulkActionIds, setBulkStateSelected, tickets } =
    useSingleQueueContext()

  const bulkAllToggle = () => {
    if (bulkActionIds.length === 1) {
      const ticketIdsToAdd = tickets
        .filter(({ friendlyStatusName }) => friendlyStatusName === bulkStateSelected)
        .map((ticket) => ticket.id)
      setBulkActionIds([...ticketIdsToAdd])
    } else {
      setBulkActionIds([])
      setBulkStateSelected(null)
    }
  }

  return (
    <TableHead>
      <DroppableTableRow innerRef={droppableProvided.innerRef} droppableProvided={droppableProvided}>
        <th scope="col" className="tw-px-6 tw-text-left tw-text-xs tw-pr-0.5"></th>
        <th scope="col" colSpan={9} className="tw-text-left tw-text-xs tw-tracking-wider tw-pr-0.5">
          <BulkActionsBar totalSelected={bulkActionIds.length} bulkAllToggle={bulkAllToggle}>
            <BulkActionsButtons status={bulkStateSelected} ticketIds={bulkActionIds} menuActionBtn={menuButtonAction} />
          </BulkActionsBar>
        </th>
      </DroppableTableRow>
    </TableHead>
  )
}

export default function TableHeaderSection({ droppableProvided }: TableHeaderProps): ReactElement {
  const { bulkActionIds } = useSingleQueueContext()

  return bulkActionIds.length > 0 ? (
    <TableHeaderActions droppableProvided={droppableProvided} />
  ) : (
    <TableHeader droppableProvided={droppableProvided} />
  )
}
