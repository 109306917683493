import AdminFilters, { SearchTitleFilter } from 'components/elements/admin-filters'
import { Dispatch } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CancellationReasonsFilter,
  RefundStatusFilter,
  DateFilter,
  PredictedLabelsFilter,
} from '../shared/filters/filters'

import Button from 'components/core/button'

import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { fetchCancellationsProps } from './cancellations-page'
import styles from '../shared/filters/filters.module.scss'

const dateFilters = [
  'cancellationStartDate',
  'cancellationEndDate',
  'cancellationRequestStartDate',
  'cancellationRequestEndDate',
]
interface CancellationsFilterProps {
  setFilters: Dispatch<CancellationFilters>
  filters: CancellationFilters
  setLabel: string
  textFilterPlaceholder: string
  cancellationReasonsOptions: string[]
  refundStatusOptions: string[]
  predictedLabelsOptions: Record<string, number>
  fetchCancellations: (params?: fetchCancellationsProps) => void
  isDownloading: boolean
}
export interface CancellationFilters {
  cancellationReasonsEq: string
  companyNameCont: string
  refundStatusEq: string
  predictedLabelsEq: number
  cancellationStartDate: Date
  cancellationEndDate: Date
  cancellationRequestStartDate: Date
  cancellationRequestEndDate: Date
}

export const parsedFilterValues = (filters: CancellationFilters): CancellationFilters => {
  const newFilters = { ...filters }

  const { cancellationStartDate, cancellationEndDate, cancellationRequestStartDate, cancellationRequestEndDate } =
    newFilters

  if (cancellationStartDate) newFilters.cancellationStartDate = new Date(Date.parse(cancellationStartDate as string))
  if (cancellationEndDate) newFilters.cancellationEndDate = new Date(Date.parse(cancellationEndDate as string))
  if (cancellationRequestStartDate)
    newFilters.cancellationRequestStartDate = new Date(Date.parse(cancellationRequestStartDate as string))
  if (cancellationRequestEndDate)
    newFilters.cancellationRequestEndDate = new Date(Date.parse(cancellationRequestEndDate as string))

  return newFilters
}

export const emptyCancellationFilters: CancellationFilters = {
  cancellationReasonsEq: '',
  companyNameCont: '',
  refundStatusEq: '',
  predictedLabelsEq: null,
  cancellationStartDate: null,
  cancellationEndDate: null,
  cancellationRequestStartDate: null,
  cancellationRequestEndDate: null,
}

export default function CancellationFilters(props: CancellationsFilterProps): JSX.Element {
  const {
    cancellationReasonsOptions,
    filters,
    refundStatusOptions,
    predictedLabelsOptions,
    setFilters,
    textFilterPlaceholder,
    fetchCancellations,
    isDownloading,
  } = props

  const { cancellationStartDate, cancellationEndDate, cancellationRequestStartDate, cancellationRequestEndDate } =
    filters

  const setCancellationStartDate = (date: Date) => {
    setFilters({ ...filters, cancellationStartDate: date })
  }

  const setCancellationEndDate = (date: Date) => {
    setFilters({ ...filters, cancellationEndDate: date })
  }

  const setCancellationRequestStartDate = (date: Date) => {
    setFilters({ ...filters, cancellationRequestStartDate: date })
  }

  const setCancellationRequestEndDate = (date: Date) => {
    setFilters({ ...filters, cancellationRequestEndDate: date })
  }

  const copyFiltersToClipboard = async () => {
    const sanitizedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (!value) return acc
      if (dateFilters.includes(key)) {
        return [...acc, [key, value?.toISOString()]]
      }
      return [...acc, [key, value]]
    }, [])
    const urlParams = new URLSearchParams(sanitizedFilters).toString()
    await navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}?${urlParams}`)
    window.alert('Filters copied to clipboard!')
  }

  const DownloadCSV = () => (
    <div>
      <Button
        className="tw-ml-2"
        size="cut"
        color="green"
        type="button"
        onClick={() => fetchCancellations({ formatParams: { format: 'csv' } })}
      >
        <FontAwesomeIcon icon={'download'} className="tw-mr-1" />
      </Button>
    </div>
  )

  return (
    <AdminFilters
      emptyFilters={emptyCancellationFilters}
      filters={filters}
      setFilters={setFilters}
      handleSubmit={() => fetchCancellations()}
      downloadCSV={isDownloading ? () => <LoadingScreen /> : DownloadCSV}
      onClear={fetchCancellations}
    >
      <SearchTitleFilter
        value={filters.companyNameCont}
        updateValue={(value) =>
          setFilters({
            ...filters,
            companyNameCont: value,
          })
        }
        placeholder={textFilterPlaceholder}
      />

      <CancellationReasonsFilter
        cancellationReasonsOptions={cancellationReasonsOptions}
        filters={filters}
        filterKey="cancellationReasonsEq"
        setFilters={setFilters}
      />

      <RefundStatusFilter
        refundStatusOptions={refundStatusOptions}
        filters={filters}
        filterKey="refundStatusEq"
        setFilters={setFilters}
      />

      <PredictedLabelsFilter
        predictedLabelsOptions={predictedLabelsOptions}
        filters={filters}
        filterKey="predictedLabelsEq"
        setFilters={setFilters}
      />

      <label className={styles.filterTitle} htmlFor="cancellation-date-range">
        Cancellation Date Range
      </label>

      <DateFilter selected={cancellationStartDate} onChange={setCancellationStartDate} placeholder="Start Date" />

      <DateFilter selected={cancellationEndDate} onChange={setCancellationEndDate} placeholder="End Date" />

      <label className={styles.filterTitle} htmlFor="cancellation-requested-date-range">
        Cancellation Requested Date Range
      </label>

      <DateFilter
        selected={cancellationRequestStartDate}
        onChange={setCancellationRequestStartDate}
        placeholder="Start Date"
      />

      <DateFilter
        selected={cancellationRequestEndDate}
        onChange={setCancellationRequestEndDate}
        placeholder="End Date"
      />

      <Button color="outlineDark" type="button" className="tw-mb-4" onClick={copyFiltersToClipboard}>
        Share filters
      </Button>
    </AdminFilters>
  )
}
