import { createContext, Dispatch, ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import { createConversationComment, getConversations, ConversationComment } from 'lib/api/conversations/conversations'
import { useRequestContext } from '../providers/request-provider'

interface ConversationsContextValue {
  addComment: (body: string) => Promise<void>
  comments: ConversationComment[]
  conversationHasError: boolean
  retryLoadConversation: () => void
  subscribeToConversations: (event: string, callback: SubscriptionCallback) => void
  unsubscribeToConversations: (event: string, callback: SubscriptionCallback) => void
}

type SubscriptionCallback = (comment: ConversationComment) => void

interface ConversationsProviderProps {
  children: ReactNode
}

const ConversationsContext = createContext({})

function loadConversation(
  ticketId: number,
  setComments: Dispatch<ConversationComment[]>,
  setConversationHasError: Dispatch<boolean>
) {
  getConversations(ticketId).then(
    (response) => {
      setComments(response)
      setConversationHasError(false)
    },
    (error) => {
      console.error('There was a problem loading conversations.', error)
      setConversationHasError(true)
    }
  )
}

/**
 * @deprecated
 */
export default function ConversationsProvider({ children }: ConversationsProviderProps): ReactElement {
  const [comments, setComments] = useState<ConversationComment[]>([])
  const [conversationHasError, setConversationHasError] = useState<boolean>(null)
  const { ticket } = useRequestContext()

  async function addComment(body: string) {
    try {
      const response = await createConversationComment(ticket.id, body)
      setComments((previous) => [...previous, response])
    } catch (error) {
      alert('There was a problem adding the comment. Please try again.')
      throw error
    }
  }

  const context = {
    addComment,
    comments,
    conversationHasError,
    retryLoadConversation,
  }

  function retryLoadConversation() {
    loadConversation(ticket.id, setComments, setConversationHasError)
  }

  useEffect(() => {
    if (ticket?.id) {
      loadConversation(ticket.id, setComments, setConversationHasError)
    }
  }, [ticket?.id])

  return <ConversationsContext.Provider value={context}>{children}</ConversationsContext.Provider>
}

/**
 * @deprecated
 */
export function useConversationsContext() {
  return useContext(ConversationsContext) as ConversationsContextValue
}
