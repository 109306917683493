import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useAdminTicketContext } from './admin-ticket-provider'
import {
  AdminTimelineFilters,
  AdminTimelineItem,
  areAllTasksComplete,
  createDetailTask,
  deleteTimelineItem,
  getTimelineItems,
  toggleCompletedStatus,
  updateTimelineItem,
} from 'lib/api/admin/timeline/admin-timeline'
import { useToastContext } from 'providers/toast-provider'
import {
  AdminConversationCreateOptionalParams,
  createConversation,
} from 'lib/api/admin/conversation/admin-conversation'

interface AdminTimelineContextValue {
  areAllTasksComplete: () => Promise<boolean>
  deleteTimelineItem: (item: AdminTimelineItem) => Promise<void>
  fetchAndSetTimelineItems: () => Promise<void>
  getLastDraftMessage: () => Promise<AdminTimelineItem>
  getNextPage: () => Promise<void>
  hasNextPage: boolean
  orderedTimelineItems: AdminTimelineItem[]
  selectedFilters: AdminTimelineFilters
  setSelectedFilters: Dispatch<SetStateAction<AdminTimelineFilters>>
  toggleCompletedStatus: (item: AdminTimelineItem) => Promise<AdminTimelineItem>
  updateTimelineItem: (item: AdminTimelineItem, bodyText: string, draft?: boolean) => Promise<void>
  createConversation: (body: string, params?: AdminConversationCreateOptionalParams) => Promise<void>
  createPrivateDetailTask: (description: string) => Promise<void>
  initialLoadComplete: boolean
  setInitialLoadComplete: Dispatch<SetStateAction<boolean>>
}

const AdminTimelineContext = createContext({})

interface AdminTimelineContextProps {
  children: ReactNode
}

type AdminDirectionsResponseTuple = [AdminTimelineItem[], string]

async function getFilteredAdminTimelineItems(
  ticketId: number,
  filters: AdminTimelineFilters,
  nextPageUrl: string,
  alert: (message: string) => void
): Promise<AdminDirectionsResponseTuple> {
  try {
    const { data, meta } = await getTimelineItems(ticketId, filters, nextPageUrl)
    const dataWithUniqueIds = data.map((item) => ({ ...item, key: `${item.taskType}-${item.id}` }))
    return [dataWithUniqueIds, meta.nextPage]
  } catch (error) {
    console.error('Error fetching admin timeline items', error)
    alert('Error fetching directions, try again later.')
    return null
  }
}

export default function AdminTimelineProvider({ children }: AdminTimelineContextProps): ReactElement {
  const [adminTimelineItems, setAdminTimelineItems] = useState<AdminTimelineItem[]>([])
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)
  const [nextPageUrl, setNextPageUrl] = useState<string>(null)
  const isAbandoned = useRef(false)

  const { ticket } = useAdminTicketContext()
  const { alert } = useToastContext()

  const [selectedFilters, setSelectedFilters] = useState<AdminTimelineFilters>({
    ticketVersion: ticket.currentVersion || 1,
  })

  async function getNextPage() {
    if (nextPageUrl) {
      const itemsResponseTuple = await getFilteredAdminTimelineItems(ticket.id, selectedFilters, nextPageUrl, alert)

      if (itemsResponseTuple) {
        setAdminTimelineItems((previousAdminTimelineItems) => {
          if (previousAdminTimelineItems.length === 0) {
            return itemsResponseTuple[0]
          }

          // The API returns an empty array when there are no more items to fetch
          if (itemsResponseTuple[0].length === 0) {
            return previousAdminTimelineItems
          }

          return previousAdminTimelineItems.concat(...itemsResponseTuple[0])
        })

        setNextPageUrl(itemsResponseTuple[1] || null)
      }
    }
  }

  const createConversationByAPI = useCallback(
    async (body: string, params?: AdminConversationCreateOptionalParams) => {
      const response = await createConversation(ticket.id, body, params || null)
      if (response.ticketVersion === selectedFilters.ticketVersion || selectedFilters.ticketVersion === 0) {
        setAdminTimelineItems((previousAdminTimelineItems) => [
          ...previousAdminTimelineItems,
          response as unknown as AdminTimelineItem,
        ])
      }
    },
    [selectedFilters.ticketVersion, ticket.id]
  )

  const updateTimelineItemByAPI = useCallback(
    async (item: AdminTimelineItem, bodyText: string, draft?: boolean) => {
      const response = await updateTimelineItem({ ticketId: ticket.id, item: item, body: bodyText, draft: draft })
      if (response.ticketVersion === selectedFilters.ticketVersion || selectedFilters.ticketVersion === 0) {
        setAdminTimelineItems((previousAdminTimelineItems) =>
          previousAdminTimelineItems.map((i) => (i.id === response.id ? response : i))
        )
      }
    },
    [selectedFilters.ticketVersion, ticket.id]
  )

  const deleteTimelineItemByAPI = useCallback(
    async (item: AdminTimelineItem) => {
      await deleteTimelineItem(ticket.id, item)
      setAdminTimelineItems((previousAdminTimelineItems) => previousAdminTimelineItems.filter((i) => i.id !== item.id))
    },
    [ticket.id]
  )

  const toggleCompletedStatusByAPI = useCallback(
    async (item: AdminTimelineItem) => {
      const response = await toggleCompletedStatus(ticket.id, item)
      setAdminTimelineItems((previousAdminTimelineItems) =>
        previousAdminTimelineItems.map((i) => (i.id === response.id ? response : i))
      )
      return response
    },
    [ticket.id]
  )

  const createPrivateDetailTask = useCallback(
    async (description: string) => {
      const response = await createDetailTask(ticket.id, description, true)
      if (response.ticketVersion === selectedFilters.ticketVersion || selectedFilters.ticketVersion === 0) {
        setAdminTimelineItems((previousAdminTimelineItems) => [
          ...previousAdminTimelineItems,
          response as unknown as AdminTimelineItem,
        ])
      }
    },
    [selectedFilters.ticketVersion, ticket.id]
  )

  const areAllTasksCompletedApiCall = useCallback(async () => {
    return await areAllTasksComplete(ticket.id)
  }, [ticket.id])

  useEffect(() => {
    isAbandoned.current = false

    if (ticket.id) {
      fetchAndSetTimelineItems()
    }
    return () => {
      isAbandoned.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters.ticketVersion, ticket.id])

  const fetchAndSetTimelineItems = useCallback(async () => {
    setAdminTimelineItems([])
    setNextPageUrl(null)
    setInitialLoadComplete(false)

    getFilteredAdminTimelineItems(ticket.id, selectedFilters, null, alert).then((itemsResponseTuple) => {
      if (!isAbandoned.current && itemsResponseTuple) {
        const [items, nextPageKey] = itemsResponseTuple
        setAdminTimelineItems((previousAdminTimelineItems) => {
          if (previousAdminTimelineItems.length === 0) {
            return items
          }
          return previousAdminTimelineItems.concat(...items)
        })
        setInitialLoadComplete(true)
        setNextPageUrl(nextPageKey || null)
      }
    })
  }, [alert, selectedFilters, ticket.id])

  const recursivelyGetLastDraftMessage = useCallback(
    async (response, nextPageUrl) => {
      const nextPageResponse = await getTimelineItems(ticket.id, { ticketVersion: ticket.currentVersion }, nextPageUrl)
      const lastDraftMessage = nextPageResponse?.data
        ?.reverse()
        ?.find((item) => item.draft && item.taskType === 'conversation')
      if (lastDraftMessage) {
        return lastDraftMessage
      } else if (nextPageResponse?.meta?.nextPage) {
        return recursivelyGetLastDraftMessage(nextPageResponse, nextPageResponse.meta.nextPage)
      } else {
        return null
      }
    },
    [ticket.currentVersion, ticket.id]
  )

  const getLastDraftMessage = useCallback(async () => {
    const lastDraft = adminTimelineItems?.reverse()?.find((item) => item.draft && item.taskType === 'conversation')

    if (!lastDraft) {
      return recursivelyGetLastDraftMessage(null, null)
    }

    return lastDraft
  }, [adminTimelineItems, recursivelyGetLastDraftMessage])

  const contextValue: AdminTimelineContextValue = {
    areAllTasksComplete: areAllTasksCompletedApiCall,
    createConversation: createConversationByAPI,
    createPrivateDetailTask,
    deleteTimelineItem: deleteTimelineItemByAPI,
    fetchAndSetTimelineItems,
    getLastDraftMessage,
    getNextPage,
    hasNextPage: !!nextPageUrl,
    initialLoadComplete,
    orderedTimelineItems: adminTimelineItems,
    selectedFilters,
    setInitialLoadComplete,
    setSelectedFilters,
    toggleCompletedStatus: toggleCompletedStatusByAPI,
    updateTimelineItem: updateTimelineItemByAPI,
  }

  return <AdminTimelineContext.Provider value={contextValue}>{children}</AdminTimelineContext.Provider>
}

export function useAdminTimelineContext() {
  return useContext(AdminTimelineContext) as AdminTimelineContextValue
}
