import Pagination from 'components/elements/pagination'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import { SkillCategory } from 'interfaces/skill-category'
import { getSkillCategories, routes } from 'lib/api/admin/skill-categories/skill-categories'
import { snakeCaseKeys } from 'lib/object/utils'
import ToastProvider, { useToastContext } from 'providers/toast-provider'
import { useCallback, useEffect, useState } from 'react'
import AdminSkillCategoriesPageFilters from './admin-skill-categories-page-filters'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  header: 'tw-flex tw-justify-between navbar-custom',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

export interface Filters {
  nameCont?: string
}

export const emptyFilters: Filters = {
  nameCont: '',
}

function AdminSkillCategories() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [filters, setFilters] = useState<Filters>(emptyFilters)
  const [skillCategories, setSkillCategories] = useState<SkillCategory[]>([])
  const { alert } = useToastContext()

  useEffect(() => {
    fetchSkillCategories(currentPage, filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const fetchSkillCategories = useCallback(
    async (currentPage: number, filters: Filters) => {
      setIsLoading(true)
      try {
        const { skillCategories, meta } = await getSkillCategories({ page: currentPage + 1, q: snakeCaseKeys(filters) })
        setSkillCategories(skillCategories)
        setPageCount(Math.ceil(meta.total / meta.pageSize))
        setCount(meta.total)
      } catch (error) {
        console.error(error)
        alert('Failed to fetch skill categories')
      } finally {
        setIsLoading(false)
      }
    },
    [alert]
  )

  function onPageClick({ selected }) {
    setCurrentPage(selected)
    window.scrollTo(0, 0)
  }

  function handleSubmit(e) {
    e.preventDefault()
    fetchSkillCategories(currentPage, filters)
  }

  function handleClear(e) {
    e.preventDefault()
    setFilters(emptyFilters)
    fetchSkillCategories(0, emptyFilters)
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <h4>Skill Categories</h4>
        {!isLoading && `Showing ${skillCategories.length} out of ${count} skill categories`}
      </div>
      <div className={classNames.container}>
        <AdminSkillCategoriesPageFilters
          filters={filters}
          setFilters={setFilters}
          onSubmit={handleSubmit}
          handleClear={handleClear}
        />
        <div className={classNames.tableWrapper}>
          <table className={classNames.table}>
            <TableHeader />
            <tbody>
              {isLoading ? (
                <LoadingScreenTableRow />
              ) : (
                skillCategories.map(({ id, name, scopeOfService }) => (
                  <tr key={id} className={classNames.tableRow}>
                    <td className={classNames.tableCell}>
                      <a href={routes.edit(id)}>{name}</a>
                    </td>
                    <td className={classNames.tableCell}>{scopeOfService}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination pageCount={pageCount} onPageChange={onPageClick} forcePage={currentPage} />
      </div>
    </div>
  )
}

const TableHeader = () => (
  <thead>
    <tr>
      <th className={classNames.tableHeadCell}>Name</th>
      <th className={classNames.tableHeadCell}>Subscription Type</th>
    </tr>
  </thead>
)

export default function AdminSkillCategoriesPage() {
  return (
    <ToastProvider>
      <AdminSkillCategories />
    </ToastProvider>
  )
}
