import { ReactElement, useEffect } from 'react'
import { EditMode } from 'lib/api/tickets/tickets'
import Comment from './comments/comment'
import CommentForm from './comments/comment-form'
import hyperlink from 'lib/util/hyperlink'
import CommentsNotLoaded from './comments-not-loaded'
import { useConversationsContext } from './conversations-provider'
import { useRequestContext } from '../providers/request-provider'

interface ConversationsProps {
  onLoad: (behaviorIsSmooth?: boolean) => void
}

const classNames = {
  list: 'tw-list-none tw-p-0',
  reply: 'tw-sticky tw-bottom-0 tw-py-5 tw-bg-white',
}

/**
 * @deprecated
 */
export default function Conversations({ onLoad }: ConversationsProps): ReactElement {
  const { addComment, conversationHasError, comments, retryLoadConversation } = useConversationsContext()
  const { editMode, fetchAndSetTicket } = useRequestContext()

  useEffect(() => {
    // Scroll to the bottom of the conversations when this view is mounted for the first time
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function submitComment(body: string) {
    await addComment(body)
    onLoad(true)
    fetchAndSetTicket()
  }

  return (
    <div className="tw-pt-6">
      <h2 className="tw-text-base tw-m-0">Conversation Thread</h2>
      <p>Want to reply directly to your Designer? Drop them a line below.</p>
      {conversationHasError && <CommentsNotLoaded retry={retryLoadConversation} />}
      {!conversationHasError && comments.length > 0 && (
        <ol className={classNames.list}>
          {comments.map((comment) => (
            <li key={comment.id}>
              <Comment body={hyperlink(comment.body)} user={comment.user} createdAt={comment.createdAt} />
            </li>
          ))}
        </ol>
      )}
      {!conversationHasError && comments.length === 0 && <strong>The conversation has not started.</strong>}
      {editMode !== EditMode.complete && (
        <div className={classNames.reply}>
          <CommentForm onSend={submitComment} />
        </div>
      )}
    </div>
  )
}
