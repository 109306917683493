import { useMemo, useState } from 'react'
import { allSizesAsSkillSizes, skillSizeDetails } from 'lib/util/skill-sizes/skill-sizes'
import Button from 'components/core/button'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AdminEditTicketDetailsModal from './admin-edit-ticket-details-modal'
import { Pencil } from 'lucide-react'

export default function AdminTicketDetails() {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { ticket } = useAdminTicketContext()

  const allSizes = useMemo(() => {
    return allSizesAsSkillSizes(ticket.selectedSkillSizes, ticket.size).map(skillSizeDetails)
  }, [ticket])

  const classNames = {
    detailItem: 'tw-flex tw-flex-col tw-gap-1',
    detailItemTitle: 'tw-text-gray-400',
  }

  return (
    <div>
      <header className="tw-border-solid tw-border-0 tw-border-b tw-border-gray-200 tw-p-4">
        <h4 className="tw-m-0">Ticket Details</h4>
      </header>
      <div className="tw-p-4 tw-flex tw-flex-col tw-gap-4">
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Client name</span>
          <span>
            <a
              href={`/admin/companies?company_id=${ticket.company.id}`}
              target="_blank"
              rel="noreferrer"
              className="tw-font-semibold"
            >
              {ticket.company.name}
            </a>
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Request type</span>
          <span>{ticket.skill.name}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>File size(s)</span>
          <>
            {allSizes.map((size) => (
              <div key={size}>{size}</div>
            ))}
          </>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>File format(s)</span>
          <span>{ticket.selectedFormats.join(', ')}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Design time</span>
          <span>
            <span className="tw-text-black">{ticket.designTime} min</span>
            <span className="tw-text-neutral-600"> / {ticket?.companyDailyAvailableTime / 60} hr</span>
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Brand Profile</span>
          <span>
            {!!ticket.selectedBrand && (
              <a href="#" className="tw-font-semibold">
                {ticket?.selectedBrand.name}
              </a>
            )}
            {!ticket.selectedBrand && <em>None</em>}
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Number of revisions</span>
          <span>{ticket.revisions}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Customer position</span>
          <span>{ticket.position}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Updated</span>
          <span>{new Date(ticket.updatedAt).toLocaleString()}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Submitted</span>
          <span>{ticket.submittedAt ? new Date(ticket.submittedAt).toLocaleString() : 'Unknown'}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Creative</span>
          <span>{ticket.designer ? ticket.designer.name : 'No Creative Associated'}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Source</span>
          <span>{ticket.source}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Is this a training ticket?</span>
          <span>{ticket.training ? 'Yes' : 'No'}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Covered by a different designer?</span>
          <span>{ticket.covered ? 'Yes' : 'No'}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Did the designer make an error?</span>
          <span>{ticket.designerError ? 'Yes' : 'No'}</span>
        </div>
        <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-sticky tw-bottom-6">
          <Button color="lightGray" onClick={() => setEditModalOpen(true)} className="tw-flex tw-items-center tw-gap-2">
            <Pencil className="lu-light lu-md" /> Edit Ticket Details
          </Button>
          <AdminEditTicketDetailsModal open={editModalOpen} setOpen={setEditModalOpen} />
        </div>
      </div>
    </div>
  )
}
