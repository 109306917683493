import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { CustomSize, getSkill, Skill, SkillResponse, SkillSize } from 'lib/api/skills/skills'
import { Brand } from 'lib/api/companies/companies'
import {
  Designer,
  DesignRequest,
  EditMode,
  DesignRequestStatus,
  getDesigners,
  getTicketRequest,
  updateTicket,
} from 'lib/api/tickets/tickets'
import { AbilityResponse, fetchAbility } from 'lib/api/ability/ability'
import { useUserContext } from 'providers/user-provider'
import { getEditMode } from './edit-mode'
import {
  convertCustomSizesToString,
  convertStringToCustomSize,
  customSizeFilter,
} from 'lib/util/skill-sizes/skill-sizes'

type DispatchAction = 'skill' | 'formats' | 'sizes' | 'subject' | 'brand' | 'designer'

interface DispatchPayload {
  subject?: string
  formats?: string[]
  skillSizes?: SkillSize[]
  customSizes?: CustomSize[]
  skill?: Skill
  brand?: Brand
  preferredDesigner?: Designer
}

interface RequestContextProps {
  children: ReactElement
}

interface RequestContextValue {
  ability: AbilityResponse
  canShare: boolean
  customSizes: CustomSize[]
  dispatch: (action: DispatchAction, payload: DispatchPayload) => void
  editMode: EditMode
  fetchAndSetTicket?: () => void
  invalidFields: string[]
  possibleDesigners: Designer[]
  setShowValidationErrors: (showValidationErrors: boolean) => void
  setTicket: Dispatch<SetStateAction<DesignRequest>>
  showRevisions: boolean
  showValidationErrors: boolean
  skillSizes: SkillSize[]
  ticket: DesignRequest
  ticketError: Error
  ticketId: number
}

const RequestContext = createContext({})

function getIdFromUrl(): number {
  const exp = /\/tickets\/(\d+)/
  const idString = window.location.pathname.match(exp)[1]
  return Number(idString)
}

export function useRequestContext(): RequestContextValue {
  return useContext(RequestContext) as RequestContextValue
}

export default function RequestProvider({ children }: RequestContextProps): ReactElement {
  const [ticket, setTicket] = useState<DesignRequest>()
  const [skillSizes, setSkillSizes] = useState<SkillSize[]>([])
  const [customSizes, setCustomSizes] = useState<CustomSize[]>([])
  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const [possibleDesigners, setPossibleDesigners] = useState<Designer[]>([])
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false)
  const [ticketError, setTicketError] = useState<Error>(null)
  const [ability, setAbility] = useState<AbilityResponse>(null)

  const ticketId = useMemo(() => ticket?.id || null, [ticket?.id])

  const { user } = useUserContext()

  useEffect(() => {
    if (user.canChooseDesigners) {
      let isAbandoned = false

      if (ticket?.id && ticket?.skill?.id) {
        getDesigners(ticket.id).then(({ designers }) => {
          if (!isAbandoned) {
            setPossibleDesigners(designers)
          }
        })
      }

      return () => {
        isAbandoned = true
      }
    }
  }, [ticket?.id, ticket?.skill?.id, user.canChooseDesigners])

  const canShare = useMemo(() => {
    return (
      (user.id === ticket?.submittedBy?.id || user.isCompanyAdmin || user.isDPAdmin) &&
      user.collaborativeReviewEnabled &&
      !user.graphicsOnlyCustomer &&
      (ticket?.status === DesignRequestStatus.review || !!ticket?.meta?.sharing?.id)
    )
  }, [
    user.collaborativeReviewEnabled,
    user.graphicsOnlyCustomer,
    user.id,
    user.isCompanyAdmin,
    user.isDPAdmin,
    ticket?.meta?.sharing?.id,
    ticket?.submittedBy?.id,
    ticket?.status,
  ])

  const fetchSkillCallback = useCallback((skillId: number): Promise<SkillResponse | void> => {
    return getSkill(skillId).then(
      (response) => {
        setSkillSizes(response.skill.skillSizes)
        return response
      },
      (err) => {
        console.error('There was a problem fetching the skill', err)
      }
    )
  }, [])

  const saveTicketCallback = useCallback(
    (payload) => {
      updateTicket(ticket?.id, payload).catch((err) => console.error(err))
    },
    [ticket?.id]
  )

  const editMode = useMemo(() => {
    if (!ticket?.status) {
      return null
    }
    if (!user.newRevisionsExperienceEnabled) {
      return EditMode.draft
    }
    return getEditMode(ticket?.status, ticket?.isNewState, ticket?.currentVersion, ticket?.lastDeliveredVersion)
  }, [
    user.newRevisionsExperienceEnabled,
    ticket?.status,
    ticket?.isNewState,
    ticket?.currentVersion,
    ticket?.lastDeliveredVersion,
  ])

  const showRevisions = useMemo(() => editMode !== EditMode.draft, [editMode])

  async function fetchTicket() {
    const ticketId = getIdFromUrl()
    try {
      const { ticket } = (await getTicketRequest(ticketId)) as { ticket: DesignRequest }
      setTicket(ticket)
      setSkillSizes(ticket.skill.skillSizes)
      setCustomSizes(convertStringToCustomSize(ticket.size))
      if (!ability) {
        const abilityResponse = await fetchAbility('Ticket', ticket.id)
        setAbility(abilityResponse)
      }
      return ticket
    } catch (e) {
      setTicketError(e)
      throw e
    }
  }

  useEffect(() => {
    fetchTicket().catch((err) => {
      console.error('Problem fetching ticket', err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function dispatch(action: DispatchAction, payload: DispatchPayload) {
    switch (action) {
      case 'formats':
        saveTicketCallback({ formats: payload.formats })
        setTicket((previous: DesignRequest) => {
          return {
            ...previous,
            selectedFormats: payload.formats,
          }
        })
        break
      case 'sizes':
        saveTicketCallback({
          skill_size_ids: payload?.skillSizes?.map((s) => s.id),
          size: convertCustomSizesToString(payload?.customSizes) || null,
        })

        if (payload.skillSizes) {
          setTicket((previous: DesignRequest) => {
            return {
              ...previous,
              selectedSkillSizes: payload.skillSizes,
            }
          })
        }

        if (payload.customSizes) {
          setTicket((previous: DesignRequest) => {
            return {
              ...previous,
              size: convertCustomSizesToString(payload?.customSizes) || null,
            }
          })
          setCustomSizes(payload.customSizes.filter(customSizeFilter))
        }
        break
      case 'skill':
        fetchSkillCallback(payload.skill.id).then(async (skillResponse: SkillResponse) => {
          const selectedFormats = ticket.selectedFormats || []

          try {
            const ticketResponse = (await updateTicket(ticket.id, { skill_id: payload.skill.id, formats: [] })) as {
              ticket: DesignRequest
            }
            const availableFormats = ticketResponse.ticket.availableFormats
            const formats = selectedFormats.filter((sf) => availableFormats.includes(sf))

            setTicket(function (previous: DesignRequest) {
              const patch = {
                skill_id: payload.skill.id,
                skill_size_ids: [],
                preferred_designer_id: null,
                formats,
              }

              saveTicketCallback(patch)

              return {
                ...previous,
                selectedSkillSizes: [],
                availableFormats: availableFormats,
                selectedFormats: formats,
                preferredDesigner: null,
                skill: skillResponse.skill as Skill,
              }
            })
          } catch (e) {
            alert('There was a problem changing request types')
            throw new Error(`There was a problem updating the ticket: ${e}`)
          }
        })
        break
      case 'subject':
        setTicket((previous) => {
          return {
            ...previous,
            ...payload,
          }
        })

        if (payload.subject === '') {
          payload.subject = null
        }

        saveTicketCallback(payload)
        break
      case 'brand':
        saveTicketCallback({ brand_id: payload?.brand?.id || null })
        setTicket((previous: DesignRequest) => {
          return {
            ...previous,
            selectedBrand: payload.brand,
          }
        })
        break

      case 'designer':
        saveTicketCallback({ preferred_designer_id: payload?.preferredDesigner?.id || null })
        setTicket((previous: DesignRequest) => {
          return {
            ...previous,
            preferredDesigner: payload.preferredDesigner,
          }
        })
        break
    }
  }

  useEffect(() => {
    const sizesValid = () => {
      if (customSizes?.length > 0) {
        return customSizes?.every((size) => size.width && size.height)
      }

      return ticket?.selectedSkillSizes?.length > 0
    }

    const newInvalidFields = []

    if (!ticket?.subject || ticket?.subject?.length < 3) {
      newInvalidFields.push('subject')
    }

    if (!ticket?.skill?.id) {
      newInvalidFields.push('skill')
    }

    if (ticket?.selectedFormats?.length === 0) {
      newInvalidFields.push('formats')
    }

    if (!sizesValid()) {
      newInvalidFields.push('sizes')
    }

    setInvalidFields(newInvalidFields)
  }, [customSizes, ticket?.selectedFormats?.length, ticket?.selectedSkillSizes?.length, ticket?.skill, ticket?.subject])

  const context: RequestContextValue = {
    ability,
    canShare,
    customSizes,
    dispatch,
    editMode,
    fetchAndSetTicket: fetchTicket,
    invalidFields,
    possibleDesigners,
    setShowValidationErrors,
    setTicket,
    showRevisions,
    showValidationErrors,
    skillSizes,
    ticket,
    ticketError,
    ticketId,
  }

  return <RequestContext.Provider value={context}>{children}</RequestContext.Provider>
}
