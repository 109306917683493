import { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-common-types'

interface QueueTypePlaceholderProps {
  fileName: keyof typeof placeholders
  className?: string
  color?: string
}

const placeholders = {
  fileImageLight: 'file-image',
  filePowerpointLight: 'file-powerpoint',
  fileVideoLight: 'file-video',
}

export default function QueueTypePlaceholder({ fileName, className, color }: QueueTypePlaceholderProps): ReactElement {
  const iconName = (placeholders[fileName] || 'file-image') as IconName
  return <FontAwesomeIcon icon={['fal', iconName]} color={color} className={className} />
}
