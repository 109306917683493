import Button from 'components/core/button'
import { useCallback, useEffect, useState } from 'react'
import { isValidHexColor } from 'lib/util/color'
import { useToastContext } from 'providers/toast-provider'
import { BrandPayload, createBrand, updateBrand } from 'lib/api/custom-branding/custom-branding'
import { CustomBrandingUrlSettings } from './custom-branding-url-settings'
import { CustomBrandingColorSettings } from './custom-branding-color-settings'
import { CustomBrandingLogoSettings } from './custom-branding-logo-settings'
import { SquareArrowOutUpRight } from 'lucide-react'
import { CustomBrandingPageProps } from './custom-branding-page'

export interface FormState {
  logo?: {
    value: string
    error: string[]
  }
  primary_color?: {
    value: string
    error: string[]
  }
  url?: {
    value: string
    error: string[]
  }
}

export interface UpdateFormState {
  (key: string, value: string, error?: string | null): void
}

export default function CustomBranding({
  settings,
  company,
  customBranding,
  customBrandingStatus,
  customBrandingPlanId,
  hasWhiteLabelSubscription,
  canSubscribeToWhiteLabel,
  isPowerCompany,
}: CustomBrandingPageProps) {
  const { alert, notice } = useToastContext()
  const [localCustomBranding, setLocalCustomBranding] = useState(customBranding)

  const getInitialState = useCallback(
    () => ({
      logo: {
        value: localCustomBranding?.logo ?? null,
        error: null,
      },
      primary_color: {
        value: isValidHexColor(localCustomBranding?.primaryColor) ? localCustomBranding?.primaryColor : '#576EE7',
        error: null,
      },
      url: {
        value: localCustomBranding?.url ?? '',
        error: null,
      },
    }),
    [localCustomBranding]
  )
  const [formState, setFormState] = useState(getInitialState())
  const resetState = useCallback(() => {
    setFormState(getInitialState())
  }, [getInitialState])
  const updateFormState = useCallback((key: string, value: string, error: string = null) => {
    setFormState((prevState) => ({ ...prevState, [key]: { value, error } }))
  }, [])

  const [hasChanges, setHasChanges] = useState(false)

  useEffect(() => {
    const initialState = getInitialState()
    setHasChanges(Object.keys(formState).some((key) => formState[key].value !== initialState[key].value))
  }, [formState, getInitialState])

  useEffect(() => {
    resetState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCustomBranding])

  async function handleSave() {
    const payload = Object.entries(formState).reduce((acc, [key, { value }]) => {
      acc[key] = value
      return acc
    }, {}) as BrandPayload

    try {
      let response
      if (localCustomBranding?.id) {
        response = await updateBrand(localCustomBranding.id, payload)
      } else {
        response = await createBrand(payload)
      }

      setLocalCustomBranding(response.data)

      notice('White Label Settings Saved')
    } catch (error) {
      if (error.response?.data?.errors) {
        const { errors } = error.response.data
        Object.keys(errors).forEach((key) => {
          if (key in formState) {
            const errorMessage = errors[key]
              .map((err) => `${key.charAt(0).toUpperCase() + key.slice(1)} ${err}`)
              .join('. ')
              .concat('.')
            updateFormState(key, formState[key].value, errorMessage)
          }
        })
      } else {
        alert('Something went wrong, please try again.')
      }
    }
  }

  function WhiteLabelPromotionContent() {
    if (isPowerCompany) {
      return (
        <p className="m-0">
          Please contact your Account Executive or{' '}
          <a href="mailto:help@designpickle.com" className="tw-font-bold">
            help@designpickle.com
          </a>{' '}
          for support in managing your plans.
        </p>
      )
    }
    if (canSubscribeToWhiteLabel) {
      return (
        <Button
          color="purple"
          data-title="Subscribe to White Label"
          data-toggle="ajax-modal"
          // eslint-disable-next-line react/prop-types
          data-url={`/pickle_subscriptions/new?company_id=${company?.id}&pickle_plan_id=${customBrandingPlanId}`}
          className="ajax-modal-link tw-self-start"
        >
          Activate white label
        </Button>
      )
    }
    return (
      <p className="m-0">
        Please reach out to your account administrator to enable this add-on. For questions, contact{' '}
        <a href="mailto:help@designpickle.com" className="tw-font-bold">
          help@designpickle.com
        </a>
      </p>
    )
  }

  return (
    <>
      {!hasWhiteLabelSubscription && (
        <div className="tw-mt-4 tw-p-6 tw-flex tw-flex-row tw-gap-4 tw-shadow tw-bg-cornflower-50 ">
          <img className="tw-w-48" src="/images/white_labeling/whiteLabel_banner.png" />
          <div className="tw-flex tw-flex-col tw-gap-3">
            <h4 className="tw-p-0 tw-m-0">White-label our Share & Review tool </h4>
            <p className="tw-p-0 tw-m-0 tw-text-base tw-text-peppercorn-900">
              Add your own logo, brand color, and custom domain to our Share & Review tool so your customers see your
              brand instead of ours! Read more on our{' '}
              <a
                href="https://help.designpickle.com/en/articles/9487688-share-review-how-to-use-white-label"
                target="_blank"
                rel="noreferrer"
                className="tw-inline-flex tw-items-center tw-gap-1 tw-font-bold tw-text-cornflower-500"
              >
                knowledge base
                <SquareArrowOutUpRight className="lu-sm" />
              </a>
            </p>
            <WhiteLabelPromotionContent />
          </div>
        </div>
      )}
      <div className={!hasWhiteLabelSubscription ? 'tw-pointer-events-none tw-opacity-50 tw-saturate-0 tw-filter' : ''}>
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-4 tw-py-6">
          <div className="tw-flex tw-flex-row tw-gap-4 tw-items-end">
            <h3 className="tw-my-0">White Label</h3>
            <span className="tw-font-medium">
              Learn more on our{' '}
              <a
                href="https://help.designpickle.com/en/articles/9487688-share-review-how-to-use-white-label"
                target="_blank"
                rel="noreferrer"
              >
                knowledge base <SquareArrowOutUpRight className="lu-sm" />
              </a>
            </span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end">
            <div className="tw-flex tw-flex-row tw-gap-2">
              {hasChanges && (
                <Button
                  color="lightGray"
                  onClick={() => {
                    resetState()
                  }}
                >
                  Discard Changes
                </Button>
              )}
              <Button color="purple" onClick={async () => await handleSave()} disabled={!hasChanges}>
                Save
              </Button>
            </div>
            <div className="tw-text-right tw-text-flushpink-500">
              {Object.entries(formState).map(([key, { error }]) => error && <div key={key + error}>{error}</div>)}
            </div>
          </div>
        </div>
        <div className="tw-bg-white tw-shadow-sm tw-rounded tw-p-6">
          <div className="tw-flex tw-flex-col tw-gap-12">
            <CustomBrandingUrlSettings
              formState={formState}
              updateFormState={updateFormState}
              customBranding={localCustomBranding}
              customBrandingStatus={customBrandingStatus}
            />
            <div className="tw-flex tw-flex-col tw-gap-4 tw-items-baseline">
              <div>
                <h4>Add your brand color and logo</h4>
                <p className="tw-mb-0">
                  Upload your logo and your brand color to customize all Share & Review pages you send to your clients.
                </p>
              </div>
              <div className="tw-flex tw-flex-row tw-gap-8 tw-items-start  tw-w-full">
                <div className="tw-flex tw-flex-col tw-w-40 tw-items-start tw-flex-1 tw-gap-4">
                  <CustomBrandingColorSettings formState={formState} updateFormState={updateFormState} />
                  <CustomBrandingLogoSettings
                    formState={formState}
                    updateFormState={updateFormState}
                    apiKey={settings.filestackApiKey}
                  />
                </div>
                <div className="tw-flex-1">
                  <label>Where your color and logo will go</label>
                  <div className="tw-p-4 tw-w-full tw-rounded-md tw-flex tw-flex-col tw-items-center tw-gap-4 tw-border tw-border-solid tw-border-gray-300">
                    <img className="tw-max-w-lg" src="/images/white_labeling/whiteLabel_authPreview.png" />
                    <img className="tw-max-w-lg" src="/images/white_labeling/whiteLabel_appPreview.png" />
                  </div>
                  <p className="tw-block tw-mt-2">
                    Your brand color and logo will be used to update the login page, main review page and elsewhere in
                    the share & review experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
