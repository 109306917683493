import Dropdown from 'lib/components/dropdown/dropdown'
import { useMemo } from 'react'
import Brands from '../brands/brands'
import { Brand } from 'lib/api/companies/companies'
import BrandsProvider from '../brands/brands-provider'

interface RequestBrandsPopoverProps {
  onChange: (brand: Brand) => void
  ticketBrand: Brand
  companyId: number
}

export default function RequestBrandsPopover({
  onChange,
  ticketBrand,
  companyId,
}: RequestBrandsPopoverProps): JSX.Element {
  const title = useMemo(() => {
    if (ticketBrand) {
      return ticketBrand.name
    }

    return 'Select Brand'
  }, [ticketBrand])

  return (
    <BrandsProvider>
      <Dropdown label="Brand Profile" title={title} size="sm">
        <Brands onSelect={onChange} companyId={companyId} ticketBrand={ticketBrand} />
      </Dropdown>
    </BrandsProvider>
  )
}
