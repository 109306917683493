import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { PenLine } from 'lucide-react'

import { displayDate } from 'lib/util/date'
import { AnnotationRecord, AnnotationStatus } from 'lib/api/annotations/annotations'
import IconButton from 'lib/components/buttons/icon-button'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import CopyTextButton from 'lib/components/buttons/copy-text-button'

import { useAnnotationsContext } from '../providers/annotations-provider'
import { useMediaContext } from '../media/media-provider'
import ApproveRejectButtons from './approve-reject-buttons'
import AnnotationStatusBadge from './annotation-status-badge'
import { DetailTaskType } from '../types/detail-task'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'

interface AnnotationDetailsProps {
  annotation: AnnotationRecord<AnnotoriousAnnotation>
  enableEditMode: () => void
  onDeleteClick: (annotation: AnnotationRecord<AnnotoriousAnnotation>) => void
}

const classNames = {
  container: `tw-flex tw-items-center tw-justify-between tw-py-2 tw-border-solid tw-border-2 tw-rounded`,
  editableDescription: 'tw-cursor-text',
  itemContainer: `
    tw-flex
    tw-divide-solid
    tw-divide-y-0
    tw-divide-x
    tw-divide-peppercorn-100
  `,
  itemLeftContainer: `
    tw-m-auto
  `,
  itemIconContainer: `
    tw-pl-0.5
    tw-mx-2
  `,
  pageNumberContainer: `
    tw-flex-col
    tw-text-cornflower-500
    tw-text-base
    tw-pt-1
  `,
  pageNumberItem: `
    tw-text-center
  `,
  itemIcon: `
    lu-light
    lu-md
    tw-text-cornflower-500
  `,
  itemDescriptionContainer: `
    tw-px-2
    tw-py-4
    tw-text-neutral-800
  `,
  itemUserContainer: `
    tw-text-sm
    tw-text-neutral-500
  `,
  tooltip: `
    tw-absolute
    tw-z-50
    tw-whitespace-nowrap
    tw-right-2
    tw--top-16
    tw-text-white
    tw-px-3
    tw-py-1.5
    tw-text-left
    tw-text-sm
    tw-rounded
    tw-bg-neutral-500
  `,
}

export default function AnnotationDetails({ annotation, enableEditMode, onDeleteClick }: AnnotationDetailsProps) {
  const [showCopyTooltip, setShowCopyTooltip] = useState(false)
  const [showAnnotationBackground, setShowAnnotationBackground] = useState(false)
  const [selectAnnotationAfterChangingPage, setSelectAnnotationAfterChangingPage] = useState(false)

  const timeout = useRef(null)
  const selectAnnotationTimeout = useRef(null)
  const scrollToDiv = useRef<HTMLDivElement>()

  const {
    canEditAnnotation,
    isCollaboratorView,
    highlightedAnnotation,
    selectAnnotation,
    setHighlightedAnnotation,
    showAnnotationCreator,
  } = useAnnotationsContext()

  const { getFilePageNumber, selectFileById } = useMediaContext()

  const allowEditAndHighlight =
    showAnnotationCreator !== DetailTaskType.IMAGE_ANNOTATION &&
    showAnnotationCreator !== DetailTaskType.VIDEO_ANNOTATION

  const annotationMeta = useMemo(() => {
    try {
      return {
        createdAtDate: displayDate(annotation.createdAt),
        creatorName: annotation.createdBy,
      }
    } catch {
      return {
        createdAtDate: 'n/a',
        creatorName: 'n/a',
      }
    }
  }, [annotation.createdAt, annotation.createdBy])

  const annotationTime = useMemo(() => {
    if (Object.hasOwn(annotation, 'time')) {
      return formatAnnotationTime(annotation.time)
    }
    return null
  }, [annotation])

  const isAnnotationHighlighted = useMemo(() => {
    if (highlightedAnnotation?.id) {
      return highlightedAnnotation?.id === annotation.id
    }

    if (highlightedAnnotation?.uuid) {
      return highlightedAnnotation?.uuid === annotation.uuid
    }
    return false
  }, [annotation.id, annotation.uuid, highlightedAnnotation?.id, highlightedAnnotation?.uuid])

  const pageNumber = useMemo(() => {
    if (annotation.fileParentId) {
      return getFilePageNumber(annotation.fileParentId, annotation.assetId)
    }
    return null
  }, [annotation?.assetId, annotation?.fileParentId, getFilePageNumber])

  async function handleCopyClick() {
    await navigator.clipboard.writeText(annotation.body)
    showTooltip()
  }

  function handleDeleteClick() {
    onDeleteClick(annotation)
  }

  function handleOnClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation()

    selectFileById(annotation.assetId, annotation.fileParentId)

    if (allowEditAndHighlight) {
      setHighlightedAnnotation(annotation)
      selectAnnotation(annotation)
    }
  }

  function onMouseEnterHandler(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    setShowAnnotationBackground(true)
    if (allowEditAndHighlight) {
      selectAnnotation(annotation)
    }
  }

  function onMouseLeaveHandler(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation()

    setShowAnnotationBackground(false)

    if (allowEditAndHighlight) {
      selectAnnotation(highlightedAnnotation || null)
    }
  }

  function showTooltip() {
    setShowCopyTooltip(true)

    timeout.current = setTimeout(() => {
      setShowCopyTooltip(false)
    }, 3000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
      clearTimeout(selectAnnotationTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (isAnnotationHighlighted && scrollToDiv.current) {
      scrollToDiv.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isAnnotationHighlighted])

  useEffect(() => {
    if (selectAnnotationAfterChangingPage) {
      selectAnnotationTimeout.current = setTimeout(() => {
        selectAnnotation(annotation)
        setSelectAnnotationAfterChangingPage(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAnnotationAfterChangingPage])

  const highlightedAnnotationBorder =
    highlightedAnnotation && isAnnotationHighlighted ? 'tw-border-cornflower-500' : 'tw-border-transparent'

  const highlightedAnnotationBackground =
    (allowEditAndHighlight && showAnnotationBackground) || isAnnotationHighlighted ? 'tw-bg-cornflower-50' : ''

  return (
    <div
      className={`${classNames.container} ${highlightedAnnotationBorder} ${highlightedAnnotationBackground}`}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      data-testid="annotation-details"
      onClick={handleOnClick}
      ref={scrollToDiv}
    >
      <div
        className={`${classNames.itemContainer} ${
          showAnnotationCreator === DetailTaskType.VIDEO_ANNOTATION ? '' : 'tw-cursor-pointer'
        }`}
      >
        <div className={classNames.itemLeftContainer}>
          <div className={classNames.itemIconContainer}>
            <PenLine className={classNames.itemIcon} />
          </div>
          {pageNumber !== null && (
            <div className={classNames.pageNumberContainer}>
              <div className={classNames.pageNumberItem}>pg.</div>
              <div className={classNames.pageNumberItem}>{pageNumber}</div>
            </div>
          )}
          {annotationTime !== null && (
            <div className={classNames.pageNumberContainer}>
              <div className={classNames.pageNumberItem}>{annotationTime}</div>
            </div>
          )}
        </div>
        <div className={classNames.itemDescriptionContainer}>
          <AnnotationStatusBadge status={annotation.status} />
          <div className={canEditAnnotation(annotation) ? classNames.editableDescription : ''} onClick={enableEditMode}>
            {annotation.body}
          </div>
          <div className={classNames.itemUserContainer}>
            Added by {annotationMeta.creatorName} {annotationMeta.createdAtDate}
          </div>
          {!isCollaboratorView && annotation.status === AnnotationStatus.Review && (
            <ApproveRejectButtons annotation={annotation} />
          )}
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-pr-4">
        {canEditAnnotation(annotation) ? (
          <>
            <IconButton color="secondary" icon={['fal', 'pen']} onClick={enableEditMode} />
            <IconButton color="danger" icon={['far', 'trash']} onClick={handleDeleteClick} invert />
            <IconFlyoutMenu
              color={showCopyTooltip ? 'success' : 'transparent'}
              icon={showCopyTooltip ? ['far', 'check'] : ['far', 'ellipsis-v']}
              adjustedLeft
              viewportBottomThreshold={230}
            >
              <IconFlyoutMenu.Button onClick={handleCopyClick}>Copy</IconFlyoutMenu.Button>
            </IconFlyoutMenu>
            <div className="tw-relative">
              {showCopyTooltip && <div className={classNames.tooltip}>Annotation Copied!</div>}
            </div>
          </>
        ) : (
          <CopyTextButton textToCopy={annotation.body} />
        )}
      </div>
    </div>
  )
}

function formatAnnotationTime(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = Math.floor(totalSeconds % 60)
  const paddedSeconds = seconds.toString().padStart(2, '0')
  return `${minutes}:${paddedSeconds}`
}
