import { ReactElement, useEffect, useRef } from 'react'

import { filestackCdnUrl } from 'lib/util/filestack'
import { AdminTicketFile } from 'lib/api/admin/ticket-files/admin-ticket-files'
import VideoPlayer from 'lib/components/video/video-player'
import { VideoJSAnnotationObject } from 'lib/api/ticket-files/ticket-files'
import { useAdminUserContext } from 'providers/admin-user-provider'

interface VideoJsPlayerProps {
  ticketFile: AdminTicketFile
}

export default function AdminVideoJsPlayer({ ticketFile }: VideoJsPlayerProps): ReactElement {
  const videoRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef(null)
  const { user } = useAdminUserContext()

  // TODO: Add creating, updating, and deleting annotations with SU-7280
  // See app/javascript/components/pages/request/media/video-js-player.tsx
  // and app/javascript/components/pages/request/providers/annotations-provider.tsx for reference
  function onSelectVideoAnnotation() {
    return
  }

  function createVideoPlayer(
    target: HTMLDivElement,
    annotationTicketFile: VideoAnnotationTicketFile,
    cancelCallback: () => void
  ) {
    const ticketFileId = annotationTicketFile.id
    const videoUrl = `${filestackCdnUrl}/${annotationTicketFile.handle}`
    const annotationUser = { id: user.id, fullName: user.fullName }
    const player = new VideoPlayer(
      target,
      ticketFileId,
      videoUrl,
      annotationUser,
      cancelCallback,
      onSelectVideoAnnotation
    )
    const videoAnnotations = annotationTicketFile.annotations.map(
      (annotation) => annotation.data
    ) as VideoJSAnnotationObject[]

    function onReadyCallback() {
      const firstVideoAnnotationCommentId = sortedByTimeVideoAnnotations(videoAnnotations)[0].comments[0].id

      player.select(firstVideoAnnotationCommentId)
    }

    player.seed(videoAnnotations, onReadyCallback)
    return player
  }

  function cancelCallback() {
    return
  }

  useEffect(() => {
    const annotationTicketFile = {
      handle: ticketFile.handle,
      id: ticketFile.id,
      annotations: ticketFile.annotations,
    }

    playerRef.current = createVideoPlayer(videoRef.current, annotationTicketFile, cancelCallback)

    return () => {
      playerRef.current.destroy()
      playerRef.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketFile.id])

  return (
    <div className="tw-h-full tw-relative">
      <div className="tw-h-full tw-relative tw-flex tw-flex-row tw-items-center" ref={videoRef} />
      <video controls className="tw-object-contain tw-w-full tw-h-full">
        <source src={`${filestackCdnUrl}/${ticketFile.handle}`} type="video/mp4" />
      </video>
    </div>
  )
}

type VideoAnnotationTicketFile = Pick<AdminTicketFile, 'id' | 'annotations' | 'handle'>

function sortedByTimeVideoAnnotations(videoAnnotations: VideoJSAnnotationObject[]) {
  return videoAnnotations.sort((a, b) => (a.range.start < b.range.start ? -1 : 1))
}
