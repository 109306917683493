import { ReactElement, useMemo, useState } from 'react'
import { Check, Play, X } from 'lucide-react'
import Button from 'components/core/button'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { AdminQaReviewStates, AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'
import QrDenyModal from './admin-header-qr-buttons-deny-modal'
import AdminHeaderQrApproveModal from './admin-header-qr-approve-modal'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import { useToastContext } from 'providers/toast-provider'

const classNames = {
  button: 'tw-flex tw-items-center tw-gap-2',
}

export default function AdminHeaderQRButtons(): ReactElement {
  const { ticket, startQr } = useAdminTicketContext()
  const [showDenyModal, setShowDenyModal] = useState(false)
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [lastUnsentDraftMessage, setLastUnsentDraftMessage] = useState(null)
  const { getLastDraftMessage } = useAdminTimelineContext()
  const { alert } = useToastContext()

  const ticketPermissions = ticket.meta.permissions
  const qaReviews = ticket.qaReviews
  const qaPermissions = qaReviews ? qaReviews[0]?.meta?.permissions : null

  const canApproveAndReject = useMemo(() => {
    if (qaPermissions) {
      return (
        qaPermissions.pass &&
        qaPermissions.fail &&
        ticket.state === AdminTicketState.qualityReview &&
        qaReviews[0]?.state === AdminQaReviewStates.inProgress
      )
    }

    if (ticketPermissions.selfQualityReview) {
      return ticket.state === AdminTicketState.qualityReview
    }
  }, [qaPermissions, qaReviews, ticket.state, ticketPermissions.selfQualityReview])

  const canStartQr = useMemo(() => {
    return qaPermissions?.start && !canApproveAndReject
  }, [canApproveAndReject, qaPermissions?.start])

  async function handleApproveClick() {
    const response = await getLastDraftMessage()
    setLastUnsentDraftMessage(response)
    setShowApproveModal(true)
  }

  async function handleStart() {
    try {
      await startQr()
    } catch (error) {
      console.error('Error starting QR', error)
      alert('There was an error starting QR, try again')
    }
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-4">
      {canStartQr && (
        <Button
          className={classNames.button}
          type="button"
          onClick={async () => {
            await handleStart()
          }}
          color="purple"
        >
          <Play className="lu-light lu-md" /> Start QR
        </Button>
      )}

      {canApproveAndReject && (
        <>
          <Button
            className={classNames.button}
            type="button"
            onClick={() => {
              setShowDenyModal(true)
            }}
            color="red"
          >
            <X className="lu-light lu-md" /> Send back for revision
          </Button>
          <QrDenyModal open={showDenyModal} setOpen={setShowDenyModal} />
          <AdminHeaderQrApproveModal
            open={showApproveModal}
            setOpen={setShowApproveModal}
            lastUnsentDraftMessage={lastUnsentDraftMessage}
          />
          <Button className={classNames.button} type="button" color="purple" onClick={handleApproveClick}>
            <Check className="lu-light lu-md" />
            Approve and deliver to client
          </Button>
        </>
      )}
    </div>
  )
}
