import { MouseEvent, ReactElement, useMemo, useState } from 'react'
import { AdminTicketFile } from 'lib/api/admin/ticket-files/admin-ticket-files'
import IconButton from 'lib/components/buttons/icon-button'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { Trash, X } from 'lucide-react'
import { useAdminMediaContext } from '../providers/admin-media-provider'
import { useToastContext } from 'providers/toast-provider'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'

interface AdminDeleteMediaButtonProps {
  file: AdminTicketFile
  icon: 'trash' | 'times'
}

export default function AdminDeleteMediaButton({ file, icon }: AdminDeleteMediaButtonProps): ReactElement {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { deleteFile } = useAdminMediaContext()
  const { ticket } = useAdminTicketContext()
  const { notice } = useToastContext()

  const removeModalMessage = useMemo(() => {
    return `Are you sure you want to delete ${file.name}?`
  }, [file.name])

  function handleRemoveClick(e: MouseEvent) {
    e.stopPropagation()
    setIsModalVisible(true)
  }

  function handleCancelClick(e: MouseEvent) {
    e.stopPropagation()
    setIsModalVisible(false)
  }

  async function handleDelete(e: MouseEvent) {
    e.stopPropagation()
    deleteFile(file)
    setIsModalVisible(false)
    notice('The file has been deleted')
  }

  const isMediaDeletable = useMemo(() => {
    if (!file.meta?.permissions?.destroy) {
      return false
    }

    const isCurrentVersion = file.ticketVersion === ticket.currentVersion
    const isNotUploadedByCreative = !file.uploadedByCreative

    return isCurrentVersion && !isNotUploadedByCreative
  }, [file.meta?.permissions?.destroy, file.ticketVersion, file.uploadedByCreative, ticket.currentVersion])

  if (isMediaDeletable) {
    return (
      <>
        <IconButton
          color={icon === 'times' ? 'secondary' : 'transparent'}
          onClick={handleRemoveClick}
          size="sm"
          dataTestid="delete-file"
        >
          <>
            {icon === 'times' && <X className="lu-light lu-sm" />}
            {icon === 'trash' && <Trash className="lu-light lu-sm tw-text-red-700" />}
          </>
        </IconButton>
        <ConfirmationModal
          title="Delete Media"
          message={<span className="tw-break-all">{removeModalMessage}</span>}
          confirmBtnText="Yes, Delete"
          confirmAction={handleDelete}
          cancelAction={handleCancelClick}
          visible={isModalVisible}
        />
      </>
    )
  }

  return null
}
