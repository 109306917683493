import { ReactElement, useEffect, useState } from 'react'
import { useUserContext } from 'providers/user-provider'
import { Info } from 'lucide-react'
import TicketLinkElement from './table-elements/ticket-link-element'
import { Ticket } from 'interfaces/ticket'
import { getTicket } from 'lib/api/tickets/tickets'
import { TicketFeedbackValues } from 'interfaces/ticket_feedback'
import { ThumbsDownButton, ThumbsUpButton } from 'components/core/thumbs-rating-buttons'
import { LoadingScreen } from './empty-screens'
import { useTicketFeedbackContext } from 'providers/ticket-feedback-provider'

const classNames = {
  container:
    'tw-h-16 tw-w-full tw-fixed tw--ml-9 tw-pl-9 tw-flex tw-justify-left tw-items-center tw-bg-cornflower-50 tw-text-skyblue-800 tw-text-sm tw-font-semibold',
  fakeContainer: 'tw-h-16 tw-w-full',
  infoIcon: 'tw-mx-2 lu-bold lu-sm',
  thumbsIcon: 'tw-mx-2 tw-cursor-pointer hover:tw-fill-current',
  ticketLinkElementWrapper: 'tw-mx-2',
}

export default function TicketRatingReminder(): ReactElement {
  const [ticket, setTicket] = useState<Ticket>(null)
  const { rateTicket, showTicketRatingReminder, isLoading } = useTicketFeedbackContext()

  const { user } = useUserContext()
  const { ticketFeedback } = user

  useEffect(() => {
    async function getTicketRequest() {
      const { ticket: ticketResponse } = await getTicket(ticketFeedback)
      setTicket(ticketResponse as Ticket)
    }

    getTicketRequest().catch(console.error)
  }, [ticketFeedback])

  if (!ticket || !showTicketRatingReminder) return null

  function rate(thumbRating: TicketFeedbackValues) {
    rateTicket({ ticket, thumbRating })
  }

  function ThumbsButtonSection() {
    if (isLoading)
      return (
        <div className="tw-ml-6 tw-w-12">
          <LoadingScreen />
        </div>
      )

    return (
      <>
        <ThumbsUpButton className={classNames.thumbsIcon} onClick={() => rate(TicketFeedbackValues.ThumbsUp)} />
        <ThumbsDownButton className={classNames.thumbsIcon} onClick={() => rate(TicketFeedbackValues.ThumbsDown)} />
      </>
    )
  }

  return (
    <>
      <div className={classNames.container} style={{ zIndex: '1' }}>
        <Info className={classNames.infoIcon} />
        <span>{'Did your last request'}</span>
        <div className={classNames.ticketLinkElementWrapper}>
          <TicketLinkElement ticket={ticket} />
        </div>
        <span>{'meet your expectations?'}</span>
        <ThumbsButtonSection />
      </div>
      <div className={classNames.fakeContainer} />
    </>
  )
}
