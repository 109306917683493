import { ReactElement } from 'react'

import { AppSettings, CurrentUser } from 'interfaces/app'

import UserProvider from 'providers/user-provider'
import ToastProvider from 'providers/toast-provider'
import PageHeader from 'components/elements/page-header'
import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'
import TicketList from './ticket-list'
import Announcements from 'lib/components/announcements/announcements'
import TicketRatingReminder from './ticket-rating-reminder'
import SingleQueueProvider from 'providers/single-queue-provider'
import TicketFeedbackProvider from 'providers/ticket-feedback-provider'

const REQUESTS_CSV_URL = '/requests.csv'

interface SingleQueueProps {
  settings: AppSettings
  user: CurrentUser
}

export default function SingleQueue({ settings, user }: SingleQueueProps): ReactElement {
  return (
    <>
      <ToastProvider>
        <UserProvider settings={settings} user={user}>
          <SingleQueueProvider userId={user.id}>
            <TicketFeedbackProvider>
              {user.ticketFeedback && <TicketRatingReminder />}
              <PageHeader title=" Requests " icon={['fal', 'ticket']}>
                <ExternalActionLinkItem
                  label="Export as CSV"
                  iconClass="far fa-regular fa-file-export"
                  route={REQUESTS_CSV_URL}
                />
              </PageHeader>
              <Announcements />
              <TicketList />
            </TicketFeedbackProvider>
          </SingleQueueProvider>
        </UserProvider>
      </ToastProvider>
    </>
  )
}
