import { getApi } from '../api'
import { camelCaseKeys } from '../../object/utils'

export type Ability = {
  actions: string[]
  associations: unknown
  conditions: unknown
  subjects: string[]
  type: 'can' | 'cannot'
}

type AbilityAction = 'create' | 'manage' | 'read' | 'update' | 'destroy' | 'sort' | 'mark_complete'

type Abilities = Ability[]

interface AbilitiesResponse extends Abilities {
  message?: string
}

export interface AbilityResponse {
  cancel: boolean
  destroy: boolean
  duplicate: boolean
  edit: boolean
  index: boolean
  markArchived?: boolean
  markComplete?: boolean
  moveToTop: boolean
  new: boolean
  read: boolean
  reply: boolean
  show: boolean
  sort: boolean
  update: boolean
}

const baseUrl = '/api/internal/abilities'
export const fetchAbilities = async (subjects: string[]): Promise<AbilitiesResponse> => {
  const { data } = await getApi(baseUrl, { subjects })
  return data as AbilitiesResponse
}

export const fetchAbility = async (subject: string, subjectId: number): Promise<AbilityResponse> => {
  const response = await getApi(`${baseUrl}/${subjectId}`, { resource: subject })
  return camelCaseKeys(response.data.data) as unknown as AbilityResponse
}

const getSubjectAbility = (subject: string, abilities: Ability[]): Ability[] => {
  return abilities.filter((ability) => ability.subjects.includes(subject))
}

const hasAction = (actions: string[], action: string): boolean => actions.includes(action) || actions.includes('manage')

export const canI = (subject: string, action: AbilityAction, abilities: Ability[]): boolean => {
  const subjectAbilities = getSubjectAbility(subject, abilities)
  const allowedAction = subjectAbilities.some((ability) => ability.type === 'can' && hasAction(ability.actions, action))
  const deniedAction = subjectAbilities.every(
    (ability) => ability.type === 'cannot' && !hasAction(ability.actions, action)
  )
  return allowedAction && !deniedAction
}

export const cantI = (subject: string, action: AbilityAction, abilities: Ability[]): boolean => {
  const subjectAbilities = getSubjectAbility(subject, abilities)
  return subjectAbilities.some((ability) => ability.type === 'cannot' && hasAction(ability.actions, action))
}

export const getConditions = (subject: string, action: string, abilities: Ability[]): unknown => {
  const subjectAbilities = getSubjectAbility(subject, abilities)
  const abilitiesWithConditions = subjectAbilities.filter(
    (ability) => ability.actions.includes(action) && Object.keys(ability.conditions).length > 0
  )
  return abilitiesWithConditions.map(({ conditions }) => conditions)
}

export const validateIfUserCanAssignDpu = (abilities: Ability[]): boolean => {
  return canI('Dpu', 'update', abilities)
}
