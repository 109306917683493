import { ReactElement, useMemo, useRef } from 'react'
import { Pencil, Trash } from 'lucide-react'
import Avatar from 'components/core/avatar'
import { ConversationTimelineItem } from 'lib/api/timeline/timeline'
import Badge from 'lib/components/badge/badge'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { displayDate } from 'lib/util/date'
import hyperlink from 'lib/util/hyperlink'
import './timeline-conversation-details.scss'

interface TimelineConversationDetailsProps {
  conversation: ConversationTimelineItem
  editable?: boolean
  enableEditMode: () => void
  onDelete?: () => void
}

const classNames = {
  article: 'tw-mb-4 tw-bg-neutral-50 tw-text-neutral-800 tw-rounded-lg tw-p-4',
  body: 'tw-py-4 tw-whitespace-pre-wrap',
  header: {
    avatar: 'tw-shrink',
    byline: 'tw-flex-grow tw-text-left tw-flex',
    container: 'tw-flex tw-direction-row tw-justify-between tw-items-center tw-p-1 tw-gap-2',
  },
}

export default function TimelineConversationDetails({
  conversation,
  editable = false,
  enableEditMode,
  onDelete,
}: TimelineConversationDetailsProps): ReactElement {
  const bodyRef = useRef<HTMLDivElement>()

  const body = useMemo(() => hyperlink(conversation.body), [conversation.body])
  const createdAt = useMemo(() => displayDate(conversation.createdAt), [conversation.createdAt])

  function handleDeleteClick() {
    onDelete()
  }

  function handleEditClick() {
    enableEditMode()
  }

  return (
    <article className={classNames.article}>
      <header className={classNames.header.container}>
        <div className={classNames.header.avatar}>
          <Avatar src={conversation.user.avatar} name={conversation.user.fullName} />
        </div>
        <div className={classNames.header.byline}>
          <div>{conversation.user.fullName}</div>
          <Badge style="pill" color="light">
            {createdAt}
          </Badge>
        </div>
        {editable && (
          <IconFlyoutMenu adjustedLeft color="transparent" icon={['far', 'ellipsis-v']} viewportBottomThreshold={230}>
            <IconFlyoutMenu.Button onClick={handleEditClick}>
              <div className="tw-flex tw-flex-row tw-items-center">
                <Pencil className="lu-sm tw-mr-1 tw-font-semibold" />
                Edit
              </div>
            </IconFlyoutMenu.Button>
            <IconFlyoutMenu.Button onClick={handleDeleteClick}>
              <div className="tw-flex tw-flex-row tw-items-center">
                <Trash className="lu-sm tw-mr-1 tw-text-red-700 tw-font-semibold" />
                <span className="tw-text-red-700">Delete</span>
              </div>
            </IconFlyoutMenu.Button>
          </IconFlyoutMenu>
        )}
      </header>
      <div ref={bodyRef} className={classNames.body}>
        <div className="comment" dangerouslySetInnerHTML={{ __html: body }}></div>
      </div>
    </article>
  )
}
