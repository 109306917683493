import { ReactElement, useEffect, useRef } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import { Underline } from '@tiptap/extension-underline'
import { TextAlign } from '@tiptap/extension-text-align'
import { Image as ImageExtension } from '@tiptap/extension-image'
import { Placeholder } from '@tiptap/extension-placeholder'
import debounce from 'lib/util/debounce'

import './wysiwyg.scss'
import WYSIWYGToolbar from './wysiwyg-toolbar'

const extensions = [
  StarterKit,
  Link.configure({
    autolink: true,
    openOnClick: true,
  }),
  Underline,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  ImageExtension.configure({
    inline: true,
  }),
]

const editorProps = {
  attributes: {
    class: 'tw-p-4 focus:tw-outline-none',
  },
}

interface WYSIWYGTextareaProps {
  defaultValue: string
  onChange: (value: string) => void
  placeholder?: string
}

export default function WYSIWYGTextarea({ defaultValue, onChange, placeholder }: WYSIWYGTextareaProps): ReactElement {
  const content = useRef(defaultValue)

  if (placeholder) {
    extensions.push(Placeholder.configure({ placeholder }) as never)
  }

  const editor = useEditor({
    extensions,
    content: content.current,
    editorProps,
  })

  useEffect(() => {
    if (editor) {
      const debouncedOnChange = debounce(() => onChange(editor.getHTML()), 300)
      editor.on('update', debouncedOnChange)
      return () => {
        editor.off('update', debouncedOnChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <div className="tw-border-solid tw-rounded-md tw-border-1 tw-border-neutral-300 tw-bg-white">
      <WYSIWYGToolbar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  )
}
