import { MouseEvent, ReactNode, useEffect, useState, JSX } from 'react'

export type ButtonColor =
  | 'cherry'
  | 'green'
  | 'lightGray'
  | 'neutralGray'
  | 'outlineDark'
  | 'purple'
  | 'lightPurple'
  | 'red'
  | 'transparent'
  | 'yellow'

type ButtonRole = 'primary' | 'secondary' | 'destructive'

export type ButtonSize = 'normal' | 'cut'

export interface ButtonProps {
  children?: ReactNode
  className?: string
  color?: ButtonColor
  disabled?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>
  rounded?: boolean
  size?: 'normal' | 'cut'
  type?: 'button' | 'submit' | 'reset'
}

interface LinkLikeButtonProps {
  children?: ReactNode
  color?: ButtonRole
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

const classNames = {
  colors: {
    green: `
      tw-bg-gherkin
      tw-text-peppercorn-50
      hover:tw-bg-gherkin-500
      hover:tw-text-white
      disabled:tw-bg-gherkin
      disabled:tw-text-peppercorn-50
      `,
    outlineDark: `
      tw-bg-transparent
      tw-text-peppercorn-800
      tw-border-peppercorn-800
      hover:tw-text-white
      hover:tw-bg-peppercorn-800
      disabled:tw-bg-transparent
      disabled:tw-text-peppercorn-800
      `,
    lightGray: `
      tw-bg-white
      tw-text-peppercorn-700
      tw-border-peppercorn-200
      hover:tw-bg-peppercorn-50
      disabled:tw-bg-white
      `,
    neutralGray: `
      tw-bg-white
      tw-text-neutral-900
      tw-border-neutral-300
      hover:tw-bg-neutral-100
      disabled:tw-bg-white
      `,
    cherry: `
      tw-bg-cherry-100
      tw-text-cherry-500
      tw-border-cherry-100
      hover:tw-bg-cherry-50
      disabled:tw-bg-cherry-100
      `,
    purple: `
      tw-bg-cornflower-500
      tw-text-white
      hover:tw-bg-cornflower-600
      disabled:tw-bg-cornflower-500
      `,
    lightPurple: `
      tw-bg-cornflower-100
      tw-text-neutral-800
      hover:tw-bg-cornflower-200
      disabled:tw-bg-cornflower-50
      `,
    transparent: `
      tw-bg-transparent
      tw-text-neutral-500
      `,
    red: `
      tw-bg-red-500
      tw-text-white
      hover:tw-bg-red-400
    `,
    yellow: `
      tw-bg-sunnyyellow-600
      tw-text-white
      hover:tw-bg-yellow-400
    `,
  },
  common: 'tw-text-center tw-font-normal tw-border tw-border-transparent tw-border-solid disabled:tw-opacity-60',
  corners: {
    normal: 'tw-rounded-md',
    rounded: 'tw-rounded-3xl',
  },
  roles: {
    primary: 'tw-text-cornflower-500 hover:tw-text-cornflower-800',
    secondary: 'tw-text-neutral-800 hover:tw-text-neutral-600',
    destructive: 'tw-text-flushpink-500 hover:tw-text-flushpink-700',
  },
  sizes: {
    normal: 'tw-py-1.5 tw-px-3.5 tw-text-base',
    cut: 'tw-py-2 tw-px-3.5 tw-text-xs',
  },
}

export default function Button({
  children,
  className,
  color = 'green',
  disabled,
  onClick,
  rounded = false,
  size = 'normal',
  type = 'button',
  ...otherProps
}: ButtonProps): JSX.Element {
  const [isMounted, setIsMounted] = useState<boolean>(true)
  const [isWaitingForClickResponse, setIsWaitingForClickResponse] = useState<boolean>(false)

  const classes = getButtonClassNames(size, rounded, color, className, disabled)

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (!isWaitingForClickResponse) {
      const response = onClick?.(event)
      if (response instanceof Promise) {
        setIsWaitingForClickResponse(true)
        response.then(
          () => isMounted && setIsWaitingForClickResponse(false),
          () => isMounted && setIsWaitingForClickResponse(false)
        )
      }
    }
  }

  useEffect(
    () => () => {
      setIsMounted(false)
    },
    []
  )

  return (
    <button
      className={classes}
      onClick={handleClick}
      disabled={disabled || isWaitingForClickResponse}
      type={type}
      {...otherProps}
    >
      {children}
    </button>
  )
}

export function getButtonClassNames(
  size: ButtonSize,
  rounded: boolean,
  color: ButtonColor,
  additionalClassNames: string = null,
  disabled = false
): string {
  const classes = [
    classNames.common,
    classNames.sizes[size],
    rounded ? classNames.corners.rounded : classNames.corners.normal,
    classNames.colors[color],
  ]
  if (disabled) {
    classes.push('tw-cursor-not-allowed')
  } else {
    classes.push('tw-cursor-pointer')
  }
  if (additionalClassNames) {
    classes.push(additionalClassNames)
  }
  return classes.join(' ')
}

interface LinkButtonProps {
  children: ReactNode
  className?: string
  color?: ButtonColor
  rounded?: boolean
  size?: ButtonSize
  target?: '_self' | '_blank'
  url: string
}
export function LinkButton({
  children,
  className,
  color = 'green',
  rounded = false,
  size = 'normal',
  target = '_self',
  url,
}: LinkButtonProps): JSX.Element {
  const classes = getButtonClassNames(size, rounded, color, className)

  return (
    <a className={classes} href={url} target={target}>
      {children}
    </a>
  )
}

export function LinkLikeButton({ children, color = 'primary', onClick }: LinkLikeButtonProps): JSX.Element {
  return (
    <button
      className={`tw-border-none tw-bg-transparent tw-font-semibold tw-p-0 tw-cursor-pointer ${classNames.roles[color]}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
