import { ReactElement, useState } from 'react'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import TimelineDirectionDetails from './timeline-direction-details'
import TimelineDirectionEdit from './timeline-direction-edit'
import { DetailTaskTimelineItem } from 'lib/api/timeline/timeline'

interface TimelineDirectionProps {
  direction: DetailTaskTimelineItem
  onDelete: (direction: DetailTaskTimelineItem) => void
}

export default function TimelineDirection({ direction, onDelete }: TimelineDirectionProps): ReactElement {
  const [editMode, setEditMode] = useState(false)

  const { canEditDirection, addDirectionToInEditMode, removeDirectionFromInEditMode } = useDirectionsContext()

  function disableEditMode() {
    setEditMode(false)
    removeDirectionFromInEditMode(direction)
  }

  function enableEditMode() {
    if (canEditDirection(direction)) {
      setEditMode(true)
      addDirectionToInEditMode(direction.id)
    }
  }

  if (editMode) {
    return <TimelineDirectionEdit direction={direction} disableEditMode={disableEditMode} />
  }
  return <TimelineDirectionDetails direction={direction} enableEditMode={enableEditMode} onDelete={onDelete} />
}
