import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { getSharedTicket, SharedTicket, submitCollaboratorFeedback } from 'lib/api/tickets/tickets'
import { ApiError } from 'lib/api/api'
import { CollaboratorEditMode } from 'lib/api/tickets/tickets'
import { useToastContext } from 'providers/toast-provider'
import { useUserContext } from 'providers/user-provider'
import getCollaboratorEditMode from './collaborator-edit-mode'

function getIdFromUrl(): string {
  const exp = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/
  return window.location.pathname.match(exp)[0]
}

const CollaboratorTicketContext = createContext({})

interface CollaboratorTicketContextValue {
  fetchAndSetTicket: () => Promise<void>
  editMode: CollaboratorEditMode
  submitFeedback: () => Promise<void>
  ticket: SharedTicket
  ticketError: ApiError
}

export function useCollaboratorTicketContext(): CollaboratorTicketContextValue {
  return useContext(CollaboratorTicketContext) as CollaboratorTicketContextValue
}

export default function CollaboratorTicketProvider({ children }: { children: ReactNode }) {
  const [ticket, setTicket] = useState(null)
  const [ticketError, setTicketError] = useState(null)
  const editMode = useMemo(() => getCollaboratorEditMode(ticket?.status), [ticket?.status])

  const { logout, token } = useUserContext()
  const { alert, notice } = useToastContext()

  async function fetchAndSetTicket() {
    try {
      const newTicket = await fetchTicket()
      setTicket(newTicket)
    } catch (err) {
      alert('There was a problem getting updated ticket information.')
    }
  }

  async function fetchTicket() {
    const ticketGuid = getIdFromUrl()
    return await getSharedTicket(ticketGuid, token)
  }

  async function submitFeedback() {
    try {
      await submitCollaboratorFeedback(ticket.id, token)
      const updatedTicket = await fetchTicket()
      setTicket(updatedTicket)
      notice('Success - your feedback has been sent.')
    } catch (axiosError) {
      alert('There was an error submitting your feedback. Please try again.')
      throw axiosError
    }
  }

  useEffect(() => {
    let isAbandoned = false
    fetchTicket()
      .then((ticket) => {
        if (!isAbandoned) {
          setTicket(ticket)
        }
      })
      .catch((err) => {
        if (!isAbandoned) {
          setTicketError(err)
          if (err.status === 401) {
            logout()
          }
        }
      })
    return () => {
      isAbandoned = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const context: CollaboratorTicketContextValue = {
    fetchAndSetTicket,
    submitFeedback,
    ticket,
    ticketError,
    editMode,
  }

  return <CollaboratorTicketContext.Provider value={context}>{children}</CollaboratorTicketContext.Provider>
}
