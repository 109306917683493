import { Fragment, ReactNode, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

interface ConfirmationModalProps {
  afterEnter?: () => void
  afterLeave?: () => void
  cancelAction: (event) => void | Promise<void>
  cancelBtnText?: string
  confirmAction: (event) => void | Promise<void>
  confirmBtnText: string
  message: string | ReactNode
  title: string
  visible: boolean
}

export default function ConfirmationModal({
  afterEnter,
  afterLeave,
  cancelAction,
  cancelBtnText = 'No',
  confirmAction,
  confirmBtnText,
  message,
  title,
  visible,
}: ConfirmationModalProps): JSX.Element {
  const cancelButtonRef = useRef(null)

  function handleAfterEnter() {
    if (afterEnter) {
      afterEnter()
    }
  }

  function handleAfterLeave() {
    if (afterLeave) {
      afterLeave()
    }
  }

  return (
    <Transition.Root show={visible} as={Fragment} afterEnter={handleAfterEnter} afterLeave={handleAfterLeave}>
      <Dialog
        as="div"
        className="tw-fixed tw-z-50 tw-inset-0 tw-overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={cancelAction}
      >
        <div className="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          >
            <div className="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full sm:tw-p-6">
              <div className="sm:tw-flex sm:tw-items-start">
                <div className="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                  <div
                    className="tw-h-6 tw-w-6 tw-text-red-600 tw-items-center tw-flex tw-justify-center"
                    aria-hidden="true"
                  >
                    <i className="tw-mx-auto far fa-exclamation-triangle"></i>
                  </div>
                </div>
                <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
                  <Dialog.Title as="h3" className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="tw-mt-2">
                    <div className="tw-text-sm tw-text-gray-500">{message}</div>
                  </div>
                </div>
              </div>
              <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
                <button
                  type="button"
                  className="tw-cursor-pointer tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-red-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm"
                  onClick={confirmAction}
                >
                  {confirmBtnText}
                </button>
                <button
                  type="button"
                  className="tw-cursor-pointer tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-400 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm"
                  onClick={cancelAction}
                  ref={cancelButtonRef}
                >
                  {cancelBtnText}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
