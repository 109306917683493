import { baseRequest, bindJWT, BaseResponse, deleteApi, patchApi, postApi } from '../api'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { VideoJSAnnotationObject, VideoJSAnnotationPayload } from '../ticket-files/ticket-files'

export interface AnnotationRecord<T> extends UpdateImageAnnotationResponse {
  assetId: number
  createdAt?: string
  createdBy?: string
  data: T
  detailTaskId?: number
  fileName: string
  fileParentId: number
  fileVersion: number
  previewUrl: string
  status: AnnotationStatus
  ticketVersion?: number
  time?: number
  type?: 'annotation' | 'video_annotation'
  userId: number
  uuid: string
}

interface VideoAnnotationResponse {
  data: VideoAnnotationData
}

// TODO: Video Annotation Data does not have assetId, fileVersion, previewUrl, userId.
export interface VideoAnnotationData extends UpdateImageAnnotationResponse {
  assetId: number
  createdAt: string
  createdBy: string
  data: VideoJSAnnotationPayload
  detailTaskId: number
  fileVersion: number
  previewUrl: string
  status: AnnotationStatus
  userId: number
}

interface AnnotationResponse {
  annotation: AnnotationRecord<AnnotoriousAnnotation>
}

interface CreateResponse {
  id: number
  assetId: number
  body: string
  createdAt: string
  createdBy: string
  data: {
    '@context': string
    type: string
    body: {
      created: string
      creator: {
        id: number
        name: string
      }
      modified: string
      purpose: string
      type: string
      value: string
    }[]
    target: {
      source: string
      selector: {
        type: string
        conformsTo: string
        value: string
      }
    }
    id: string
  }
  detailTaskId: number
  fileParentId: number
  fileVersion: number
  previewUrl: string
  status: AnnotationStatus
  userId: number
  uuid: string
}

interface UpdateAnnotationParams {
  assetId: number
  annotationId: number
  annotation: AnnotoriousAnnotation
  annotationStatus?: AnnotationStatus
  token?: string
}

interface UpdateImageAnnotationResponse {
  id: number
  body: string
  uuid: string
}

const ANNOTATION_DELIMITER = ' | '

export enum AnnotationStatus {
  Default = 'default',
  Pending = 'pending',
  Review = 'review',
  Approved = 'approved',
  Rejected = 'rejected',
}

function combineAnnotationTextForDetailTask(annotation: AnnotoriousAnnotation) {
  const values = annotation.body.map((bodyItem) => bodyItem.value)
  return values.join(ANNOTATION_DELIMITER)
}

export async function createAnnotation(
  assetId: number,
  annotation: AnnotoriousAnnotation,
  token?: string
): Promise<AnnotationResponse> {
  const payload = {
    annotation: {
      asset_id: assetId,
      asset_type: 'TicketFile',
      data: annotation,
      body: annotation.body[0].value,
      uuid: annotation.id,
    },
  }
  const postApiWithToken = bindJWT(postApi, token)
  const { data } = await baseRequest('/api/internal/annotations', postApiWithToken, payload)

  return { annotation: data as unknown as CreateResponse }
}

export async function createVideoAnnotation(
  ticketFileId: number,
  details: VideoJSAnnotationPayload,
  token: string
): Promise<VideoAnnotationResponse> {
  const payload = {
    annotation: {
      asset_type: 'TicketFile',
      asset_id: ticketFileId,
      body: details.comments[0].body,
      data: details,
      uuid: details.comments[0].id,
    },
  }
  const postWithToken = bindJWT(postApi, token)
  return postWithToken('/api/internal/annotations', payload)
}

export function deleteAnnotation(annotationId: number, token?: string): Promise<BaseResponse> {
  const deleteApiWithToken = bindJWT(deleteApi, token)
  return baseRequest(`/api/internal/annotations/${annotationId}`, deleteApiWithToken)
}

export async function updateAnnotation({
  assetId,
  annotationId,
  annotation,
  annotationStatus,
  token,
}: UpdateAnnotationParams): Promise<AnnotationResponse> {
  const body = combineAnnotationTextForDetailTask(annotation)
  const payload = {
    annotation: {
      asset_id: assetId,
      asset_type: 'TicketFile',
      data: annotation,
      body,
      uuid: annotation.id,
      status: annotationStatus,
    },
  }

  const patchApiWithToken = bindJWT(patchApi, token)
  const { data } = await baseRequest(`/api/internal/annotations/${annotationId}`, patchApiWithToken, payload)

  return {
    annotation: {
      assetId,
      data: annotation,
      ...(data as unknown as UpdateImageAnnotationResponse),
    } as AnnotationRecord<AnnotoriousAnnotation>,
  }
}

export async function updateVideoAnnotation(id: number, body: string, data: VideoJSAnnotationObject, token?: string) {
  const patchApiWithToken = bindJWT(patchApi, token)
  const payload = {
    annotation: {
      body: body,
      data: data,
    },
  }
  return patchApiWithToken(`/api/internal/annotations/${id}`, payload)
}
