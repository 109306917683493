import { useCallback, useEffect, useState } from 'react'
import { Format } from 'interfaces/format'
import { snakeCaseKeys } from 'lib/object/utils'
import Badge from 'lib/components/badge/badge'
import { getformats, routes } from 'lib/api/admin/formats/formats'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import Pagination from 'components/elements/pagination'
import AdminFormatsPageFilters from './admin-formats-page-filters'
import ToastProvider, { useToastContext } from 'providers/toast-provider'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  header: 'tw-flex tw-justify-between navbar-custom',
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
  tableWrapper: 'tw-w-full tw-block tw-overflow-x-auto tw-bg-white',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

export interface Filters {
  nameCont?: string
}

export const emptyFilters: Filters = {
  nameCont: '',
}

function AdminFormats() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [filters, setFilters] = useState<Filters>(emptyFilters)
  const [formats, setFormats] = useState<Format[]>([])
  const { alert } = useToastContext()

  useEffect(() => {
    fetchFormats(currentPage, filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const fetchFormats = useCallback(
    async (currentPage: number, filters: Filters) => {
      setIsLoading(true)
      try {
        const { formats, meta } = await getformats({ page: currentPage + 1, q: snakeCaseKeys(filters) })
        setFormats(formats)
        setPageCount(Math.ceil(meta.total / meta.pageSize))
      } catch (error) {
        console.error(error)
        alert('Failed to fetch skills')
      } finally {
        setIsLoading(false)
      }
    },
    [alert]
  )

  function onPageClick({ selected }) {
    setCurrentPage(selected)
    window.scrollTo(0, 0)
  }

  function handleSubmit(e) {
    e.preventDefault()
    fetchFormats(currentPage, filters)
  }

  function handleClear(e) {
    e.preventDefault()
    setFilters(emptyFilters)
    fetchFormats(0, emptyFilters)
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <AdminFormatsPageFilters
          filters={filters}
          setFilters={setFilters}
          onSubmit={handleSubmit}
          handleClear={handleClear}
        />
        <table className={classNames.table}>
          <TableHeader />
          <tbody>
            {isLoading ? (
              <LoadingScreenTableRow />
            ) : (
              formats.map(({ id, name, extensions, delivery, source }) => (
                <tr key={id} className={classNames.tableRow}>
                  <td className={classNames.tableCell}>
                    <a href={routes.edit(id)}>{name}</a>
                  </td>
                  <td className={classNames.tableCell}>{extensionDetails(extensions)}</td>
                  <td className={classNames.tableCell}>{delivery}</td>
                  <td className={classNames.tableCell}>
                    <Badge color={source ? 'success' : 'warning'} className="tw-ml-3">
                      {source.toString()}
                    </Badge>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} onPageChange={onPageClick} forcePage={currentPage} />
      </div>
    </div>
  )
}

const extensionDetails = (extensions) => extensions.join(', ')

const TableHeader = () => (
  <thead>
    <tr>
      <th className={classNames.tableHeadCell}>Name</th>
      <th className={classNames.tableHeadCell}>Extensions</th>
      <th className={classNames.tableHeadCell}>Delivery</th>
      <th className={classNames.tableHeadCell}>Source</th>
    </tr>
  </thead>
)

export default function AdminFormatsPage() {
  return (
    <ToastProvider>
      <AdminFormats />
    </ToastProvider>
  )
}
