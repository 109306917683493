import { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserContext } from 'providers/user-provider'
import NewRequestButton from '../request/new-request-button/new-request-button'
import styles from './empty-screens.module.scss'
import { LucideLoaderCircle } from 'lucide-react'

interface EmptyTableScreenProps {
  className?: string
}

interface EmptyTicketsScreenProps {
  activeTab: 'active' | 'draft' | 'archived'
}

interface LoadingScreenProps {
  size?: SizeProp
}

// Borrowed from the Font Awesome library
type SizeProp =
  | '2xs'
  | 'xs'
  | 'sm'
  | 'lg'
  | 'xl'
  | '2xl'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'

export function AnimatedLoadingScreen(): ReactElement {
  const customBranding = useUserContext().settings?.customBranding

  if (customBranding) {
    return <LucideLoaderCircle className="tw-animate-spin tw-w-20 tw-h-20 tw-opacity-20" />
  }

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img
          alt="Pickle Rocket"
          src="/images/progress/pickle-rocket-2-eyes.gif"
          className={styles.imageOverlay}
          height={378}
          width={454}
        />
        <img alt="Pickle Rocket" src="/images/progress/pickle-rocket-2.gif" height={378} width={454} />
      </div>
    </div>
  )
}

export function EmptyTicketsScreen({ activeTab }: EmptyTicketsScreenProps): ReactElement {
  const { user } = useUserContext()

  return (
    <tr>
      <td colSpan={10} className="tw-text-center tw-h-screen-60">
        <div>
          <div className="tw-text-xl">
            <img src="/images/states/none-exist.svg" alt="Empty Queue" />
          </div>
          <div className="tw-font-bold tw-mb-4">Your {activeTab} queue is empty</div>
          <NewRequestButton user={user} />
        </div>
      </td>
    </tr>
  )
}

export function EmptySearchScreen(): ReactElement {
  return (
    <tr>
      <td colSpan={10} className="tw-text-center tw-h-screen-60">
        <div>
          <div className="tw-text-3xl tw-text-peppercorn-300">
            <img src="/images/states/not-found.svg" alt="No Results" />
          </div>
          <div className="tw-font-bold">{"Uh oh! We weren't able to find any requests."}</div>
          <div>{"Try adjusting your search or filters to find what you're looking for."}</div>
        </div>
      </td>
    </tr>
  )
}

export const EmptyTableScreen = ({ className = '' }: EmptyTableScreenProps): ReactElement => (
  <div className={`tw-flex tw-justify-center tw-items-center tw-text-2xl tw-font-medium ${className}`}>
    No results were found.
  </div>
)

export function LoadingScreen({ size = 'xl' }: LoadingScreenProps): ReactElement {
  return (
    <div className="m-auto" data-testid="loading-screen">
      <div className="tw-text-3xl tw-text-peppercorn-300">
        <FontAwesomeIcon icon="spinner-third" spin size={size} />
      </div>
    </div>
  )
}

export function LoadingScreenTableRow(): ReactElement {
  return (
    <tr>
      <td colSpan={10} className="tw-text-center tw-h-screen-60">
        <div>
          <div className="tw-text-3xl tw-text-peppercorn-300">
            <FontAwesomeIcon icon="spinner-third" spin />
          </div>
        </div>
      </td>
    </tr>
  )
}
