import ActionLinkItem from 'components/elements/dropdown/items/action-link-item'
import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'
import { isStatusCompleted, isStatusOnDeck, showDownloadAction } from 'lib/ticket/utils'
import { AbilityResponse, fetchAbility } from 'lib/api/ability/ability'
import { useEffect, useState } from 'react'

interface ActiveTabMenuProps {
  id: bigint
  subject: string
  filesDownloadUrl: string
  status: string
  userId: number
  menuActionBtn: (route: string, data: Record<string, unknown>, method: string) => void
  ticketFilesCount: bigint
}

export default function ActiveTabMenu({
  id,
  subject,
  filesDownloadUrl,
  status,
  userId,
  menuActionBtn,
  ticketFilesCount,
}: ActiveTabMenuProps): JSX.Element {
  const [ticketAbility, setTicketAbility] = useState<AbilityResponse>(null)
  const downloadAssetsButtonUrl = filesDownloadUrl ? filesDownloadUrl.toString() : ''
  const markArchivedTicketUrl = window.Routes.apiInternalMarkArchivedUrl()
  const updateTicketUrl = window.Routes.apiInternalTicketUrl(id)
  const editTicketUrl = window.Routes.ticket(id, { edit_request: true })
  const cancelTicketUrl = window.Routes.cancelApiInternalTicket(id)
  const cancelButtonData = { id: id, user: userId }
  const markArchivedButtonData = {
    ticket_ids: [id],
    user_id: userId,
    update_action: 'mark_archived',
  }
  const moveToTopButtonData = {
    ticket_id: id,
    user_id: userId,
    update_action: 'move_to_top',
  }

  const cancelModalData = {
    title: 'Cancel Request?',
    message: `Are you sure you want to cancel ${subject}?`,
    confirmBtnText: 'Yes, Cancel',
    confirmAction: () => {
      menuActionBtn(cancelTicketUrl, cancelButtonData, 'PATCH')
    },
  }

  useEffect(() => {
    const fetchTicketAbility = async () => {
      const ability = await fetchAbility('Ticket', id)
      setTicketAbility(ability)
    }
    fetchTicketAbility()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isStatusOnDeck(status) && ticketAbility?.moveToTop && (
        <ActionLinkItem
          label="Move to Top"
          iconClass="far fa-arrow-to-top"
          route={updateTicketUrl}
          method="PATCH"
          data={moveToTopButtonData}
          menuActionBtn={menuActionBtn}
        />
      )}
      {isStatusOnDeck(status) && ticketAbility?.edit && (
        <ExternalActionLinkItem label="Edit" iconClass="far fa-pen" route={editTicketUrl} />
      )}
      {showDownloadAction(status, ticketFilesCount) && (
        <ExternalActionLinkItem
          label="Download Assets"
          iconClass="far fa-folder-download"
          route={downloadAssetsButtonUrl}
        />
      )}
      {isStatusCompleted(status) && ticketAbility?.markArchived && (
        <ActionLinkItem
          label="Archive"
          iconClass="far fa-check"
          route={markArchivedTicketUrl}
          method="PATCH"
          data={markArchivedButtonData}
          menuActionBtn={menuActionBtn}
        />
      )}
      {ticketAbility?.duplicate && (
        <ExternalActionLinkItem
          label="Duplicate"
          iconClass="far fa-copy"
          route={`/tickets/${id}/duplications`}
          method="POST"
        />
      )}
      {!isStatusCompleted(status) && ticketAbility?.cancel && (
        <ActionLinkItem
          label="Cancel"
          iconClass="fas fa-ban"
          data={cancelButtonData}
          menuActionBtn={menuActionBtn}
          modalData={cancelModalData}
        />
      )}
    </>
  )
}
