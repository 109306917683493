import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Clock } from 'lucide-react'
import Button from 'components/core/button'
import { AdminTicket, AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'
import Modal from 'lib/components/modals/modal'
import { decimalHoursToMinutes } from 'lib/util/date'

const minuteInMilliseconds = 60000

interface TimerProps {
  ticket: AdminTicket
}

function calculateMinutesLeft(ticket: AdminTicket): number {
  if (ticket.ticketTrackings.length === 0) {
    return Math.round(ticket.designTime)
  }

  function isDateWithinTwelveHours(date: Date): boolean {
    const currentDate = new Date()
    const twelveHoursAgo = new Date(currentDate.getTime() - 12 * 60 * 60 * 1000)
    return date >= twelveHoursAgo
  }

  const lastTwelveHoursTrackings = ticket.ticketTrackings.filter(
    (tracking) => !tracking.endTime || isDateWithinTwelveHours(new Date(tracking.endTime))
  )

  if (lastTwelveHoursTrackings.length === 0) {
    return Math.round(ticket.designTime)
  }

  const hoursWorked = lastTwelveHoursTrackings
    .map((tracking) => tracking.hoursWorked)
    .reduce((acc, hoursWorked) => acc + hoursWorked)
  return Math.round(ticket.designTime - decimalHoursToMinutes(hoursWorked))
}

function displayTimeLeft(minutesLeft: number): string {
  const hours = Math.floor(Math.abs(minutesLeft / 60))
  const minutes = Math.abs(minutesLeft % 60)
  const leftOrOver = minutesLeft >= 0 ? 'left' : 'over'

  if (hours === 0) {
    return `${minutes} minutes ${leftOrOver}`
  }

  if (minutes === 0) {
    return `${hours} hours ${leftOrOver}`
  }

  return `${hours} hours and ${minutes} minutes ${leftOrOver}`
}

export default function AdminTimer({ ticket }: TimerProps): ReactElement {
  const [minutesLeft, setMinutesLeft] = useState<number>(calculateMinutesLeft(ticket))
  const [showTimesUpModal, setShowTimesUpModal] = useState<boolean>(false)
  const ticketAlertRef = useRef(null)
  const intervalRef = useRef(null)

  const timeLeft = displayTimeLeft(minutesLeft)

  const timerTextColor = useMemo(() => {
    if (minutesLeft <= 0) {
      return 'tw-text-flushpink-500'
    }

    if (minutesLeft <= ticket.designTime * 0.25) {
      return 'tw-text-sunnyyellow-600'
    }

    return 'tw-text-neutral-800'
  }, [minutesLeft, ticket.designTime])

  useEffect(() => {
    if (ticket.state === AdminTicketState.inProgress) {
      intervalRef.current = setInterval(() => {
        setMinutesLeft((prev) => {
          const newValue = prev - 1

          if (newValue === 0) {
            ticketAlertRef.current.play()
            setShowTimesUpModal(true)
          }

          return newValue
        })
      }, minuteInMilliseconds)
    } else {
      clearInterval(intervalRef.current)
    }
    return () => clearInterval(intervalRef.current)
  }, [minutesLeft, ticket.state])

  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      <TimesUpModal setShowTimesUpModal={setShowTimesUpModal} showTimesUpModal={showTimesUpModal} />
      <audio ref={ticketAlertRef} src="/ticket-alert.mp3" data-testid="audio-tag" />
      <div className={`${timerTextColor} tw-flex tw-items-center tw-gap-1`}>
        <Clock className="lu-light lu-md" />
        {timeLeft}
      </div>
    </div>
  )
}

interface TimesUpModalProps {
  showTimesUpModal: boolean
  setShowTimesUpModal: (show: boolean) => void
}

function TimesUpModal({ showTimesUpModal, setShowTimesUpModal }: TimesUpModalProps) {
  return (
    <Modal open={showTimesUpModal} setOpen={setShowTimesUpModal} size="sm">
      <Modal.Header>Times Up</Modal.Header>
      <Modal.Body setOpen={setShowTimesUpModal} closeButton>
        <p>
          Today&apos;s design time is up for this ticket. Wrap up your work and click the &quot;Stop&quot; button once
          you are ready.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-justify-end">
          <Button onClick={() => setShowTimesUpModal(false)} color="purple">
            Got it
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
