import { ReactElement, useMemo } from 'react'
import { AdminTicketFile } from 'lib/api/admin/ticket-files/admin-ticket-files'
import AdminAnnotationViewer from './admin-annotation-viewer'
import { useAdminUserContext } from 'providers/admin-user-provider'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'

interface AnnotatedMediaViewerProps {
  ticketFile: AdminTicketFile
}

export default function AdminAnnotatedMediaViewer({ ticketFile }: AnnotatedMediaViewerProps): ReactElement {
  const { user } = useAdminUserContext()
  async function onCreateAnnotation() {
    // TODO: Implement when we do annotations SU-6787
  }

  async function onUpdateAnnotation() {
    // TODO: Implement when we do annotations SU-6787
  }

  const AnnotationViewerWrapper = useMemo<ReactElement>(() => {
    const annotoriousAnnotations = ticketFile.annotations.map((annotation) => {
      return {
        ...annotation.data,
      }
    }) as unknown as AnnotoriousAnnotation[]

    return (
      <AdminAnnotationViewer
        annotations={annotoriousAnnotations || []}
        onCreate={onCreateAnnotation}
        onUpdate={onUpdateAnnotation}
        readOnly={true}
        url={ticketFile?.previewUrl}
        user={user}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketFile?.previewUrl])

  return <>{AnnotationViewerWrapper}</>
}
