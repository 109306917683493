import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import { generateRangeTo } from 'lib/util/range'
import { AdminTimelineItem } from 'lib/api/admin/timeline/admin-timeline'
import { LinkLikeButton } from 'components/core/button'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AdminTimelineFooter from './admin-timeline-footer'
import AdminTimelineDisplayItem from './admin-timeline-display-item'

// TODO: Implement timeline filter
// const timelineSelectOptions = [
//   {
//     value: 'notes',
//     displayElement: <>Notes</>, // Internal only aka yellow notes
//   },
//   {
//     value: 'conversations',
//     displayElement: <>Conversations</>, // Timeline items with client and creatives but not internal notes
//   },
//   {
//     value: 'conversations_notes',
//     displayElement: <>Conversations & Notes</>, // Everything
//   },
// ]

function timelineKey(item: AdminTimelineItem): string {
  return `${item.taskType}-${item.id}`
}

function TimelineHeader(): ReactElement {
  const { ticket } = useAdminTicketContext()
  const { selectedFilters, setSelectedFilters } = useAdminTimelineContext()
  const versions = useMemo(() => generateRangeTo(ticket?.currentVersion), [ticket?.currentVersion])

  const handleVersionChange = (ticketVersion: number) => {
    setSelectedFilters((previous) => ({ ...previous, ticketVersion }))
  }

  // function handleTimelineFilterChange(selectedValue: string) {
  //   setSelectedFilter(selectedValue)
  // }

  return (
    <header className="tw-sticky tw-left-0 tw-top-0 tw-w-full tw-bg-white tw-flex tw-flex-col tw-py-4 tw-z-20">
      <h2 className="tw-text-base tw-m-0">Directions</h2>
      <div className="tw-flex tw-items-center tw-gap-6">
        <div className="tw-w-64">
          <TicketVersionSelectBox
            versions={versions}
            selectedVersion={selectedFilters.ticketVersion}
            setSelectedVersion={handleVersionChange}
          />
        </div>
        {/* TODO: Implement timeline filter*/}
        {/*<div className="tw-w-64">*/}
        {/*  <SelectBox*/}
        {/*    options={timelineSelectOptions}*/}
        {/*    handleChange={handleTimelineFilterChange}*/}
        {/*    selectedValue={selectedFilter}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </header>
  )
}

function TimelineContent() {
  const [isNextPageLoading, setIsNextPageLoading] = useState(false)
  const [topItemId, setTopItemId] = useState(null)
  const { orderedTimelineItems, getNextPage, hasNextPage } = useAdminTimelineContext()
  const observerTargetDiv = useRef<HTMLDivElement>(null)
  const topItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hasNextPage && orderedTimelineItems?.length) {
      const observer = new IntersectionObserver(
        async ([targetDiv]) => {
          if (targetDiv.isIntersecting && !isNextPageLoading) {
            setIsNextPageLoading(true)
            setTopItemId(timelineKey(orderedTimelineItems[0]))
            await getNextPage()
            observer.disconnect()
            setTimeout(() => {
              setIsNextPageLoading(false)
            }, 500)
          }
        },
        { threshold: 0.5 }
      )

      if (observerTargetDiv.current && !isNextPageLoading) {
        observer.observe(observerTargetDiv.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, orderedTimelineItems, isNextPageLoading])

  useEffect(() => {
    if (topItemRef.current) {
      topItemRef.current.scrollIntoView()

      setTopItemId(null)
    }
  }, [topItemId])

  if (!orderedTimelineItems) {
    return <div>Loading...</div>
  }

  if (orderedTimelineItems.length === 0) {
    return <div className="tw-pt-4">No directions available</div>
  }

  return (
    <ol className="tw-list-none tw-p-0 tw-overflow-auto">
      {hasNextPage && !isNextPageLoading && (
        <div ref={observerTargetDiv} className="tw-ml-5 tw-bg-white tw-text-center tw-text-sm tw-text-gray-500">
          <LinkLikeButton onClick={getNextPage}>More...</LinkLikeButton>
        </div>
      )}
      {orderedTimelineItems.map((item) => (
        <span key={timelineKey(item)}>
          {timelineKey(item) === topItemId && <div ref={topItemRef} />}
          <AdminTimelineDisplayItem item={item} />
        </span>
      ))}
    </ol>
  )
}

export default function AdminTimeline(): ReactElement {
  return (
    <div>
      <div className="tw-px-5 tw-pb-6">
        <TimelineHeader />
        <TimelineContent />
      </div>
      <div className="tw-py-4 tw-border-0 tw-border-t tw-border-solid tw-border-gray-200 tw-sticky tw-left-0 tw-bottom-0 tw-w-full tw-bg-white tw-z-10">
        <div className="tw-px-5">
          <AdminTimelineFooter />
        </div>
      </div>
    </div>
  )
}
