import { baseRequest, BaseResponse, deleteApi, getApi, patchApi, postApi } from '../api'

export interface ConversationComment {
  id: number
  body: string
  createdAt: string
  incoming: boolean
  meta: null
  private: boolean
  taskType: 'conversation'
  ticketId: number
  ticketVersion: number
  updatedAt: string
  user: ConversationUser
}

interface ConversationUser {
  id: number
  avatar?: string
  fullName: string
}

export async function createConversationComment(ticketId: number, body: string): Promise<ConversationComment> {
  const response = await baseRequest(`/api/internal/tickets/${ticketId}/conversations`, postApi, { data: { body } })
  return response.data.data as unknown as ConversationComment
}

export function deleteConversationComment(ticketId: number, commentId: number): Promise<BaseResponse> {
  return baseRequest(`/api/internal/tickets/${ticketId}/conversations/${commentId}`, deleteApi)
}

export async function getConversations(ticketId: number): Promise<ConversationComment[]> {
  const response = await baseRequest(`/api/internal/tickets/${ticketId}/conversations`, getApi)
  return response.data.data as unknown as ConversationComment[]
}

export async function updateConversationComment(
  ticketId: number,
  commentId: number,
  body: string
): Promise<ConversationComment> {
  const response = await baseRequest(`/api/internal/tickets/${ticketId}/conversations/${commentId}`, patchApi, {
    data: { body },
  })
  return response.data.data as unknown as ConversationComment
}
