import { LegacyRef, ReactNode } from 'react'
import { DraggableProvided, DroppableProvided } from 'react-beautiful-dnd'

interface DraggableTableRowProps {
  children: ReactNode
  innerRef: LegacyRef<HTMLTableRowElement>
  draggableProvided: DraggableProvided
}

interface DroppableTableRowProps {
  children: ReactNode
  innerRef: LegacyRef<HTMLTableRowElement>
  droppableProvided: DroppableProvided
}

export function DraggableTableRow({ children, innerRef, draggableProvided }: DraggableTableRowProps): JSX.Element {
  return (
    <tr
      ref={innerRef}
      {...draggableProvided.draggableProps}
      className="tw-bg-white tw-whitespace-normal tw-border-solid tw-border-1 tw-h-12"
    >
      {children}
    </tr>
  )
}

export function DroppableTableRow({ children, innerRef, droppableProvided }: DroppableTableRowProps): JSX.Element {
  return (
    <tr
      ref={innerRef}
      {...droppableProvided.droppableProps}
      className="tw-bg-white tw-whitespace-normal tw-border-solid tw-border-1 tw-h-12"
    >
      {children}
    </tr>
  )
}
