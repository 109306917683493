import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSProperties, ReactElement, useState } from 'react'

interface SortableTableHeaderProps {
  children: ReactElement
  className?: string
  column: string
  sortedClassName?: string
  onClick: (column?, direction?) => void
  sortColumn: string
  sortDirection: 'ASC' | 'DESC'
  isSortable?: boolean
}

export default function SortableTableHeader({
  children,
  className = '',
  column,
  sortedClassName,
  onClick,
  sortColumn,
  sortDirection,
  isSortable = true,
}: SortableTableHeaderProps): ReactElement {
  const [hovering, setHovering] = useState(false)

  const isFirstSorting = sortColumn !== column
  const isDESC = sortDirection === 'DESC'
  const invertSortOrder = isDESC ? 'ASC' : 'DESC'

  const thStyle: CSSProperties = {
    whiteSpace: 'nowrap',
  }

  const changeSortState = () => {
    if (isFirstSorting) return onClick(column, 'ASC')
    if (!isDESC) return onClick(column, invertSortOrder)
    onClick()
  }

  const SortingIcon = () => {
    if (!isSortable) return null

    if (isFirstSorting)
      return (
        <span className={hovering ? '' : 'hidden'} data-testid="sort-button-up-down">
          <FontAwesomeIcon icon={['fal', 'arrow-up']} />
        </span>
      )
    if (!isDESC)
      return (
        <span data-testid="sort-button-up">
          <FontAwesomeIcon icon={['fal', 'arrow-up']} />
        </span>
      )
    return (
      <span data-testid="sort-button-down">
        <FontAwesomeIcon icon={['fal', 'arrow-down']} />
      </span>
    )
  }

  const sortedStyle = !isFirstSorting && isDESC ? sortedClassName : ''
  const hoveringStyle = isSortable ? 'tw-cursor-pointer' : ''

  return (
    <th
      className={`${className} ${sortedStyle} tw-pr-6 ${hoveringStyle}`}
      scope="col"
      onClick={isSortable ? changeSortState : undefined}
      onMouseEnter={() => {
        isSortable && setHovering(true)
      }}
      onMouseLeave={() => {
        isSortable && setHovering(false)
      }}
      data-testid="sort-button"
      style={thStyle}
    >
      {children}
      <span className="tw-relative tw-float-right tw-w-0">
        <SortingIcon />
      </span>
    </th>
  )
}
