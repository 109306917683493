import { bindJWT, getApi } from 'lib/api/api'

export interface CannedResponse {
  id: number
  body: string
  subject: string
}
export interface CannedResponseFolder {
  id: number
  name: string
  cannedResponses: CannedResponse[]
}

export async function getCannedResponses(jwt: string = null): Promise<CannedResponseFolder[]> {
  const getWithJWT = bindJWT(getApi, jwt)
  const url = `/api/internal/admin/canned_responses`

  const response = await getWithJWT(url)
  return response.data.data
}
