import { AppSettings } from 'interfaces/app'
import { Company } from 'interfaces/company'
import ToastProvider from 'providers/toast-provider'
import CustomBranding from './custom-branding'

export interface CustomBrandingSettings {
  companyId: string
  createdAt: string
  id: string
  logo?: string
  mappedTo?: string
  primaryColor?: string
  updatedAt?: string
  url?: string
  userId?: string
}

export interface CustomBrandingPageProps {
  settings: AppSettings
  company: Company
  customBranding?: CustomBrandingSettings
  customBrandingStatus?: boolean
  customBrandingPlanId?: number
  hasWhiteLabelSubscription?: boolean
  canSubscribeToWhiteLabel?: boolean
  isPowerCompany?: boolean
}

export default function CustomBrandingPage(props: CustomBrandingPageProps) {
  return (
    <ToastProvider>
      <CustomBranding {...props} />
    </ToastProvider>
  )
}
