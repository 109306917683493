import { ChangeEvent, useState } from 'react'

import Modal from 'lib/components/modals/modal'

import Button from 'components/core/button'
import { useToastContext } from 'providers/toast-provider'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import TextInput from 'components/core/text-input/text-input'
import { isValidFormatUrl } from 'lib/util/url'
import { useAdminMediaContext } from '../providers/admin-media-provider'

interface AddLinkModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export default function AddLinkModal({ open, setOpen }: AddLinkModalProps) {
  const [inputValue, setInputValue] = useState('')
  const { addFile } = useAdminMediaContext()
  const { ticket } = useAdminTicketContext()
  const { alert, notice } = useToastContext()

  async function addLink(event) {
    event.preventDefault()

    try {
      await addFile({ externalLink: inputValue })

      notice('Link Added')
      setOpen(false)
    } catch (error) {
      alert('Error adding link, please try again')
    }
  }

  function getFormatMessage(formats: string[]): string {
    const validFormats = formats.filter((format) => ['figma', 'canva', 'google_slides'].includes(format))

    const formatNames: Record<string, string> = {
      figma: 'Figma',
      canva: 'Canva',
      google_slides: 'Google Slides',
    }

    const displayFormats = validFormats.map((format) => formatNames[format])

    if (displayFormats.length === 0) {
      return 'Paste the full share URL, including https://.'
    }

    if (displayFormats.length === 1) {
      return `Paste the full share URL from ${displayFormats[0]}, including https://.`
    }

    if (displayFormats.length === 2) {
      return `Paste the full share URL from ${displayFormats[0]} or ${displayFormats[1]}, including https://.`
    }

    if (displayFormats.length > 2) {
      const lastFormat = displayFormats.pop()
      return `Paste the full share URL from ${displayFormats.join(', ')}, or ${lastFormat}, including https://.`
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} size="lg" clickOutsideToClose={false}>
      <Modal.Header as="div">
        <h4 className="tw-my-0">Add Link</h4>
        <p className="tw-text-gray-500 tw-text-sm">{getFormatMessage(ticket.selectedFormats)}</p>
      </Modal.Header>
      <Modal.Body setOpen={setOpen} closeButton>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-neutral-600">
          <form className="tw-flex tw-flex-col tw-gap-6 tw-font-semibold tw-text-md">
            <TextInput
              name="addLink"
              type="text"
              placeholder="Enter URL"
              className="tw-w-full"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value)
              }}
            />
            <div className="tw-flex tw-flex-row tw-gap-2 tw-justify-end tw-items-center">
              <Button
                color="neutralGray"
                type="button"
                onClick={() => {
                  setOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                color="purple"
                type="submit"
                onClick={addLink}
                disabled={!isValidFormatUrl(ticket.selectedFormats, inputValue)}
              >
                Add Link
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
