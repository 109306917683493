const classNames = {
  checkboxWrapper: 'tw-flex tw-items-center tw-rounded-full',
  checkbox:
    'tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600',
}

interface CheckboxProps {
  onClick: () => void
  isChecked: boolean
  className?: string
  disabled?: boolean
}

export default function Checkbox({ isChecked, onClick, disabled = false, className = '' }: CheckboxProps) {
  return (
    <div className={`${classNames.checkboxWrapper} ${className}`}>
      <input
        checked={isChecked}
        id="checked-checkbox"
        type="checkbox"
        value=""
        className={`${classNames.checkbox}`}
        onChange={onClick}
        disabled={disabled}
      />
    </div>
  )
}
