import { ReactElement, useEffect } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { canI, fetchAbilities } from 'lib/api/ability/ability'

import Pagination from 'components/elements/pagination'
import Tabs from 'components/elements/tabs'
import ConfirmationModal from 'components/elements/confirmation-modal'

import Table from 'components/core/table'
import TableBody from 'components/core/table-body'

import TableContent from 'components/pages/requests/table-elements/table-content'
import TableHeaderSection from 'components/pages/requests/table-elements/table-header'
import TicketListFilters from './ticket-list-filters'
import { LoadingScreen } from './empty-screens'
import { getStateFromQueryString } from 'lib/api/tickets/tickets'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import ThumbRateRequestModal from 'components/core/thumb-rate-request-modal'

export default function TicketList(): ReactElement {
  const {
    activeTab,
    changeTab,
    currentPage,
    dataLoaded,
    fetchTickets,
    filters,
    handlePageChange,
    isModalVisible,
    modalData,
    onDragEnd,
    oops,
    pageCount,
    setActiveTab,
    setCurrentPage,
    setFilters,
    setModalVisible,
    setUserCanSort,
    tabs,
    urlState,
  } = useSingleQueueContext()

  useEffect(() => {
    if (dataLoaded) {
      fetchTickets().catch(oops)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.friendlyStatus, filters.userIdEq, filters.skillSkillCategorySubscriptionTypeEq, filters.brandIdEq])

  useEffect(() => {
    if (dataLoaded && filters.titleQuery === '') {
      fetchTickets().catch(oops)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.titleQuery])

  useEffect(() => {
    setActiveTab(urlState.activeTab)
    setFilters(urlState.filters)
    setCurrentPage(urlState.currentPage)

    fetchAbilities(['Ticket']).then((data) => setUserCanSort(canI('Ticket', 'sort', data)))
    fetchTickets().catch(oops)

    window.document.title = 'Requests - Design Pickle'

    window.onpopstate = () => {
      const urlState = getStateFromQueryString()
      setActiveTab(urlState.activeTab)
      setFilters(urlState.filters)
      setCurrentPage(urlState.currentPage)
      fetchTickets().catch(oops)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function TicketListTable() {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Table innerRef={provided.innerRef}>
              <TableHeaderSection droppableProvided={provided} />
              <TableBody>
                <TableContent />
                {provided.placeholder}
              </TableBody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  if (!dataLoaded) {
    return <LoadingScreen />
  }

  return (
    <div className="tw-mb-28 tw-mt-14">
      <ConfirmationModal
        title={modalData['title']}
        message={modalData['message']}
        confirmBtnText={modalData['confirmBtnText']}
        confirmAction={modalData['confirmAction']}
        cancelAction={() => setModalVisible(false)}
        visible={isModalVisible}
      />
      <ThumbRateRequestModal />
      <TicketListFilters />
      <Tabs tabs={tabs} activeTab={activeTab} onClick={changeTab} />
      <TicketListTable />
      <Pagination forcePage={currentPage - 1} pageCount={pageCount} onPageChange={handlePageChange} />
    </div>
  )
}
