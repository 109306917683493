import { FormEvent, useState } from 'react'

import Modal from 'lib/components/modals/modal'
import SelectBox, { SelectBoxOption } from 'lib/components/dropdown/select-box'
import Button from 'components/core/button'
import { useToastContext } from 'providers/toast-provider'

import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import TextInput from 'lib/components/text-input/text-input'
import { convertCustomSizesToString } from 'lib/util/skill-sizes/skill-sizes'
import { CustomSize, Skill, SkillSize } from 'lib/api/skills/skills'
import AdminTicketSizeDropdown from './admin-ticket-size-dropdown'
import AdminTimeSelector from '../stop-modal/admin-time-selector'
import RequestTypePopover from 'components/pages/request/request-header/request-type-popover'

interface AdminEditTicketDetailsModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const yesNoOptions = [
  { displayElement: 'Yes', value: true },
  { displayElement: 'No', value: false },
] as unknown as SelectBoxOption[]

export default function AdminEditTicketDetailsModal({ open, setOpen }: AdminEditTicketDetailsModalProps) {
  const { ticket, updateTicket } = useAdminTicketContext()
  const { alert, notice } = useToastContext()

  const [training, setTraining] = useState(ticket.training)
  const [covered, setCovered] = useState(ticket.covered)
  const [designerError, setDesignerError] = useState(ticket.designerError)
  const [subject, setSubject] = useState(ticket.subject)
  const [skillSizes, setSkillSizes] = useState(ticket.selectedSkillSizes || [])
  const [size, setSize] = useState(ticket.size)
  const [selectedTime, setSelectedTime] = useState(ticket.designTime / 60)
  const [skill, setSkill] = useState<Skill>(ticket.skill)

  const ticketPermissions = ticket.meta.permissions

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    try {
      const skillSizeIds = skillSizes.map((size) => size.id)
      await updateTicket({
        id: ticket.id,
        training,
        covered,
        designerError,
        subject,
        skillId: skill.id,
        skillSizeIds,
        size,
        todaysWeight: selectedTime,
      })
      notice('Ticket details updated successfully')
      setOpen(false)
    } catch (error) {
      alert('There was an error updating the ticket details. try again later')
    }
  }

  function handleCancel() {
    setTraining(ticket.training)
    setCovered(ticket.covered)
    setDesignerError(ticket.designerError)
    setSubject(ticket.subject)
    setSize(ticket.size)
    setSkillSizes(ticket.selectedSkillSizes)

    setOpen(false)
  }

  function onChangeSizes(sizes: { skillSizes?: SkillSize[]; customSizes?: CustomSize[] }) {
    if (sizes.skillSizes) {
      setSkillSizes(sizes.skillSizes)
    }
    if (sizes.customSizes) {
      setSize(convertCustomSizesToString(sizes.customSizes) || null)
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      <Modal.Header>Edit Ticket Details</Modal.Header>
      <Modal.Body setOpen={setOpen}>
        <form onSubmit={handleSubmit}>
          <div className="tw-mb-8 tw-flex tw-flex-col tw-gap-4">
            <div>
              <label htmlFor="subject-input" className="tw-mb-0 tw-text-neutral-500">
                Subject
              </label>
              <TextInput
                id="subject-input"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="tw-mt-2 tw-mb-0"
              />
            </div>
            {ticketPermissions.update.skillId && (
              <div className="tw--mb-4 tw--mx-4" data-testid="skillDropdown">
                <RequestTypePopover
                  skill={skill}
                  onChange={(skill) => {
                    setSkill(skill)
                  }}
                  showValidationError={false}
                  disabled={false}
                />
              </div>
            )}
            {ticketPermissions.update.todaysWeight && (
              <div>
                <label htmlFor="subject-input" className="tw-mb-0 tw-text-neutral-500">
                  Design Time
                </label>
                <AdminTimeSelector selectedTime={selectedTime} setSelectedTime={setSelectedTime} className="tw-mt-2" />
              </div>
            )}
            {ticketPermissions.update.skillSizeIds && (
              <div>
                <label className="tw-mb-2 tw-block tw-text-neutral-500">Size(s)</label>
                <AdminTicketSizeDropdown
                  skillSizes={ticket.skill.skillSizes}
                  selectedSkillSizes={skillSizes}
                  selectedCustomSizes={size}
                  onChange={onChangeSizes}
                />
              </div>
            )}
            {ticketPermissions.update.training && (
              <SelectBox
                options={yesNoOptions}
                handleChange={(value: boolean) => setTraining(value)}
                selectedValue={training}
                label="Is this a training ticket?"
              />
            )}
            {ticketPermissions.update.covered && (
              <SelectBox
                options={yesNoOptions}
                handleChange={(value: boolean) => setCovered(value)}
                selectedValue={covered}
                label="Is this covered by a different designer?"
              />
            )}
            {ticketPermissions.update.designerError && (
              <SelectBox
                options={yesNoOptions}
                handleChange={(value: boolean) => setDesignerError(value)}
                selectedValue={designerError}
                label="Did the designer make an error?"
              />
            )}
          </div>
          <div className="tw-flex tw-items-center tw-justify-end tw-gap-2">
            <Button onClick={handleCancel} color="lightGray">
              Cancel
            </Button>
            <Button type="submit" color="purple">
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
