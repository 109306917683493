import RequestTypePopover from './request-type-popover'
import RequestSizePopover from './request-size-popover'
import RequestFormatsPopover from './request-formats-popover'
import RequestBrandsPopover from './request-brands-popover'
import RequestPreferredDesignerPopover from './request-preferred-designer-popover'
import { useEffect, useMemo, useState } from 'react'
import { CustomSize, Skill, SkillCategorySkill, SkillSize } from 'lib/api/skills/skills'
import { Brand } from 'lib/api/companies/companies'
import { Designer } from 'lib/api/tickets/tickets'
import { useRequestContext } from '../providers/request-provider'
import { useUserContext } from 'providers/user-provider'

function Line() {
  return <div className="tw-h-5 tw-bg-neutral-700 tw-relative tw--top-2" style={{ width: '1px' }} />
}

export default function HeaderDropdowns() {
  const [skill, setSkill] = useState<Skill>(null)
  const { user } = useUserContext()
  const { dispatch, skillSizes, ticket, invalidFields, possibleDesigners, showValidationErrors } = useRequestContext()

  const isShowPreferredDesigner = useMemo(() => {
    return user.canChooseDesigners && possibleDesigners && possibleDesigners.length > 1
  }, [user, possibleDesigners])

  function onChangeSkill(skill: SkillCategorySkill) {
    dispatch('skill', { skill })
  }

  function onChangeSkillFormats(formats: string[]) {
    dispatch('formats', { formats })
  }

  function onChangeSizes(sizes: { skillSizes?: SkillSize[]; customSizes?: CustomSize[] }) {
    dispatch('sizes', sizes)
  }

  function onChangeSkillBrands(brand: Brand) {
    dispatch('brand', { brand })
  }

  function onChangePreferredDesigner(preferredDesigner: Designer) {
    dispatch('designer', { preferredDesigner })
  }

  useEffect(() => {
    if (ticket?.skill?.id !== skill?.id) {
      setSkill(ticket.skill)
    }
  }, [ticket?.skill, skill])

  return (
    <div className="tw-flex tw-items-center">
      <RequestTypePopover
        skill={skill}
        onChange={onChangeSkill}
        showValidationError={invalidFields?.includes('skill') && showValidationErrors}
        disabled={ticket?.status?.toLowerCase() !== 'draft'}
      />
      <Line />
      <RequestSizePopover
        skillSizes={skillSizes}
        onChange={onChangeSizes}
        ticketSkillSizes={ticket?.selectedSkillSizes}
        ticketCustomSizes={ticket?.size || ''}
        className="tw-ml-2"
        showValidationError={invalidFields?.includes('sizes') && showValidationErrors}
      />
      <Line />
      <div className="tw-ml-2">
        <RequestFormatsPopover
          availableFormats={ticket?.availableFormats || []}
          onChange={onChangeSkillFormats}
          ticketSelectedFormats={ticket?.selectedFormats}
          showValidationError={invalidFields?.includes('formats') && showValidationErrors}
        />
      </div>
      <Line />
      <div className="tw-ml-2">
        <RequestBrandsPopover
          onChange={onChangeSkillBrands}
          ticketBrand={ticket?.selectedBrand}
          companyId={user.companyId}
        />
      </div>
      {isShowPreferredDesigner && (
        <>
          <Line />
          <div className="tw-ml-2">
            <RequestPreferredDesignerPopover
              onChange={onChangePreferredDesigner}
              possibleDesigners={possibleDesigners}
              preferredDesigner={ticket?.preferredDesigner}
            />
          </div>
        </>
      )}
    </div>
  )
}
