import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './toast-provider.module.scss'

interface ToastContextValue {
  alert?: (message: string) => void
  notice?: (message: string) => void
}

interface ToastProviderProps {
  children: React.ReactNode
}

interface ToastProps {
  message: string
  setMessage: (message: string) => void
  type: MessageType
}

enum MessageType {
  alert,
  notice,
}

const ToastContext = createContext<ToastContextValue>({})

export function useToastContext(): ToastContextValue {
  return useContext(ToastContext) as ToastContextValue
}

function Toast({ message, setMessage, type }: ToastProps) {
  const colorClass = type === MessageType.alert ? styles.alert : styles.notice

  useEffect(() => {
    let isAbandoned = false
    setTimeout(() => {
      if (!isAbandoned) {
        setMessage('')
      }
    }, 5000)
    return () => {
      isAbandoned = true
    }
  }, [setMessage])

  return (
    <>
      {!!message && (
        <div className="tw-fixed tw-top-24 tw-inset-x-0 tw-mx-auto tw-my-0 tw-max-w-lg tw-items-center tw-z-50">
          <div className={`${styles.flashMessage} ${colorClass}`} role="alert">
            <span className={styles.icon} onClick={() => setMessage('')}>
              <FontAwesomeIcon icon="times" />
            </span>
            {message}
          </div>
        </div>
      )}
    </>
  )
}

export default function ToastProvider({ children }: ToastProviderProps): ReactElement {
  const [type, setType] = useState(MessageType.notice)
  const [message, setMessage] = useState<string>('')

  function alert(message: string) {
    setMessage(message)
    setType(MessageType.alert)
  }

  function notice(message: string) {
    setMessage(message)
    setType(MessageType.notice)
  }

  const contextValue = { alert, notice }

  return (
    <ToastContext.Provider value={contextValue}>
      {message && <Toast message={message} setMessage={setMessage} type={type} />}
      {children}
    </ToastContext.Provider>
  )
}
