import { Company } from 'interfaces/company'
import Dropdown from 'lib/components/dropdown/dropdown'
import { useCompanyDailyAvailableTimesContext } from 'providers/company-daily-available-times-provider'
import { ReactElement, useCallback, useEffect } from 'react'

const classNames = {
  dropdownZone: 'tw-w-full tw-h-24',
  dropdownOption: 'tw-p-2 tw-cursor-pointer hover:tw-bg-neutral-100',
  dropdownContent: 'tw-w-fit tw-h-48 tw-overflow-y-auto',
}

export default function CompanyDropdown(): ReactElement {
  const { company, companies, getTicketsByCompany, errors, resetErrors } = useCompanyDailyAvailableTimesContext()

  const oops = useCallback((err) => {
    const message = err?.message || 'Oops! Something went wrong. Please try again.'
    alert(message)
    console.error(err)
  }, [])

  useEffect(() => {
    if (errors.length) {
      oops(errors[0])
      resetErrors()
    }
  })

  function onChange(company: Partial<Company>) {
    getTicketsByCompany(company.id)
  }

  function DropdownContent() {
    return (
      <div className={classNames.dropdownContent}>
        {companies.map((company) => (
          <div key={company.id} onClick={() => onChange(company)} className={classNames.dropdownOption}>
            {company.name}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={classNames.dropdownZone}>
      <Dropdown title={company?.name || 'Select Company'} label="Companies" size="xs">
        <DropdownContent />
      </Dropdown>
    </div>
  )
}
