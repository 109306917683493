import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import {
  AdminTicket,
  AdminTicketState,
  AdminUpdateTicketParams,
  createNewVersion,
  failSelfQr,
  failTicketQr,
  getTicket,
  passSelfQr,
  passTicketQr,
  patchCustomerClarification,
  pauseTicket,
  resumeTicket,
  startTicket,
  startTicketQr,
  StopAction,
  stopTicket,
  updateFeaturedFile,
  updateTicket,
} from 'lib/api/admin/tickets/admin-tickets'
import { updateAdminNavigatorTitle } from 'components/pages/admin/admin-app/admin-app'
import { AnimatedLoadingScreen } from 'components/pages/requests/empty-screens'

interface AdminTicketContextProps {
  children: ReactNode
}

export interface AdminView {
  rightSidebar: 'clientProfile' | 'timeline' | 'ticketDetails' | 'logs'
  mainContent: 'showWithOverflow' | 'show' | null
}

interface AdminTicketContextValue {
  createNewVersion: () => Promise<AdminTicket>
  currentView: AdminView
  customerClarification: () => Promise<void>
  pause: () => Promise<void>
  resume: () => Promise<void>
  saveAsFeaturedFile: (fileId: number) => Promise<void>
  setMainContent: (view: AdminView['mainContent']) => void
  setRightSidebar: (view: AdminView['rightSidebar']) => void
  start: () => Promise<void>
  startQr: () => Promise<void>
  failQr: () => Promise<void>
  passQr: (messageBody: string) => Promise<void>
  stop: (action: StopAction, time: number) => Promise<void>
  ticket: AdminTicket
  updateTicket: (ticketParams: AdminUpdateTicketParams) => void
}

const defaultAdminView: AdminView = {
  mainContent: null,
  rightSidebar: 'timeline',
}

const AdminTicketContext = createContext({})

export default function AdminTicketProvider({ children }: AdminTicketContextProps): ReactElement {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [ticket, setTicket] = useState<AdminTicket>()
  const [currentView, setCurrentView] = useState<AdminView>(defaultAdminView)

  async function pause() {
    const response = await pauseTicket(ticket.id)
    setTicket(response)
  }

  async function resume() {
    const response = await resumeTicket(ticket.id)
    setTicket(response)
  }

  async function start() {
    const response = await startTicket(ticket.id)
    setTicket(response)
  }

  async function startQr() {
    const response = await startTicketQr(ticket.id, ticket.qaReviews[0].id)
    setTicket({ ...ticket, qaReviews: [response, ...ticket.qaReviews] })
  }

  async function failQr() {
    if (ticket.meta.permissions.selfQualityReview) {
      const response = await failSelfQr(ticket.id)
      setTicket(response)
    } else {
      const response = await failTicketQr({ ticketId: ticket.id, qaId: ticket.qaReviews[0]?.id })

      setTicket({
        ...ticket,
        qaReviews: [response, ...ticket.qaReviews],
        state: AdminTicketState.incompleteReviewFailed,
      })
    }
  }

  async function passQr(messageBody: string) {
    if (ticket.meta.permissions.selfQualityReview) {
      const response = await passSelfQr(ticket.id, messageBody)
      setTicket(response)
    } else {
      const response = await passTicketQr(ticket.id, ticket.qaReviews[0].id, messageBody)

      setTicket({ ...ticket, qaReviews: [response, ...ticket.qaReviews], state: AdminTicketState.customerReview })
    }
  }

  async function stop(action: StopAction, time?: number) {
    const response = await stopTicket(ticket.id, action, time)
    setTicket(response)
  }

  async function saveAsFeaturedFile(fileId: number) {
    const response = await updateFeaturedFile(ticket.id, fileId)
    setTicket(response)
  }

  const customerClarification = useCallback(async (): Promise<void> => {
    const updatedTicket = await patchCustomerClarification(ticket.id)

    setTicket(updatedTicket)
  }, [ticket?.id])

  const createNewVersionAndUpdateTicket = useCallback(async () => {
    const response = await createNewVersion(ticket.id)
    setTicket(response)
    return response
  }, [ticket?.id])

  const saveAndUpdateTicket = useCallback(async (ticketParams: AdminUpdateTicketParams) => {
    const updatedTicket = await updateTicket(ticketParams)
    setTicket(updatedTicket)
  }, [])

  const setMainContent = useCallback((view: AdminView['mainContent']) => {
    setCurrentView((previous) => ({ ...previous, mainContent: view }))
  }, [])

  const setRightSidebar = useCallback((view: AdminView['rightSidebar']) => {
    setCurrentView((previous) => ({ ...previous, rightSidebar: view }))
  }, [])

  useEffect(() => {
    let isAbandoned = false
    const ticketId = getIdFromUrl()
    getTicket(ticketId).then((response) => {
      if (!isAbandoned) {
        setTicket(response)
        updateAdminNavigatorTitle(response.subject)
        setIsLoaded(true)
      }
    })
    return () => {
      isAbandoned = true
    }
  }, [])

  const context: AdminTicketContextValue = {
    createNewVersion: createNewVersionAndUpdateTicket,
    currentView,
    customerClarification,
    failQr,
    passQr,
    pause,
    resume,
    saveAsFeaturedFile,
    setMainContent,
    setRightSidebar,
    start,
    startQr,
    stop,
    ticket,
    updateTicket: saveAndUpdateTicket,
  }

  if (!isLoaded) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <AnimatedLoadingScreen />
      </div>
    )
  }

  return <AdminTicketContext.Provider value={context}>{children}</AdminTicketContext.Provider>
}

function getIdFromUrl(): number {
  const exp = /\/admin\/tickets\/pte\/(\d+)/
  const idString = window.location.pathname.match(exp)[1]
  return parseInt(idString)
}

export function useAdminTicketContext(): AdminTicketContextValue {
  return useContext(AdminTicketContext) as AdminTicketContextValue
}
