import ActionLinkItem from 'components/elements/dropdown/items/action-link-item'
import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'

interface DraftTabMenuProps {
  requestId: bigint
  subject: string
  userId: number
  menuActionBtn: (route: string, data: Record<string, unknown>, method: string) => void
  url: string
}

export default function DraftTabMenu({
  requestId,
  subject,
  url,
  userId,
  menuActionBtn,
}: DraftTabMenuProps): JSX.Element {
  const deleteRequestButtonRoute = window.Routes.apiInternalRequestUrl(requestId)

  const params = { requestId: requestId, user: userId }

  const deleteModalData = {
    title: 'Delete Request?',
    message: `Are you sure you want to delete ${subject}?`,
    confirmBtnText: 'Yes, Delete',
    confirmAction: () => {
      menuActionBtn(deleteRequestButtonRoute, params, 'delete')
    },
  }

  return (
    <>
      <ExternalActionLinkItem label="Edit Request" iconClass="far fa-pencil" route={url} />
      <ActionLinkItem
        label="Delete Request"
        iconClass="far fa-trash"
        route={deleteRequestButtonRoute}
        method="DELETE"
        data={params}
        menuActionBtn={menuActionBtn}
        modalData={deleteModalData}
      />
    </>
  )
}
