import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { DetailTaskTimelineItem, TimelineItemTypes } from 'lib/api/timeline/timeline'
import IconButton from 'lib/components/buttons/icon-button'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'

import { useDirectionsContext } from '../providers/timeline-directions-provider'
import DetailTask from '../types/detail-task'

interface TimelineDirectionEditProps {
  direction: DetailTaskTimelineItem
  disableEditMode: () => void
}

const PLACEHOLDER = 'Start typing general direction'

const minimumLength = 3

export default function TimelineDirectionEdit({
  direction,
  disableEditMode,
}: TimelineDirectionEditProps): ReactElement {
  const inputRef = useRef(null)
  const [value, setValue] = useState(direction?.description || '')
  const { update, showDirectionErrors } = useDirectionsContext()
  const [showAttemptedSubmitError, setShowAttemptedSubmitError] = useState(false)

  const showError = useMemo(() => {
    return showDirectionErrors || (showAttemptedSubmitError && !hasMinimumLength(value))
  }, [showAttemptedSubmitError, showDirectionErrors, value])

  const showCharacterCounter = useMemo(() => direction?.taskType !== TimelineItemTypes.COPY, [direction?.taskType])

  function hasMinimumLength(s: string) {
    return s.trim().length >= minimumLength
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value

    setValue(newValue)

    if (hasMinimumLength(newValue)) {
      setShowAttemptedSubmitError(false)
    }
  }

  async function save() {
    if (!hasMinimumLength(value)) {
      setShowAttemptedSubmitError(true)
      return
    }

    setShowAttemptedSubmitError(false)

    // TODO: updateTimelineItem
    await update(direction as unknown as DetailTask, value.trim())
    disableEditMode()
  }

  async function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      await save()
    } else if (e.key === 'Escape') {
      disableEditMode()
    }
  }

  useEffect(() => {
    inputRef.current.focus()
    inputRef.current.scrollTop = inputRef.current.scrollHeight
    inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="tw-flex tw-items-center tw-gap-2 tw-w-full">
        <Textarea
          value={value}
          onChange={onChange}
          placeholder={PLACEHOLDER}
          error={showError}
          maxLength={direction.taskType === TimelineItemTypes.COPY ? -1 : undefined}
          onKeyDown={onKeyDown}
          ref={inputRef}
        />
        <div style={{ minWidth: '40px' }}>
          <IconButton color="secondary" icon={['far', 'check']} onClick={save} />
        </div>
        <span onMouseDown={(e) => e.preventDefault()}>
          <IconButton color="secondary" icon={['far', 'times']} onClick={disableEditMode} />
        </span>
      </div>
      {showCharacterCounter && <TextCounter value={value} className="tw-text-right tw-mr-24" />}
    </>
  )
}
