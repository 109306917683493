import { Ticket } from 'interfaces/ticket'
import styles from './table-content.module.scss'
import QueueTypePlaceholder from 'components/core/queue-type-placeholder'

interface TicketLinkElementProps {
  ticket: Ticket
}

export default function TicketLinkElement({ ticket }: TicketLinkElementProps): JSX.Element {
  const { subject, skillName, url, thumbnail } = ticket

  return (
    <a href={url} className="tw-text-eggplant hover:tw-text-eggplant-600">
      <div className="tw-flex tw-items-center">
        <div className={styles.thumbnailContainer}>
          {thumbnail.placeholder ? (
            <QueueTypePlaceholder fileName={thumbnail.url} className={styles.placeholderImage} color={'#ADA6AD'} />
          ) : (
            <img className={styles.thumbnailImage} src={thumbnail.url} alt="thumbnail" />
          )}
        </div>
        <div className="tw-align-middle">{subject || `Untitled ${skillName}`}</div>
      </div>
    </a>
  )
}
