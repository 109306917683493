import { designRequestSearchResult } from './universal-search-button'
import { ClipboardList, FileImage } from 'lucide-react'
import { TicketStatusBadge } from 'lib/components/badge/badge'

export default function SearchResult({ result }: { result: designRequestSearchResult }) {
  const classNames = {
    thumbnail: 'tw-h-24 tw-w-auto tw-object-cover tw-object-center tw-rounded',
    metadata: 'tw-flex tw-flex-row tw-gap-1 tw-text-xs tw-text-gray-500 tw-flex-wrap',
    icon: 'tw-h-4 tw-w-4',
  }

  return (
    <a key={result.id} className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-rounded">
      <div className="tw-flex tw-flex-row tw-gap-2 tw-flex-nowrap tw-w-80">
        {result?.thumbnails && (
          <>
            {result.thumbnails.slice(0, 3).map((thumbnail) => {
              return (
                <img
                  key={result.id + thumbnail}
                  src={thumbnail}
                  className={classNames.thumbnail}
                  style={{ aspectRatio: '1 / 1' }}
                />
              )
            })}
          </>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-flex-row tw-items-center">
          <span className="tw-m-0 tw-text-cornflower-500 tw-font-semibold">{result.title}</span>
          <TicketStatusBadge status={result.status} />
        </div>
        <div className={classNames.metadata}>
          <span>{result.requestType}</span>
          <span>•</span>
          <span>Designed by {result.designer}</span>
          <span>•</span>
          <span>Submitted by {result.submittedBy}</span>
          <span>•</span>
          <span>{result.submittedDate}</span>
        </div>
        {result?.excerpt && (
          <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center tw-text-gray-700">
            <ClipboardList className={classNames.icon} />
            <span>&quot;{result.excerpt}&quot;</span>
          </div>
        )}
        {result?.files && (
          <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center tw-text-gray-700">
            <FileImage className={classNames.icon} />
            <span className="tw-flex tw-flex-row tw-gap-1 tw-items-center">
              {result.files[1]}{' '}
              <span className="tw-text-gray-500">
                {result.files.length > 1 ? `+ ${result.files.length - 1} more` : ''}
              </span>
            </span>
          </div>
        )}
      </div>
    </a>
  )
}
