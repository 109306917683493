import { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'
import {
  CompanyDesignerAllocation,
  updateCompanyDesignerAllocation,
  routes,
} from 'lib/api/company-designer-allocations/company-designer-allocations'
import { ArrowLeftToLine } from 'lucide-react'
import { snakeCaseKeys } from 'lib/object/utils'
import DesignerTable from './designer-table'
import ToastProvider, { useToastContext } from 'providers/toast-provider'
import DesignerAllocationsProvider, { useDesignerAllocationsContext } from './designer-allocations-provider'
import ScopeTable from './scope-table'

const classNames = {
  arrowIcon: 'tw-mr-3 tw-text-peppercorn-900',
  container:
    'tw-container tw-px-3 tw-mt-16 tw-bg-white tw-shadow-md tw-rounded-md tw-mx-auto tw-py-4 tw-px-6 tw-w-full',
  header: 'tw-flex tw-items-center navbar-custom',
  switch: {
    enabled:
      'tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-flex-shrink-0 tw-border-1 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-bg-gherkin-500',
    disabled:
      'tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-flex-shrink-0 tw-border-1 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-bg-neutral-200',
  },
  switchGroup: 'tw-flex tw-items-center',
  switchLabel: 'tw-mr-1 tw-mb-0 tw-text-black tw-uppercase',
  switchToggle: {
    enabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw-translate-x-4',
    disabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw--translate-x-1',
  },
  switchWrapper: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  wrapper: 'tw-flex tw-w-full tw-flex-col tw-pb-4',
}

function CompanyDesignerAllocationDetail() {
  const { companyAllocation } = useDesignerAllocationsContext()
  const { alert, notice } = useToastContext()
  const [params, setParams] = useState({
    id: companyAllocation.id,
    timezoneTeamBackup: companyAllocation.timezoneTeamBackup || {
      est: false,
      pst: false,
      uk: false,
      aus: false,
    },
  })

  useEffect(() => {
    if (params.timezoneTeamBackup !== companyAllocation.timezoneTeamBackup) {
      try {
        updateCompanyDesignerAllocation(snakeCaseKeys(params))
        notice('Company Designer Allocation updated successfully')
      } catch (error) {
        console.error('Company Designer Allocation could not be updated.', error)
        alert('Company Designer Allocation could not be updated.')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  function handleToggleChange(key: string) {
    setParams((prevParams) => ({
      ...prevParams,
      timezoneTeamBackup: {
        ...prevParams.timezoneTeamBackup,
        [key]: !prevParams.timezoneTeamBackup[key],
      },
    }))
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <a href={routes.index}>
          <ArrowLeftToLine size={24} className={classNames.arrowIcon} />
        </a>
        <h3>{companyAllocation.company.name}</h3>
      </div>
      <div className={classNames.container}>
        <h4>Pool Backup</h4>
        <div className={classNames.switchWrapper}>
          {Object.entries(params.timezoneTeamBackup).map(([key, value]: [string, boolean]) => (
            <Switch.Group key={key}>
              <div className={classNames.switchGroup}>
                <Switch.Label className={classNames.switchLabel}>{key}</Switch.Label>
                <Switch
                  checked={value}
                  onChange={() => handleToggleChange(key)}
                  className={value ? classNames.switch.enabled : classNames.switch.disabled}
                  style={{ width: '38px', height: '18px' }}
                >
                  <span className="sr-only">{key}</span>
                  <span
                    aria-hidden="true"
                    className={value ? classNames.switchToggle.enabled : classNames.switchToggle.disabled}
                    style={{ width: '14px', height: '14px', transform: `translateX(${value ? '14px' : '-4px'})` }}
                  />
                </Switch>
              </div>
            </Switch.Group>
          ))}
        </div>
      </div>
      <div className={classNames.container}>
        <h4>Creatives</h4>
        <DesignerTable />
        <ScopeTable />
      </div>
    </div>
  )
}

export default function CompanyDesignerAllocationDetailPage({
  companyDesignerAllocation,
}: {
  companyDesignerAllocation: CompanyDesignerAllocation
}) {
  return (
    <ToastProvider>
      <DesignerAllocationsProvider companyDesignerAllocation={companyDesignerAllocation}>
        <CompanyDesignerAllocationDetail />
      </DesignerAllocationsProvider>
    </ToastProvider>
  )
}
