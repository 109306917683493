import { ReactElement, useEffect, useMemo } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/solid'

import { useAdminMediaContext } from '../providers/admin-media-provider'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AdminMediaViewer from './admin-media-viewer'
import AdminMediaViewerHeader from './admin-media-viewer-header'
import { TriangleAlert } from 'lucide-react'

const MEDIA_VIEW_CONTAINER_WIDTH = '800px'

const classNames = {
  buttons: {
    button: 'btn btn-icon tw-absolute tw-px-0 tw-cursor-pointer',
    chevron: 'tw-h-6 tw-w-6',
    close: 'tw-bg-white tw-rounded-r-none tw-rounded-l-md tw-border-1 tw-border-r-0 tw-border-gray-200',
    container: 'tw-absolute tw-right-0 tw-top-1/2 tw-z-20',
  },
  mediaView: {
    aside: 'tw-flex-none tw-relative tw-duration-500 tw-bg-gray-50',
    container: 'tw-h-full tw-border-gray-200 tw-border-0 tw-border-r tw-border-solid tw-flex tw-flex-col',
    controls: 'tw-text-right',
    figure: 'tw-w-full tw-text-center tw-p-0 tw-m-0 tw-relative',
    metadataContainer: 'tw-flex tw-items-center tw-justify-center tw-gap-2 tw-w-full tw-pt-4',
    metadataItem: 'tw-flex tw-items-center tw-justify-center tw-gap-2',
    figureVideo: 'tw-object-contain tw-w-full tw-h-full',
  },
}

const styles = {
  requestMainCollapsed: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-out',
    width: '0px',
  },

  requestMainExpanded: {
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-in',
    width: MEDIA_VIEW_CONTAINER_WIDTH,
  },
}

function CloseButton({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <div className={classNames.buttons.container}>
      <button
        type="button"
        onClick={onClick}
        className={`${classNames.buttons.button} ${classNames.buttons.close}`}
        style={{ right: '-1px' }}
      >
        <ChevronLeftIcon className={classNames.buttons.chevron} />
      </button>
    </div>
  )
}

// TODO: Bring this back in when the metadata is available
// interface FileMetadataProps {
//   selectedFile: AdminTicketFile
// }
// function FileMetadata({ selectedFile }: FileMetadataProps): ReactElement {
//   return (
//     <div className={classNames.mediaView.metadataContainer}>
//       {selectedFile.color_type && (
//         <div className={classNames.mediaView.metadataItem}>
//           <Palette className="lu-light lu-md" />
//           {selectedFile.color_type}
//         </div>
//       )}
//       {selectedFile.dpi && (
//         <div className={classNames.mediaView.metadataItem}>
//           <ScanEye className="lu-light lu-md" />
//           {selectedFile.dpi}
//         </div>
//       )}
//       {selectedFile.width && selectedFile.height && selectedFile.unit && (
//         <div className={classNames.mediaView.metadataItem}>
//           <Ruler className="lu-light lu-md" />
//           {selectedFile.width} x {selectedFile.height}
//           <a href="#" className="tw-flex tw-items-center">
//             {selectedFile.unit}
//             <ChevronDown className="lu-light lu-md" />
//           </a>
//         </div>
//       )}
//       {selectedFile.file_size && (
//         <div className={classNames.mediaView.metadataItem}>
//           <File className="lu-light lu-md" />
//           {displayFilesize(selectedFile.file_size)}
//         </div>
//       )}
//     </div>
//   )
// }

export default function AdminMediaContainer(): ReactElement {
  const { extractedPreviewIndex, selectedFile, setExtractedPreviewIndex } = useAdminMediaContext()
  const { currentView, setMainContent } = useAdminTicketContext()

  const currentTicketFile = useMemo(() => {
    if (selectedFile?.isExtractable) {
      if (extractedPreviewIndex === -1 && selectedFile.extractedPages?.length > 0) {
        return selectedFile.extractedPages[0]
      }

      const index = Math.min(extractedPreviewIndex, selectedFile.extractedPages.length)
      if (index === -1) {
        return null
      }
      return selectedFile.extractedPages[index]
    }
    return selectedFile
  }, [selectedFile, extractedPreviewIndex])

  useEffect(() => {
    setExtractedPreviewIndex(selectedFile?.isExtractable && selectedFile.extractedPages?.length > 0 ? 0 : -1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile?.id, setExtractedPreviewIndex])

  return (
    <>
      <aside
        className={`${classNames.mediaView.aside} ${
          currentView.mainContent === 'showWithOverflow' ? '' : 'tw-overflow-hidden'
        }`}
        style={currentView.mainContent && !!selectedFile ? styles.requestMainExpanded : styles.requestMainCollapsed}
      >
        {selectedFile && (
          <div
            className={`${classNames.mediaView.container} ${
              currentView.mainContent === 'showWithOverflow' ? '' : 'tw-overflow-y-auto'
            }`}
            style={{ width: MEDIA_VIEW_CONTAINER_WIDTH }}
          >
            {selectedFile && selectedFile.state === 'rejected' && (
              <div
                className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-bg-yellow-50 tw-text-yellow-800 tw-font-semibold tw-p-4 tw-justify-center"
                data-testid="rejectedNotice"
              >
                <TriangleAlert size="16" />
                {"This file is kept for record and won't be delivered to the client."}
              </div>
            )}
            {selectedFile && <AdminMediaViewerHeader />}
            {selectedFile && <AdminMediaViewer currentTicketFile={currentTicketFile} selectedFile={selectedFile} />}
            <CloseButton onClick={() => setMainContent(null)} />
          </div>
        )}
      </aside>
    </>
  )
}
