import { ReactElement, useEffect, useMemo, useRef, useState, MouseEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PenLine } from 'lucide-react'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { GenAiRequestTypes } from 'lib/api/gen-ai/gen-ai-requests'
import { GenAiStockAsset, TicketFile } from 'lib/api/ticket-files/ticket-files'
import { EditMode } from 'lib/api/tickets/tickets'
import IconButton from 'lib/components/buttons/icon-button'
import { Card } from 'lib/components/card/card'
import { useMediaContext } from './media-provider'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { useToastContext } from 'providers/toast-provider'

interface MediaItemFooterProps {
  file: TicketFile
}

interface MediaItemPlaceholderProps {
  file: TicketFile
}

interface MediaItemProps {
  file: TicketFile
}

const PLACEHOLDERS_FOLDER = 'file_placeholders'

const classNames = {
  previewOverlayContainer: `tw-absolute
                            tw-flex
                            tw-justify-center
                            tw-items-center
                            tw-w-full
                            tw-h-full
                            tw-bg-opacity-40
                            tw-bg-black
                            tw-rounded-t-lg
                            tw-z-10`,
  img: 'tw-rounded-t-lg tw-max-w-full',
  imgName: 'tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-neutral-600',
  placeholder: {
    div: 'tw-block tw-text-center',
    img: 'tw-mx-auto tw-py-8 tw-max-w-full',
  },
  removeButtonRelativeContainer: 'tw-relative tw-h-0 tw-w-0',
  mediaItemContainer: 'tw-flex tw-flex-col tw-gap-6 tw-items-center tw-py-6',
  removeButtonAbsoluteContainer: 'tw-absolute tw--bottom-5 tw-left-36 tw-z-10',
  annotationIconRelativeContainer: 'tw-relative tw-h-0 tw-w-0',
  annotationIconAbsoluteContainer:
    'tw-absolute tw--bottom-3 tw--left-2 tw-bg-cornflower-500 tw-text-white tw-rounded-md tw-px-3 tw-flex tw-items-center tw-z-20',
}

function PreviewOverlay() {
  return (
    <div className={classNames.previewOverlayContainer} data-testid="preview-overlay">
      <h4 className="tw-m-0 tw-text-white">PREVIEW</h4>
    </div>
  )
}

function MediaItem({ file }: MediaItemProps): ReactElement {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const containerRef = useRef(null)
  const { ticket, editMode, deleteFile, isCollaboratorView, selectedFile, selectFileById } = useMediaContext()
  const { cancelAnnotation } = useAnnotationsContext()
  const { notice } = useToastContext()

  const isSelected = useMemo(() => {
    return file.id === selectedFile?.id
  }, [selectedFile?.id, file.id])

  const removeModalMessage = useMemo(() => {
    if (file.stockAsset?.requestType === GenAiRequestTypes.Inpainting) {
      return 'Are you sure you want to delete this generated image?'
    }
    return `Are you sure you want to delete ${file.name}?`
  }, [file.name, file.stockAsset?.requestType])

  useEffect(() => {
    if (isSelected) {
      containerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isSelected])

  function handleRemoveClick(e: MouseEvent) {
    e.stopPropagation()
    setIsModalVisible(true)
  }

  function handleCancelClick(e: MouseEvent) {
    e.stopPropagation()
    setIsModalVisible(false)
  }

  async function handleDelete(e: MouseEvent) {
    e.stopPropagation()
    deleteFile(file)
    setIsModalVisible(false)
    notice('The file has been deleted')
  }

  function isMediaDeletable() {
    const isCurrentVersion = file.ticketVersion === ticket.currentVersion
    const isNotUploadedByCreative = !file.uploadedByCreative
    const isNotInProgress = editMode !== EditMode.processing
    const isNotComplete = editMode !== EditMode.complete

    return !isCollaboratorView && isCurrentVersion && isNotUploadedByCreative && isNotInProgress && isNotComplete
  }

  const annotationsCount = useMemo(() => {
    if (file.isExtractable) {
      return file.extractedPages.reduce((acc, pdf) => acc + pdf.annotations.length, 0)
    } else {
      return file.annotations?.length || 0
    }
  }, [file.isExtractable, file.extractedPages, file.annotations?.length])

  function handleSelectFile() {
    cancelAnnotation()

    selectFileById(file.id, null)
  }

  return (
    <Card size="custom" className="tw-w-48" onClick={handleSelectFile} isSelected={isSelected} ref={containerRef}>
      <Card.Body>
        {annotationsCount > 0 && (
          <div className={classNames.annotationIconRelativeContainer} data-testid="annotation-count-container">
            <div className={classNames.annotationIconAbsoluteContainer}>
              <PenLine className="lu-light lu-sm" />
              <span className="tw-pl-2">{annotationsCount}</span>
            </div>
          </div>
        )}
        {isMediaDeletable() && (
          <div className={classNames.removeButtonRelativeContainer}>
            <div className={classNames.removeButtonAbsoluteContainer}>
              <IconButton color="secondary" icon={['far', 'times']} onClick={handleRemoveClick} size="sm" />
            </div>
          </div>
        )}
        <ConfirmationModal
          title="Delete Media"
          message={<span className="tw-break-all">{removeModalMessage}</span>}
          confirmBtnText="Yes, Delete"
          confirmAction={handleDelete}
          cancelAction={handleCancelClick}
          visible={isModalVisible}
        />
        <div className="tw-relative">
          {file?.preview && <PreviewOverlay />}
          {file.previewUrl.startsWith(PLACEHOLDERS_FOLDER) ? (
            <MediaItemPlaceholder file={file} />
          ) : (
            <img key={file.id} src={file.previewUrl} alt={file.name} className={classNames.img} />
          )}
        </div>
      </Card.Body>
      <Card.Footer className="tw-h-12">
        <MediaItemFooter file={file} />
      </Card.Footer>
    </Card>
  )
}

function MediaItemFooter({ file }: MediaItemFooterProps): ReactElement {
  const fileCategory = useMemo(() => {
    if (!file.stockAsset) {
      return 'user'
    }
    const { requestType } = file.stockAsset as GenAiStockAsset
    if (requestType === GenAiRequestTypes.Inpainting) {
      return 'edit'
    }
    if (requestType === GenAiRequestTypes.TXT2IMG || requestType === GenAiRequestTypes.IMG2IMG) {
      return 'create'
    }
    return 'stock'
  }, [file.stockAsset])

  if (fileCategory === 'user') {
    return <div className={classNames.imgName}>{file.name}</div>
  } else if (fileCategory === 'edit') {
    return (
      <>
        <span className="tw-pr-2">
          <FontAwesomeIcon icon={['fal', 'paint-brush']} size="lg" />
        </span>
        <div className={classNames.imgName}>{file.name}</div>
      </>
    )
  } else if (fileCategory === 'create') {
    return (
      <>
        <span className="tw-pr-2">
          <FontAwesomeIcon icon={['fal', 'magic']} size="lg" />
        </span>
        <div className={classNames.imgName}>{file.name}</div>
      </>
    )
  }
  return <div className={classNames.imgName}>{file.name}</div>
}

function MediaItemPlaceholder({ file }: MediaItemPlaceholderProps): ReactElement {
  const src = `/images/${file.previewUrl}`
  return (
    <div className={classNames.placeholder.div}>
      <img key={file.id} src={src} alt={file.name} className={classNames.placeholder.img} />
    </div>
  )
}

export default function MediaList(): ReactElement {
  const { files, filters, showRevisions } = useMediaContext()

  const visibleFiles = useMemo(() => {
    if (filters.version) {
      return files.filter((file) => file.ticketVersion === filters.version)
    }
    return files
  }, [files, filters.version])

  const creativeFiles = visibleFiles.filter((file) => file.uploadedByCreative && !file.preview) || []
  const previewFiles = visibleFiles.filter((file) => file.preview) || []
  const clientFiles = visibleFiles.filter((file) => !file.uploadedByCreative) || []

  const showDivider = showRevisions && (creativeFiles.length > 0 || previewFiles.length > 0)

  return (
    <>
      {showDivider ? (
        <>
          {creativeFiles.length > 0 && (
            <div>
              <h5 className="tw-mx-4 tw-my-0 tw-pb-2 tw-border-solid tw-border-0 tw-border-b tw-w-10/12">
                Delivered Assets
              </h5>

              <div className={classNames.mediaItemContainer}>
                {creativeFiles.map((file) => (
                  <MediaItem file={file} key={file.id} />
                ))}
              </div>
            </div>
          )}

          {clientFiles.length > 0 && (
            <div>
              <h5 className="tw-mx-4 tw-my-0 tw-pb-2 tw-border-solid tw-border-0 tw-border-b tw-w-10/12">
                Added Assets
              </h5>
              <div className={classNames.mediaItemContainer}>
                {clientFiles.map((file) => (
                  <MediaItem file={file} key={file.id} />
                ))}
              </div>
            </div>
          )}
          {previewFiles.length > 0 && (
            <div>
              <h5 className="tw-mx-4 tw-my-0 tw-pb-2 tw-border-solid tw-border-0 tw-border-b tw-w-10/12">
                Preview Assets
              </h5>
              <div className={classNames.mediaItemContainer}>
                {previewFiles.map((file) => (
                  <MediaItem file={file} key={file.id} />
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={classNames.mediaItemContainer}>
          {visibleFiles.map((file) => (
            <MediaItem file={file} key={file.id} />
          ))}
        </div>
      )}
    </>
  )
}
