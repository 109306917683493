import { ReactElement, ReactNode, useState, Dispatch, SetStateAction } from 'react'
import Button from 'components/core/button'
import { Dpu } from 'interfaces/dpu'
import { useToastContext } from 'providers/toast-provider'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { snakeCaseKeys } from 'lib/object/utils'

export interface ProDpuRequestBody {
  backupCiId: number
  backupDesignerId: number
  backupMgId: number
  backupPdId: number
  backupVeId: number
  designerId: number
  primaryCiId: number
  primaryDesignerId: number
  primaryMgId: number
  primaryPdId: number
  primaryVeId: number
}

export interface ScaleDpuRequestBody {
  qrTeamId: number
  teamDesignerClusterId: number
}

interface SubscriptionsItemProps {
  assigned: boolean
  children: ReactNode
  disabled: boolean
  dpu: Dpu
  requestBody: ScaleDpuRequestBody | ProDpuRequestBody
  role?: string
  setAssigned: Dispatch<SetStateAction<boolean>>
}

export default function SubscriptionsItem(props: SubscriptionsItemProps): ReactElement {
  return (
    <tr role={props.role}>
      <CompanyName dpu={props.dpu} />
      {props.children}
      <AssignButton
        assigned={props.assigned}
        disabled={props.disabled}
        dpu={props.dpu}
        requestBody={props.requestBody}
        setAssigned={props.setAssigned}
      />
    </tr>
  )
}

interface CompanyNameProps {
  dpu: Dpu
}

function CompanyName({ dpu }: CompanyNameProps): ReactElement {
  return (
    <td className="tw-w-1/12" data-testid="company-name-container">
      <a
        href={window.Routes.adminCompaniesUrl({
          company_id: dpu.company.id,
        })}
        target="_blank"
        rel="noreferrer"
      >
        {dpu.company.name}
      </a>{' '}
      <br />[{dpu.picklePlan.name}]
      <br />({dpu.company.email})
    </td>
  )
}

interface AssignButtonProps {
  assigned: boolean
  disabled: boolean
  dpu: Dpu
  requestBody: ScaleDpuRequestBody | ProDpuRequestBody
  setAssigned: Dispatch<SetStateAction<boolean>>
}

function AssignButton({ assigned, disabled, dpu, requestBody, setAssigned }: AssignButtonProps): ReactElement {
  const [loading, setLoading] = useState(false)
  const { alert, notice } = useToastContext()

  const submit = async () => {
    setLoading(true)

    postData(dpu.id, snakeCaseKeys(requestBody)).catch(() => {
      alert('Oops, something went wrong.')
      setLoading(false)
    })
  }

  const postData = async (id, body) => {
    const response = await axios.patch(window.Routes.apiInternalDpuUrl(id), {
      dpu: body,
    })

    notice(response.data.message)
    setLoading(false)
    setAssigned(true)
  }

  if (loading) {
    return (
      <td>
        <FontAwesomeIcon icon="spinner-third" spin />
      </td>
    )
  } else {
    return (
      <td className="tw-text-center">
        {assigned ? (
          <div className="tw-text-xs">Assigned</div>
        ) : (
          <Button onClick={submit} disabled={disabled} color={'green'} type="button" className="tw-text-xs">
            Assign
          </Button>
        )}
      </td>
    )
  }
}
