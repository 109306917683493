import { JSX, useEffect, useState } from 'react'
import spinner from 'images/spinner.gif'

interface ImgWithLoaderProps {
  src: string
  alt: string
  className?: string
}

export default function ThumbnailWithSpinner({ src, alt, className }: ImgWithLoaderProps): JSX.Element {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const image = new Image()
    image.src = src
    image.onload = () => setImageLoaded(true)
    return () => (image.onload = null)
  }, [src])

  return (
    <div className={`${className || ''} tw-flex tw-justify-center tw-items-center`}>
      <img
        src={imageLoaded ? src : spinner}
        alt={alt}
        style={imageLoaded ? { width: '100%', height: '100%' } : { width: 'auto', height: '50%' }}
      />
    </div>
  )
}
