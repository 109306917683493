import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import { Apm } from 'interfaces/apm'
import { findById } from 'lib/array/utils'
import Checkbox from 'components/core/checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { humanize } from 'lib/string/utils'
import SingleSelect from 'components/core/single-select'
import { Designer } from 'interfaces/designer'
import { Team } from 'interfaces/team'
import { ScaleFilters } from '../scale/scale-subscriptions-filters'
import { ProFilters } from '../pro/pro-subscriptions-filters'

import styles from './subscriptions-filters.module.scss'

interface CompanyNameFilterProps {
  filters: ScaleFilters | ProFilters
  setFilters: Dispatch<SetStateAction<ScaleFilters | ProFilters>>
}

export function CompanyNameFilter({ filters, setFilters }: CompanyNameFilterProps): ReactElement {
  return (
    <div className="tw-relative">
      <input
        placeholder="Company"
        className={`${styles.input} ${styles.companyInput}`}
        type="text"
        value={filters.companyNameCont}
        onChange={(e) =>
          setFilters({
            ...filters,
            companyNameCont: e.target.value,
          })
        }
      />
      <span className="tw-absolute tw-top-3 tw-left-2.5">
        <FontAwesomeIcon icon="search" />
      </span>
    </div>
  )
}

interface CompanyStatusFilterProps {
  companyStatusOptions: string[]
  filters: ScaleFilters | ProFilters
  setFilters: Dispatch<SetStateAction<ScaleFilters | ProFilters>>
}

export function CompanyStatusFilter({
  companyStatusOptions,
  filters,
  setFilters,
}: CompanyStatusFilterProps): ReactElement {
  return (
    <React.Fragment>
      <label className={styles.filterTitle} htmlFor="company-status">
        Company Status
      </label>
      {!companyStatusOptions ? (
        <FontAwesomeIcon icon="spinner-third" spin />
      ) : (
        <SingleSelect
          name="company-status"
          inputId="company-status"
          className={styles.input}
          value={
            filters.companyStatusEq && {
              label: humanize(filters.companyStatusEq),
              value: filters.companyStatusEq,
            }
          }
          placeholder="Select Status"
          options={companyStatusOptions.map((option) => ({
            value: option,
            label: humanize(option),
          }))}
          onChange={(e) =>
            setFilters({
              ...filters,
              companyStatusEq: String(e.value),
            })
          }
        />
      )}
    </React.Fragment>
  )
}

interface ApmsFilterProps {
  apms: Apm[]
  filters: ScaleFilters | ProFilters
  filterKey: string
  setFilters: Dispatch<SetStateAction<ScaleFilters | ProFilters>>
}

export function ApmsFilter({ apms, filters, filterKey, setFilters }: ApmsFilterProps): ReactElement {
  const selectedApm: Apm = filters[filterKey] && findById(filters[filterKey], apms)

  return (
    <React.Fragment>
      <label className={styles.filterTitle} htmlFor="apms-select">
        APM
      </label>
      <SingleSelect
        className={styles.input}
        value={selectedApm && { label: selectedApm.name, value: selectedApm.id }}
        placeholder="Select APM"
        name="apms-select"
        inputId="apms-select"
        options={apms.map((apm) => ({
          value: apm.id,
          label: apm.name,
        }))}
        onChange={(e) =>
          setFilters({
            ...filters,
            [filterKey]: e.value,
          })
        }
      />
    </React.Fragment>
  )
}

interface TeamsFilterProps {
  apms: Apm[]
  filters: ScaleFilters | ProFilters
  setFilters: Dispatch<SetStateAction<ScaleFilters | ProFilters>>
  teams: Team[]
}

export function TeamsFilter({ apms, filters, setFilters, teams }: TeamsFilterProps): ReactElement {
  const selectedApm: Apm = findById(filters.qrTeamApmIdEq, apms)
  const teamsOptions: Team[] = selectedApm ? selectedApm.teams : teams
  const selectedTeam: Team = filters.qrTeamIdEq && findById(filters.qrTeamIdEq, teams)

  return (
    <React.Fragment>
      <label className={styles.filterTitle} htmlFor="teams-select">
        Teams
      </label>
      <SingleSelect
        className={styles.input}
        value={selectedTeam && { label: selectedTeam.name, value: selectedTeam.id }}
        placeholder="Select Team"
        name="teams-select"
        inputId="teams-select"
        options={teamsOptions.map((team) => ({
          value: team.id,
          label: team.name,
        }))}
        onChange={(e) =>
          setFilters({
            ...filters,
            qrTeamIdEq: String(e.value),
          })
        }
      />
    </React.Fragment>
  )
}

interface UnassignedFilterProps {
  filters: ScaleFilters | ProFilters
  filterKey: string
  setFilters: Dispatch<SetStateAction<ScaleFilters | ProFilters>>
}

export function UnassignedFilter({ filters, filterKey, setFilters }: UnassignedFilterProps): ReactElement {
  return (
    <Checkbox
      label="Unassigned"
      className={styles.input}
      checked={filters[filterKey]}
      onChange={(e) =>
        setFilters({
          ...filters,
          [filterKey]: e.target.checked,
        })
      }
    />
  )
}

interface DesignersFilterProps {
  apms: Apm[]
  designers: Designer[]
  filterKey: string
  filters: ScaleFilters | ProFilters
  label: string
  setFilters: Dispatch<SetStateAction<ScaleFilters | ProFilters>>
  teams: Team[]
}

export function DesignersFilter({
  apms,
  designers,
  filterKey,
  filters,
  label,
  setFilters,
  teams,
}: DesignersFilterProps): ReactElement {
  const selectedApm: Apm = findById(filters.qrTeamApmIdEq, apms)
  const selectedTeam: Team = findById(filters.qrTeamIdEq, teams)
  const selectedApmOrTeam = selectedApm || selectedTeam
  const designersOptions: Designer[] = selectedApmOrTeam ? selectedApmOrTeam.designers : designers
  const selectedDesigner: Designer = filters[filterKey] && findById(filters[filterKey], designersOptions)

  return (
    <React.Fragment>
      <div className={styles.filterTitle}>{label}</div>
      <SingleSelect
        className={styles.input}
        onChange={(e) =>
          setFilters({
            ...filters,
            [filterKey]: e.value,
          })
        }
        options={designersOptions.map((designer: Designer) => ({
          label: designer.name,
          value: designer.id,
        }))}
        value={
          selectedDesigner && {
            label: selectedDesigner.name,
            value: selectedDesigner.id,
          }
        }
        isSearchable
      />
    </React.Fragment>
  )
}
