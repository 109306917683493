import { forwardRef, HTMLAttributes, ReactElement, RefObject } from 'react'
import { inputClassNames } from '../textarea/textarea'

interface TextInputProps {
  id?: string
  label?: string
  error?: boolean
  type?: 'text' | 'password' | 'number'
  [key: string]: unknown
  className?: HTMLAttributes<HTMLDivElement>['className']
}

function TextInput(
  { id, label, error = false, type = 'text', className = '', ...otherProps }: TextInputProps,
  ref?: RefObject<HTMLInputElement>
): ReactElement {
  return (
    <>
      {label && (
        <label htmlFor={id} className={inputClassNames.label}>
          {label}
        </label>
      )}
      <input
        id={id}
        ref={ref}
        type={type}
        {...otherProps}
        className={`${inputClassNames.common} ${error ? inputClassNames.error : inputClassNames.noError} ${className}`}
      />
    </>
  )
}

export default forwardRef(TextInput)
