import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { init as filestackInit } from 'filestack-js'
import { client, PickerOverlay as UntypedPickerOverlay, PickerOverlayProps } from 'filestack-react'
import {
  bulkCreateTicketFiles,
  createTicketFile,
  deleteTicketFile,
  getTicketFile,
  getTicketFiles,
  TicketFile,
  TicketFilePayload,
  VideoJSAnnotationPayload,
} from 'lib/api/ticket-files/ticket-files'
import { AnnotationRecord, VideoAnnotationData } from 'lib/api/annotations/annotations'
import { DesignRequest, EditMode } from 'lib/api/tickets/tickets'
import { useUserContext } from 'providers/user-provider'
import { useToastContext } from 'providers/toast-provider'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { FilestackFile, FilestackUploadDoneParams } from 'lib/util/filestack'

interface ContextProps {
  editMode: EditMode
  children: ReactNode
  fetchAndSetTicket?: () => void
  isCollaboratorView?: boolean
  showRevisions?: boolean
  ticket: DesignRequest
}

export interface MediaContextValue {
  addFiles: (imageableId: number, files: TicketFile[]) => void
  addStockAssets: (newFiles: TicketFile[]) => void
  deleteFile: (file: TicketFile) => void
  editMode: EditMode
  extractedPreviewIndex: number
  fetchAndSetTicket: () => void
  files: TicketFile[]
  filters: MediaFilters
  getFileById: (id: number, parentId?: number) => TicketFile
  getFilePageNumber: (parentId: number, childId: number) => number
  isCollaboratorView?: boolean
  isMediaLoaded: boolean
  open: () => void
  selectedFile: TicketFile
  selectedVersion: number
  selectFileById: (fileId: number, parentId?: number) => void
  setExtractedPreviewIndex: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<MediaFilters>>
  setSelectedVersion: Dispatch<SetStateAction<number>>
  showRevisions: boolean
  ticket: DesignRequest
  updateFileAnnotations: (
    file: TicketFile,
    newAnnotations: AnnotationRecord<AnnotoriousAnnotation | VideoJSAnnotationPayload>[]
  ) => void
  updateTicketFileVideoAnnotations: (ticketFileId: number, annotations: VideoAnnotationData[]) => void
  upload: (file: File) => Promise<client.PickerFileMetadata>
}

interface MediaFilters {
  version: number
}

const ALL_VERSIONS = 0

const filestackClient = {
  instance: null,
}

const filestackOptions = {
  accept: [
    '.ai',
    '.doc',
    '.docx',
    '.eof',
    '.eot',
    '.eps',
    '.fig',
    '.indd',
    '.indl',
    '.key',
    '.mogrt',
    '.mp4',
    '.otf',
    '.pdf',
    '.pdfx',
    '.ppt',
    '.pptm',
    '.pptx',
    '.prproj',
    '.psd',
    '.rar',
    '.sketch',
    '.ttf',
    '.woff',
    '.xls',
    '.xlsx',
    '.zip',
    'audio/*',
    'image/*',
    'text/*',
    'video/*',
  ],
  exposeOriginalFile: true,
  fromSources: [
    'local_file_system',
    'imagesearch',
    'dropbox',
    'onedrive',
    'box',
    'googledrive',
    'facebook',
    'instagram',
  ],
  storeTo: {
    location: 'S3',
  },
}

const filestackMaxUploadBytes = 1024 * 1024 * 100

const MediaContext = createContext({})

const PickerOverlay = UntypedPickerOverlay as (props: PickerOverlayProps) => ReactElement

function determineFilesInVersion(files: TicketFile[], version: number): number {
  if (!version) {
    return files.length
  }
  const userFilesInVersion = files.filter((file) => {
    return !file.uploadedByCreative && file.ticketVersion === version
  })
  return userFilesInVersion.length
}

function findNextImageFile(currentFiles: TicketFile[], fromIndex: number) {
  const nextFiles = currentFiles.slice(fromIndex)
  return nextFiles[0]
}

function findPreviousImageFile(currentFiles: TicketFile[], endIndex: number) {
  const previousFiles = currentFiles.slice(0, endIndex).reverse()
  return previousFiles[0]
}

function getFilestackClient(apikey: string) {
  if (!filestackClient.instance) {
    filestackClient.instance = filestackInit(apikey)
  }
  return filestackClient.instance
}

function getNextFile(currentFiles: TicketFile[], fromIndex = 0, reverse = false) {
  if (reverse) {
    const previousImageFile = findPreviousImageFile(currentFiles, fromIndex)
    if (previousImageFile) {
      return previousImageFile
    }
    return findNextImageFile(currentFiles, fromIndex)
  } else {
    const nextImageFile = findNextImageFile(currentFiles, fromIndex)
    if (nextImageFile) {
      return nextImageFile
    }
    return findPreviousImageFile(currentFiles, fromIndex)
  }
}

function onFileSelected(file: FilestackFile) {
  if (file.size >= filestackMaxUploadBytes) {
    throw new Error(
      'The selected file is larger than the 100 megabytes file size limit and cannot be uploaded. Feel free to give us an external link to this file instead.'
    )
  }

  if (file.source === 'local_file_system' && file.mimetype && file.mimetype.startsWith('image/') && file.size > 1) {
    const reader = new FileReader()
    reader.onload = () => {
      const image = new Image()
      image.onload = () => {
        if (image.width < 400 || image.height < 400) {
          throw new Error(
            'One or more of your images is low resolution. Please consider uploading a higher resolution version.'
          )
        }
      }

      image.src = reader.result as string
    }
    reader.readAsDataURL(file.originalFile)
  }
}

function sortFiles(ticket: DesignRequest, files: TicketFile[]): TicketFile[] {
  const featuredFile = ticket.featuredFileId ? files.find((file) => file.id === ticket.featuredFileId) : null

  if (featuredFile) {
    const nonFeaturedFiles = files.filter((file) => file.id !== ticket.featuredFileId)
    const insertIndex = nonFeaturedFiles.findIndex((file) => file.ticketVersion === featuredFile.ticketVersion)

    nonFeaturedFiles.splice(insertIndex, 0, featuredFile)

    return nonFeaturedFiles
  }

  return files
}

async function sendMetadata(ticketId: number, file: FilestackFile): Promise<TicketFile> {
  return await createTicketFile(ticketId, {
    filename: file.filename,
    handle: file.handle,
    mimetype: file.mimetype,
  } as TicketFilePayload)
}

function updateExtractableFile(oldFile: TicketFile, newFile: TicketFile): TicketFile {
  const { extractedPages, previewUrl } = newFile
  return {
    ...oldFile,
    previewUrl,
    extractedPages,
  }
}

export function useMediaContext(): MediaContextValue {
  return useContext(MediaContext) as MediaContextValue
}

export default function MediaProvider({
  children,
  editMode,
  fetchAndSetTicket = () => null,
  isCollaboratorView = false,
  showRevisions = false,
  ticket,
}: ContextProps): ReactElement {
  const {
    settings: { filestackApiKey },
    token,
  } = useUserContext()

  const [files, setFiles] = useState<TicketFile[]>([])
  const [filters, setFilters] = useState<MediaFilters>({ version: null })
  const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<TicketFile>(null)
  const [selectedVersion, setSelectedVersion] = useState<number>(ticket?.lastDeliveredVersion || 1)
  const [extractedPreviewIndex, setExtractedPreviewIndex] = useState<number>(0)
  const { alert } = useToastContext()

  const numberOfFilesInCurrentVersion = useMemo(
    () => determineFilesInVersion(files, ticket?.lastDeliveredVersion),
    [files, ticket?.lastDeliveredVersion]
  )

  const maxFiles = useMemo(
    () => Math.max(1, Math.min(10, 30 - numberOfFilesInCurrentVersion)),
    [numberOfFilesInCurrentVersion]
  )

  async function addFiles(genAiRequestID: number, newFiles: TicketFile[]) {
    const filesFromResponse = await bulkCreateTicketFiles(ticket.id, genAiRequestID, newFiles)
    setFiles((current) => [...current, ...filesFromResponse])
    const file = getNextFile(filesFromResponse)
    setSelectedFile(file)
    fetchAndSetTicket()
  }

  function addFile(response: PromiseSettledResult<TicketFile>) {
    if (response.status === 'fulfilled') {
      setFiles((current) => [...current, response.value])
    } else {
      alert('Oops! Something went wrong while uploading. Please try again')
    }
  }

  function updateFileAnnotations(
    file: TicketFile,
    newAnnotations: AnnotationRecord<AnnotoriousAnnotation | VideoJSAnnotationPayload>[]
  ) {
    const updateFileFromCurrent = (currentFiles: TicketFile[]) => {
      return currentFiles.map((currentFile) => {
        if (currentFile.isExtractable) {
          const extractedPages = currentFile.extractedPages.map((pdf) => {
            if (pdf.id === file.id) {
              pdf.annotations = newAnnotations
              return pdf
            }
            return pdf
          })

          return {
            ...currentFile,
            extractedPages,
          }
        }

        if (currentFile.id === file.id) {
          file.annotations = newAnnotations
          return file
        }
        return currentFile
      })
    }

    setFiles(updateFileFromCurrent)

    if (selectedFile.id === file.id) {
      setSelectedFile((previous: TicketFile) => {
        return {
          ...previous,
          annotations: newAnnotations,
        }
      })
    } else if (selectedFile.isExtractable) {
      setSelectedFile((previous: TicketFile) => {
        return {
          ...previous,
          extractedPages: previous.extractedPages.map((pdf) => {
            if (pdf.id === file.id) {
              pdf.annotations = newAnnotations
              return pdf
            }
            return pdf
          }),
        }
      })
    }
  }

  function getFileById(id: number, parentId?: number) {
    if (parentId) {
      const parent = files.find((f) => f.id === parentId)
      return parent.extractedPages.find((f) => f.id === id)
    }
    return files.find((f) => f.id === id)
  }

  function updateTicketFileVideoAnnotations(ticketFileId: number, annotations: VideoAnnotationData[]) {
    setFiles((previous: TicketFile[]) => {
      return previous.map((currentFile) => {
        if (currentFile.id === ticketFileId) {
          currentFile.annotations = annotations as AnnotationRecord<VideoJSAnnotationPayload>[]
        }
        return currentFile
      })
    })

    if (selectedFile.id === ticketFileId) {
      setSelectedFile((previous: TicketFile) => {
        return {
          ...previous,
          annotations: annotations as AnnotationRecord<VideoJSAnnotationPayload>[],
        }
      })
    }
  }

  function addStockAssets(newFiles: TicketFile[]) {
    const noErrorAssets = newFiles.filter((newFile) => !newFile.errors)

    setFiles((current) => {
      return [...current, ...noErrorAssets]
    })

    fetchAndSetTicket()

    if (newFiles.some((newFile) => !!newFile.errors)) {
      alert('Oops! There was an error choosing images. Please try again')
    }

    if (noErrorAssets) {
      const file = getNextFile(noErrorAssets)
      setSelectedFile(file)
    }
  }

  function getFilePageNumber(parentId: number, childId: number) {
    const parentFile = files.find((file) => file.id === parentId)
    if (parentFile?.isExtractable) {
      const index = parentFile.extractedPages.findIndex((page) => page.id === childId)
      return index === -1 ? null : index + 1
    }
    return null
  }

  function onClose() {
    setIsOpen(false)
  }

  async function onUpload(result: client.PickerFileMetadata) {
    const { filesFailed, filesUploaded } = result as FilestackUploadDoneParams

    setIsOpen(false)

    if (filesFailed.length) {
      alert('Oops! Something went wrong while uploading. Please try again')
    }
    const promises = filesUploaded.map((file) => sendMetadata(ticket.id, file))
    const results = (await Promise.allSettled(promises)) as PromiseFulfilledResult<TicketFile>[]
    results.forEach(addFile)
    if (results.length) {
      setSelectedFile(results[0].value)
    }

    fetchAndSetTicket()
  }

  function open() {
    if (numberOfFilesInCurrentVersion >= 30) {
      alert('You can only upload 30 files per version. Please try again')
    } else {
      setIsOpen(true)
    }
  }

  function selectFileById(fileId: number, parentId?: number) {
    const thisFileId = parentId || fileId
    const thisChildFileId = parentId ? fileId : null
    const thisFile = selectedFile?.id === thisFileId ? selectedFile : files.find((f) => f.id === thisFileId)
    if (thisFile) {
      setSelectedFile(thisFile)

      if (selectedVersion !== ALL_VERSIONS && thisFile.ticketVersion !== selectedVersion) {
        setSelectedVersion(thisFile.ticketVersion)
      }

      if (thisChildFileId) {
        const index = thisFile.extractedPages.findIndex((page) => page.id === thisChildFileId)
        setExtractedPreviewIndex(index)
      } else {
        setExtractedPreviewIndex(0)
      }
    }
  }

  function upload(file: File): Promise<client.PickerFileMetadata> {
    const client = getFilestackClient(filestackApiKey)
    return client.upload(file)
  }

  const deleteFileAndSelectImage = useCallback(
    (currentFiles: TicketFile[], fileToBeDeleted: TicketFile) => {
      const newFiles = currentFiles.filter((f) => f.id !== fileToBeDeleted.id)

      if (selectedFile !== fileToBeDeleted) {
        return newFiles
      }

      if (!newFiles.length) {
        setSelectedFile(null)
      } else {
        const deletedFileIndex = currentFiles.indexOf(fileToBeDeleted)

        // If the deleted file was the last one, find the next image in reverse order
        const reverse = deletedFileIndex === currentFiles.length - 1
        const file = getNextFile(newFiles, deletedFileIndex, reverse)
        setSelectedFile(file)
      }
      return newFiles
    },
    [selectedFile]
  )

  const deleteFile = useCallback(
    async (fileToBeDeleted: TicketFile) => {
      try {
        await deleteTicketFile(ticket.id, fileToBeDeleted.id)

        setIsOpen(false)
        setFiles((currentFiles) => deleteFileAndSelectImage(currentFiles, fileToBeDeleted))
        fetchAndSetTicket()
      } catch (e) {
        setIsOpen(false)
        alert('There was an error deleting this file, please try again')
      }
    },
    [ticket.id, fetchAndSetTicket, deleteFileAndSelectImage, alert]
  )

  useEffect(() => {
    if (ticket?.id) {
      getTicketFiles(ticket.id, token).then((response) => {
        const sortedFiles = sortFiles(ticket, response)
        setFiles(sortedFiles)
        setIsMediaLoaded(true)

        if (sortedFiles.length > 0) {
          let file: TicketFile
          if (showRevisions) {
            setFilters({ version: ticket.lastDeliveredVersion })

            const lastDeliveredFiles = sortedFiles.filter((file) => file.ticketVersion === ticket.lastDeliveredVersion)
            file = getNextFile(lastDeliveredFiles)
          } else {
            file = getNextFile(response)
          }
          setSelectedFile(file)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isAbandoned: boolean, timeoutUntilNextPoll: number

    function recursivelyPollUntilPagesExtracted(delay = 500) {
      getTicketFile(ticket.id, selectedFile.id).then((latestFile) => {
        if (!isAbandoned && latestFile.isExtractable) {
          if (latestFile.extractedPages.length > 0) {
            setFiles((currentFiles) => {
              return currentFiles.map((file) => {
                if (file.id === latestFile.id) {
                  return updateExtractableFile(file, latestFile)
                }
                return file
              })
            })

            setSelectedFile((current) => {
              return updateExtractableFile(current, latestFile)
            })
          } else {
            timeoutUntilNextPoll = window.setTimeout(() => recursivelyPollUntilPagesExtracted(delay * 2), delay)
          }
        }
      })
    }

    if (selectedFile && selectedFile.isExtractable && selectedFile.extractedPages.length === 0) {
      recursivelyPollUntilPagesExtracted()
    }

    return () => {
      isAbandoned = true
      window.clearTimeout(timeoutUntilNextPoll)
    }
  }, [selectedFile, ticket?.id])

  useEffect(
    () => {
      if (showRevisions) {
        let version: number = null
        let file: TicketFile

        if (selectedVersion === ALL_VERSIONS) {
          version = null
          file = getNextFile(files)
        } else {
          version = Number(selectedVersion)

          const currentVersionFiles = files.filter((file) => file.ticketVersion === selectedVersion)

          if (currentVersionFiles.length) {
            const creativeFiles = currentVersionFiles.filter((file) => file.uploadedByCreative)

            if (creativeFiles.length) {
              file = creativeFiles[0]
            } else {
              file = getNextFile(files)
            }
          } else {
            file = null
          }
        }

        setFilters((previous) => ({ ...previous, version }))
        setSelectedFile(file)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedVersion, showRevisions]
  )

  const pickerOptions: client.PickerOptions = {
    ...filestackOptions,
    maxFiles,
    onClose,
    onFileSelected,
  }

  const context: MediaContextValue = {
    addFiles,
    addStockAssets,
    deleteFile,
    editMode,
    extractedPreviewIndex,
    fetchAndSetTicket,
    files,
    filters,
    getFileById,
    getFilePageNumber,
    isCollaboratorView,
    isMediaLoaded,
    open,
    selectedFile,
    selectedVersion,
    selectFileById,
    setExtractedPreviewIndex,
    setFilters,
    setSelectedVersion,
    showRevisions,
    ticket,
    updateFileAnnotations,
    updateTicketFileVideoAnnotations,
    upload,
  }

  return (
    <MediaContext.Provider value={context}>
      {isOpen && (
        <div className="tw-absolute tw-w-full tw-inset-x-1/2">
          <PickerOverlay apikey={filestackApiKey} pickerOptions={pickerOptions} onUploadDone={onUpload} />
        </div>
      )}
      {children}
    </MediaContext.Provider>
  )
}
